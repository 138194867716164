import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import * as Yup from 'yup';
import { QueryResult } from 'react-apollo';
import { Grid, Typography, Chip, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { UserAddress, UserAddressType, UserMenuOptionStatus, GetPendingUsersResult } from 'src/types';
import client from 'src/clients/apolloClient';
import store from 'src/redux/store';
import InviteUserToOrganizationMutation from 'src/gql/mutation/InviteUserToOrganizationMutation';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import Info from 'src/assets/svgs/Info';
import theme from 'src/assets/styles/theme';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import CustomToaster from 'src/components/CustomToaster';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { PrimaryButton, SecondaryButton, StyledDivider } from 'src/components/shared/HypercareComponents';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import 'src/assets/styles/AutocompleteTextareaStyles.scss';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import CreateShellAccountMutation from 'src/gql/v2/mutation/CreateShellAccountMutation';
import { INVITE_USERS_V2 } from 'src/gql/v2/mutation/InviteUsersMutation';
import { FetchPaginatedUsersQueryResponseSTA } from '../../../gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import { FetchPaginatedUsersQueryResponse } from '../../../gql/v2/query/FetchPaginatedUsersQuery';

const LicenseDatePickerContainer = styled.div`
  display: flex;
  margin-top: 0px;
  margin-bottom: 4px;
  justify-content: space-between;

  input {
    color: black !important;
  }

  .MuiFormLabel-root {
    min-width: max-content;
    text-transform: capitalize !important;
  }

  & > div {
    width: 100%;
  }
`;

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
`;

const DateLabel = styled.div`
  margin: 24px 0px 4px 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  input {
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
  }

  & .MuiFormHelperText-contained {
    margin-left: 0px !important;
  }
`;

const ActionButton = styled.div`
  float: right;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 8px !important;
`;

const AddressLabel = styled.div`
  margin-bottom: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const IntroBanner = styled(Typography)`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #222222 !important;
`;

const emailValidationSchema = Yup.string().required('Email is required!').email('Please enter a correct email');

const options: UserAddress[] = [];

const defaultUserAddressParameters: UserAddress = {
  address: '',
  type: 'email',
};

const AUTOCOMPLETE_INPUT_ID = 'inviteColleagueAutoComplete';

const InviteOrganizationScopeManagerSTA = ({
  closeModal,
  setMenuOptions,
  pendingRecords,
  setInviteResponse,
  setAddressesInput,
  setShowResponseModal,
}: {
  closeModal: () => void;
  setMenuOptions: React.Dispatch<React.SetStateAction<UserMenuOptionStatus>>;
  pendingRecords?: QueryResult<
    GetPendingUsersResult | FetchPaginatedUsersQueryResponseSTA | FetchPaginatedUsersQueryResponse
  >;
  setInviteResponse: React.Dispatch<React.SetStateAction<any[]>>;
  setAddressesInput: React.Dispatch<React.SetStateAction<any[]>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { site_id, department_id } = store.getState().organizationReducer;

  const adminCoSignInvites = IsFeatureFlagEnabled('adminCoSignInvites') && site_id === null && department_id === null;
  const [licenseEndDate, setLicenseEndDate] = React.useState<moment.Moment>(null);
  const [selectedList, setSelectedList] = React.useState<UserAddress[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [licenseDateError, setLicenseDateError] = React.useState<string>('');
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const inputOnChangeRef = React.useRef('');

  const focusAutoCompleteInput = () => {
    document.getElementById(AUTOCOMPLETE_INPUT_ID).focus();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (!inputOnChangeRef.current || !inputOnChangeRef.current.trim()) return;
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      handleUpdateSelectedList().catch(() => null);
    }
  };

  const handleUpdateSelectedList = (): Promise<UserAddressType> => {
    if (errorMessage) setErrorMessage('');

    const isCurrentInputEmail =
      inputOnChangeRef.current &&
      (inputOnChangeRef.current.includes('@') || Boolean(inputOnChangeRef.current.match(/[A-z]/g))) &&
      isNaN(inputOnChangeRef.current as any);

    const isDuplicationExist = selectedList.find((address) => {
      if (isCurrentInputEmail) {
        return address.address === inputOnChangeRef.current;
      }
      return address.address.replace(/\D/g, '') === inputOnChangeRef.current.replace(/\D/g, '');
    });

    return new Promise((resolve, reject) => {
      if (Boolean(isDuplicationExist)) {
        setErrorMessage('This address has already been selected');
        return reject('This address has already been selected');
      }

      let trimmedInput = inputOnChangeRef.current.trim().replace(/\s/g, '');

      if (isCurrentInputEmail) {
        return emailValidationSchema
          .validate(trimmedInput)
          .then(() => {
            setSelectedList((prevSelected) =>
              prevSelected.concat({
                ...defaultUserAddressParameters,
                address: trimmedInput,
              }),
            );
            resolve('email');
          })
          .catch((err) => {
            setErrorMessage(err.errors[0]);
            reject(err.errors[0]);
          });
      }
    });
  };

  const handleOnChange = (event: React.ChangeEvent<{}>, newValue: UserAddress[], reason: AutocompleteChangeReason) => {
    if (reason === 'remove-option') {
      setSelectedList(newValue);
    }
  };

  const onInputChange = (e: React.ChangeEvent<{}>, value: string) => {
    if (Boolean(value) && errorMessage && value.slice(-1) !== ' ' && value.slice(-1) !== ',') {
      setErrorMessage('');
    }
    inputOnChangeRef.current = value;
  };

  const handleSubmit = async () => {
    const hasSelectedAddresses = selectedList.length > 0;
    if (errorMessage) setErrorMessage('');
    if (!inputOnChangeRef.current && !hasSelectedAddresses) {
      setErrorMessage('Please enter an email address');
      focusAutoCompleteInput();
      return;
    }

    try {
      let addressType: UserAddressType;
      let addresses: UserAddress[] = selectedList;
      let accounts: any[] = [];
      if (!hasSelectedAddresses || inputOnChangeRef.current) {
        addressType = await handleUpdateSelectedList();

        addresses = selectedList.concat({
          ...defaultUserAddressParameters,
          address: inputOnChangeRef.current,
          type: addressType,
        });
      }
      setAddressesInput(addresses);
      accounts = addresses.map((address) => ({
        profile: {},
        addresses: [address],
        licenseExpiryDate: licenseEndDate ? licenseEndDate.toISOString() : null,
      }));
      setSubmitting(true);
      const { organization_id, type } = store.getState().organizationReducer;
      const response = await client.mutate({
        mutation: INVITE_USERS_V2,
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: {
            id: organization_id,
            type,
          },
          accounts,
        },
      });
      setInviteResponse(response?.data.adminMutation.organizationalUnit.inviteUsers.invitees);

      if (response?.data.adminMutation.organizationalUnit.inviteUsers.invitees.length > 0) {
        setShowResponseModal(true);
      }

      pendingRecords.refetch();
      closeModal();
      if (adminCoSignInvites) {
        setMenuOptions('licensed');
      }
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.sendInvitesButtonPressed,
        params: {
          email_count: addresses.length,
          license_end_date: licenseEndDate ? licenseEndDate.toISOString() : null,
        },
      });
    } catch (error) {
      console.error(error);
      if (typeof error === 'string') {
        setErrorMessage(error);
      } else {
        setErrorMessage('Failed to send invitations, please check your internet connection and try again');
      }
      focusAutoCompleteInput();
      setSubmitting(false);
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    if (date) {
      setLicenseEndDate(date.endOf('day').millisecond(0o00));
    } else {
      setLicenseEndDate(null);
    }
    if (errorMessage) setErrorMessage('');
  };

  const endDateHelperText = licenseDateError
    ? licenseDateError === 'Date should not be before minimal date'
      ? `Please select a date after ${moment().format('DD/MM/YYYY')}`
      : licenseDateError
    : `The date that the user's account gets automatically removed from the organization`;

  return (
    <div>
      <div className="instruction-body" style={{ marginTop: '16px' }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <StyledInfo circlefill={theme.warmGrey} strokefill={'white'} />
          </Grid>
          <Grid item xs>
            <IntroBanner>
              Invite your colleagues by entering their email address. If they don't have a Hypercare account, they will
              receive sign-up instructions. If they already have a Hypercare account, they will receive an in-app
              invitation to your organization.
            </IntroBanner>
          </Grid>
        </Grid>
      </div>
      <div className="autoCompleteWrapper">
        <AddressLabel>Enter email addressess separated by a comma</AddressLabel>
        <Autocomplete
          multiple
          disabled={isSubmitting}
          debug={true}
          forcePopupIcon={false}
          id={AUTOCOMPLETE_INPUT_ID}
          options={options}
          value={selectedList}
          onKeyPress={handleKeyPress}
          onChange={handleOnChange}
          onInputChange={onInputChange}
          open={false}
          getOptionLabel={(option) => option.address}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                deleteIcon={<CloseIcon color="#4A4A4A" width="10" height="10" />}
                style={{ paddingRight: '12px', borderRadius: '4px' }}
                label={option.address}
                component="a"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              color={errorMessage ? 'secondary' : 'primary'}
              variant="outlined"
              placeholder={selectedList.length === 0 ? 'type addresses separated by enter, space or comma' : ''}
            />
          )}
        />
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      </div>
      <DateLabel>License End Date (optional)</DateLabel>
      <LicenseDatePickerContainer>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StyledKeyboardDatePicker
            id="licenseEndDate"
            name="licenseEndDate"
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            placeholder="DD/MM/YYYY"
            disabled={isSubmitting}
            helperText={endDateHelperText}
            value={licenseEndDate}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            format="DD/MM/YYYY"
            onError={(err) => setLicenseDateError(err ? (err as string) : licenseDateError)}
            minDate={moment().add(1, 'days')}
            onChange={(date) => handleDateChange(date)}
          />
        </MuiPickersUtilsProvider>
      </LicenseDatePickerContainer>
      <StyledDivider />
      <ActionButton>
        <StyledSecondaryButton type="submit" onClick={closeModal}>
          Cancel
        </StyledSecondaryButton>
        <PrimaryButton disableTouchRipple color="secondary" disabled={isSubmitting} onClick={handleSubmit}>
          {isSubmitting
            ? 'Sending...'
            : `Send Invites ${
                selectedList.length > 0 ? `(${selectedList.length})${selectedList.length > 1 ? 's' : ''}` : ''
              }`}
        </PrimaryButton>
      </ActionButton>
    </div>
  );
};

export default InviteOrganizationScopeManagerSTA;
