import gql from 'graphql-tag';
import { PageInfo, PaginatedInvites } from 'src/types/PaginatedTypes';

export type SearchPendingInvitesQueryResponseSTA = {
  adminQuery: {
    organizationalUnit: {
      searchQuery: {
        searchPendingInvites: {
          pageInfo: PageInfo;
          invites: PaginatedInvites[];
        };
      };
    };
  };
};

export const SEARCH_PENDING_INVITES_QUERY_STA = gql`
  query SearchPendingInvitesSTA(
    $organizationalUnit: OrganizationalUnitInput!
    $text: String!
    $searchPendingInvitesText2: String
    $limit: Int
    $direction: PaginationDirection
    $filters: AdminSearchPendingInvitesFilters
  ) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            searchQuery(text: $text) {
              searchPendingInvites(
                text: $searchPendingInvitesText2
                limit: $limit
                direction: $direction
                filters: $filters
              ) {
                ... on PendingInvitesSearchResponse {
                  invites {
                    id
                    user {
                      id
                      firstname: firstName
                      lastname: lastName
                      username
                    }

                    organization {
                      id
                    }
                    invitedBy {
                      id
                      firstname: firstName
                      lastname: lastName
                      username
                    }
                    resolvedBy {
                      id
                      firstname: firstName
                      lastname: lastName
                      username
                    }
                    status
                    createdAt
                    updatedAt
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                    nextCursor
                    pageSize
                    resultsCount
                    startCursor
                    totalResults
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
