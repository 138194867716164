import { AUTH_INFO, HYPERCARE_REGION } from 'src/constants/storageKeys';
import store from 'src/redux/store';
import { AuthPayload, AuthRegion, User } from 'src/types';
import { SavedOrganization } from '../types/sta';

export default function saveAuthInfo(auth: AuthPayload | SavedOrganization) {
  return new Promise((res, rej) => {
    try {
      const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt, user } = auth;
      const { firstname, id, lastname, username } = user;
      const authInfo = {
        accessToken,
        accessTokenExpiresAt,
        refreshToken,
        refreshTokenExpiresAt,
        user: {
          firstname,
          id,
          lastname,
          username,
        },
      };
      window.localStorage.setItem(AUTH_INFO, JSON.stringify(authInfo));
      res('Token Info Saved');
    } catch (e) {
      rej('Error in LocalStorage');
    }
  });
}

export const getParsedAuthInfo = () => {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload | null = authInfo ? JSON.parse(authInfo) : null;
  return parsedAuthInfo;
};

export const saveScopeToken = (token: string) => {
  return new Promise((res, rej) => {
    try {
      const parsedAuthInfo = JSON.parse(window.localStorage.getItem(AUTH_INFO));
      parsedAuthInfo.scopeToken = token;
      localStorage.setItem(AUTH_INFO, JSON.stringify(parsedAuthInfo));
      res('Token Info Saved');
    } catch (e) {
      rej('Error in LocalStorage');
    }
  });
};

export const updateLocalStorageAuthInfo = (user: User) => {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const { firstname, id, lastname, username } = user;
  const parsedAuthInfo = JSON.parse(authInfo);
  const newParsedAuthInfo = {
    ...parsedAuthInfo,
    user: {
      firstname,
      id,
      lastname,
      username,
    },
  };

  localStorage.setItem(AUTH_INFO, JSON.stringify(newParsedAuthInfo));
};

export const getParsedAuthRegion = (): AuthRegion => {
  let authRegion: AuthRegion;

  if (window.localStorage.getItem(HYPERCARE_REGION)) {
    authRegion = window.localStorage.getItem(HYPERCARE_REGION) as AuthRegion;
  } else {
    const currentAuthRegion = store?.getState().hypercareRegionReducer.hypercareRegion;
    authRegion = currentAuthRegion as AuthRegion;
  }
  return authRegion;
};
