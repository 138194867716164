import gql from 'graphql-tag';
import { FullOrganizationMember } from '../../../types';

export interface MemberDevices {
  userAgent: string;
  type: string;
  id: string;
}

export interface MemberSessions {
  id: string;
  device: MemberDevices;
  lastLogin: string;
  user: Pick<FullOrganizationMember, 'id' | 'firstName' | 'lastName'>;
}

export interface FetchDevicesForMember {
  adminQuery: {
    organizationalUnit: {
      member: {
        sessions: MemberSessions[];
      };
    };
  };
}

export const FETCH_DEVICES_FOR_MEMBER = gql`
  query FetchDevicesForUser($organizationalUnit: OrganizationalUnitInput!, $memberId: ID!) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            member(id: $memberId) {
              ... on FullOrganizationMember {
                sessions {
                  id
                  device {
                    userAgent
                    type
                    id
                  }
                  lastLogin
                  user {
                    firstName
                    id
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
