import gql from 'graphql-tag';
import { PaginatedPendingInvitesSTA } from '../../../../types/PaginatedTypes';

export type FetchPaginatedInvitesQueryResponseSTA = {
  adminQuery: {
    organizationalUnit: {
      paginatedPendingInvites: PaginatedPendingInvitesSTA;
    };
  };
};
export const FETCH_PAGINATED_INVITES_QUERY_STA = gql`
  query FetchPaginatedInvites(
    $organizationalUnit: OrganizationalUnitInput!
    $pageInfo: CursorPageInput
    $filters: AdminSearchPendingInvitesFilters
  ) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedPendingInvites(pageInfo: $pageInfo, filters: $filters) {
              ... on PaginatedPendingInvitesResponse {
                invites {
                  id
                  user {
                    id
                    firstname: firstName
                    lastname: lastName
                    username
                  }

                  organization {
                    id
                  }
                  invitedBy {
                    id
                    firstname: firstName
                    lastname: lastName
                    username
                  }
                  resolvedBy {
                    id
                    firstname: firstName
                    lastname: lastName
                    username
                  }
                  status
                  createdAt
                  updatedAt
                }
                pageInfo {
                  totalResults
                  startCursor
                  pageSize
                  nextCursor
                  endCursor
                  hasNextPage
                }
              }
            }
          }
        }
      }
    }
  }
`;
