import React from 'react';
import { HCFilterPaginatedSearch } from 'src/components/@hc-ui/filters/variants/HCFilterPaginatedSearch';
import { HCFilterOption } from 'src/components/@hc-ui/filters/variants/HCFilterSimple';
import { MultiUserInputUser } from 'src/types';

interface UserAccordionTextProps {
  userOptions: MultiUserInputUser[];
  setFieldValue: any;
  searchText: string;
  handleSearch: (searchValue: string) => Promise<void>;
  selectedUsers: HCFilterOption[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<HCFilterOption[]>>;
  setLocalSearchText: React.Dispatch<React.SetStateAction<string>>;
  fetchMorePaginatedSearchData: () => Promise<void>;
  isDoneRows: boolean;
  isSearchLoading: boolean;
}

export const UserAccordionText = ({
  userOptions,
  setFieldValue,
  searchText,
  handleSearch,
  selectedUsers,
  setSelectedUsers,
  setLocalSearchText,
  fetchMorePaginatedSearchData,
  isDoneRows,
  isSearchLoading,
}: UserAccordionTextProps) => {
  const handleOnUserOptionClick = (option: HCFilterOption) => {
    const found = selectedUsers.find((user: HCFilterOption) => user.id === option.id);

    if (found) {
      const newFilteredArray = selectedUsers.filter((user) => user.id !== option.id);
      setSelectedUsers(newFilteredArray);
      setFieldValue(
        'username',
        newFilteredArray.map((option) => option.username),
      );
    } else {
      const userNameArray = [...selectedUsers, option].map((option) => option.id);
      setFieldValue('username', userNameArray);
      setSelectedUsers([...selectedUsers, option]);
    }
  };

  return (
    <HCFilterPaginatedSearch
      handleSearch={handleSearch}
      searchText={searchText}
      title="Users"
      options={{
        title: 'Users',
        options: userOptions || [],
      }}
      onToggle={(option) => {
        handleOnUserOptionClick(option);
      }}
      optionRender={(option) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ paddingBottom: 4 }}>{option.label}</div>
            <div style={{ fontWeight: 400, fontSize: 14, color: '#4A4A4A' }}>{option.role}</div>
          </div>
        );
      }}
      onClear={() => {
        setLocalSearchText('');
      }}
      selectedIds={selectedUsers}
      fetchMorePaginatedData={fetchMorePaginatedSearchData}
      isDoneFetchingMoreData={isDoneRows}
      isSearchLoading={isSearchLoading}
    />
  );
};
