import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import AppTheme from 'src/assets/styles/theme';

const DatePickerTheme = createMuiTheme({
  palette: {
    primary: { main: AppTheme.mainTealColor },
    secondary: { main: AppTheme.mainButtonColor },
  },
});

const DateLabel = styled.div`
  margin: 0px 0px -12px 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const ProfileFormLicenseDatePickerContainer = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  input {
    color: black !important;
  }
  .MuiFormLabel-root {
    text-transform: capitalize !important;
  }
  & > div {
    width: 100%;
  }
`;

const StyledHelperInfo = styled.span`
  margin: 0px 0px 0px -12px !important;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  input {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
  }
`;

interface Props {
  formikProps: FormikProps<any>;
  isDisabled: boolean;
}

const LicenseDatePicker = ({ formikProps, isDisabled }: Props) => {
  const { values, errors, setFieldValue, setFieldError } = formikProps;

  const handleEndDateChange = (date: moment.Moment) => {
    if (formikProps && date) {
      setFieldValue('licenseEndDate', date.endOf('day').millisecond(0o00));
    } else {
      setFieldValue('licenseEndDate', null);
      setFieldError('licenseEndDate', undefined);
    }
  };

  const handleEndDateError = (error: any) => {
    if (isDisabled) return;
    if (!!error && !errors['licenseEndDate']) setFieldError('licenseEndDate', error);
    if (!error && errors['licenseEndDate']) setFieldError('licenseEndDate', undefined);
  };

  const endDateHelperText = errors['licenseEndDate']
    ? errors.licenseEndDate === 'Date should not be before minimal date'
      ? `Please select a date after ${moment().format('DD/MM/YYYY')}`
      : errors.licenseEndDate
    : `The date that the user's account gets automatically removed from the organization`;

  return (
    <>
      <DateLabel>License End Date (optional)</DateLabel>
      <ProfileFormLicenseDatePickerContainer>
        <ThemeProvider theme={DatePickerTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <StyledKeyboardDatePicker
              id="licenseEndDate"
              name="licenseEndDate"
              disableToolbar
              autoOk
              placeholder="DD/MM/YYYY"
              variant="inline"
              inputVariant="outlined"
              disabled={isDisabled}
              helperText={<StyledHelperInfo>{endDateHelperText}</StyledHelperInfo>}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              error={isDisabled ? false : !!errors['licenseEndDate']}
              value={values.licenseEndDate}
              format="DD/MM/YYYY"
              onError={handleEndDateError}
              minDate={moment().add(1, 'days')}
              onChange={(date) => handleEndDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ProfileFormLicenseDatePickerContainer>
    </>
  );
};

export default LicenseDatePicker;
