import {
  ActionButtonsContainer,
  IconWrapper,
  OrganizationWithNoLogoWrapper,
  RowItems,
  RowItemsLeftInfo,
  RowItemsLeftSide,
} from '../styled/login.styled';
import React from 'react';
import { HCLabelFour, HCTextContext } from '../../components/shared/HypercareComponents';
import theme from '../../assets/styles/theme';
import WarningAltIcon from '../../assets/svgs/WarningAltIcon';
import { Organization, User } from '../../types/sta';

interface SavedAccountActionButtonsProps {
  icon: React.ReactNode;
  onIconClick: () => void;
  description: React.ReactNode;
}

interface SavedAccountOrganizationRowItemsProps {
  user: User;
  organization: Organization;
  isSignedIn: boolean;
  handleRowClick: () => void;
  rightSideContent: React.JSX.Element;
  showOrganizationContent?: React.JSX.Element;
}

export const SavedAccountActionButtons = ({ icon, onIconClick, description }: SavedAccountActionButtonsProps) => (
  <ActionButtonsContainer onClick={onIconClick}>
    <IconWrapper>{icon}</IconWrapper>
    {description}
  </ActionButtonsContainer>
);

export const SavedAccountOrganizationRowItems = ({
  user,
  organization,
  isSignedIn,
  handleRowClick,
  rightSideContent,
  showOrganizationContent,
}: SavedAccountOrganizationRowItemsProps) => {
  return (
    <>
      <RowItems onClick={handleRowClick}>
        <RowItemsLeftSide>
          <IconWrapper>
            {organization.imageURL ? (
              <img src={organization.imageURL} width="40px" height="40px" alt={'org logo'} />
            ) : (
              <OrganizationWithNoLogoWrapper>{organization.name[0]}</OrganizationWithNoLogoWrapper>
            )}
          </IconWrapper>
          <RowItemsLeftInfo>
            <HCTextContext lineHeight={'24px'} color={theme.black}>
              {`${user.firstname} ${user.lastname || ''}`}
            </HCTextContext>
            <HCTextContext lineHeight={'24px'} color={theme.mainFontColor}>
              {organization.name}
            </HCTextContext>
            {!isSignedIn && (
              <span style={{ display: 'flex', gap: '4px', justifyContent: 'center', alignItems: 'center' }}>
                <HCLabelFour color={theme.textLightTertiary} lineHeight={'16px'}>
                  Signed out
                </HCLabelFour>
                <WarningAltIcon width={12} height={12} />
              </span>
            )}
          </RowItemsLeftInfo>
        </RowItemsLeftSide>
        {rightSideContent}
      </RowItems>
      <>{showOrganizationContent}</>
    </>
  );
};
