export const getDeviceName = (type: string) => {
  let deviceName;
  switch (type) {
    case 'web':
      deviceName = 'Web';
      break;
    case 'ios':
      deviceName = 'iPhone';
      break;
    case 'android':
      deviceName = 'Android Phone';
      break;
    default:
      deviceName = 'Unknown device';
      break;
  }

  return deviceName;
};
