import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { QueryResult } from 'react-apollo';
import client from 'src/clients/apolloClient';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import CustomToaster from 'src/components/CustomToaster';
import ResendInvite from 'src/gql/mutation/ResendInvite';
import UserAvatar from 'src/components/shared/UserAvatar';
import { APPROVE_INVITE } from 'src/gql/mutation/ApproveInvite';
import { DECLINE_INVITE } from 'src/gql/mutation/DeclineInvite';
import { getParsedAuthInfo } from 'src/utils/localStorageHelper';
import { Box, Chip } from '@material-ui/core';
import PendingInviteModal from 'src/components/modals/PendingInviteModal';
import getAdminTypeBaseOnScopes from 'src/utils/getAdminTypeBaseOnScopes';
import { ActionType, GetPendingUsersResult, SorterType, User, UserMenuOptionStatus } from 'src/types';
import { StyledButton, StyledMenu, StyledMenuItem, StyledStatus, useStyles } from 'src/pages/HomePage/InviteStyle';
import { sortByInviteStatus } from 'src/utils/userSorting';
import { APPROVE, PENDING_ADMIN, PENDING_USER, REJECT, TIMER, USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from '../../../router/AppRoutes';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { StyledHypercareChip } from 'src/components/shared/StyledHypercareChip';
import UsersListActions from '../views/UsersListActions';
import {
  MANAGED_LABEL_TOOLTIP_TEXT,
  SHELL_ACCOUNT,
  UNMANAGED_LABEL_TOOLTIP_TEXT,
} from 'src/constants/dirSyncConstants';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { HCBodyTwo } from '../../../components/shared/HypercareComponents';
import { StyledLink, StyledToolTipContainer } from '../../../components/shared/SharedStyles';
import { DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK } from '../../../constants/strings';
import theme from '../../../assets/styles/theme';
import { LEARN_MORE } from '../../../constants/virtualPagerStrings';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';

type PresentedUserLicensingStatus =
  | 'licensed'
  | 'unlicensed'
  | 'pending invitation'
  | 'Pending admin approval'
  | 'Pending invitee response';

interface Props {
  currentMenuOption: UserMenuOptionStatus;
  users: User[];
  sorterType: SorterType;
  pendingUsers: User[];
  blacklistUsers: User[];
  pendingByAdmin: any;
  setPendingByAdmin: any;
  pendingByInvitee: any;
  setPendingByInvitee: any;
  pendingRecords: QueryResult<GetPendingUsersResult>;
  adminCoSignInvites: boolean;
}

const UsersList: React.FC<Props> = ({
  users,
  pendingUsers,
  blacklistUsers,
  sorterType,
  currentMenuOption,
  pendingByAdmin,
  setPendingByAdmin,
  pendingByInvitee,
  setPendingByInvitee,
  pendingRecords,
  adminCoSignInvites,
}) => {
  const classes = useStyles();
  const [selectUser, setSelectedUser] = React.useState(null);
  const [actionStatus, setActiveStatus] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [actionTypeEl, setActionTypeEl] = React.useState<null | HTMLElement>(null);
  const [action, setAction] = React.useState<null | ActionType>(null);
  const [rejectReason, setRejectReason] = React.useState('');
  const [activeAction, setActiveAction] = React.useState(null);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const viewModel = DirSyncViewModel();
  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();
  let undo = false;
  const { data: selfProfileData } = viewModel.fetchSelfProfile();
  const [showDirSyncToolTip, setShowDirSyncToolTip] = useState<HTMLElement | null | any>(null);

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>, user: User) => {
    setShowDirSyncToolTip(e.currentTarget);
    setSelectedUser(user);
  };

  const handlePopoverClose = (e: React.MouseEvent) => {
    setShowDirSyncToolTip(null);
  };

  const getUserStatus: (user: User) => PresentedUserLicensingStatus = React.useCallback(
    (user: User) => {
      if (currentMenuOption === USER_MENU_OPTION.Licensed) return 'licensed';
      if (currentMenuOption === USER_MENU_OPTION.Unlicensed) {
        if (adminCoSignInvites) {
          if (pendingUsers.find((pendingUser) => pendingUser.id === user.id))
            return user.inviteStatus === PENDING_ADMIN ? 'Pending admin approval' : 'Pending invitee response';
        } else {
          if (pendingUsers.find((pendingUser) => pendingUser.id === user.id)) return 'pending invitation';
        }

        return 'unlicensed';
      }
      if (currentMenuOption === USER_MENU_OPTION.All) {
        if (adminCoSignInvites) {
          if (pendingUsers.find((pendingUser) => pendingUser.id === user.id))
            return user.inviteStatus === PENDING_ADMIN ? 'Pending admin approval' : 'Pending invitee response';
        } else {
          if (pendingUsers.find((pendingUser) => pendingUser.id === user.id)) return 'pending invitation';
        }

        if (blacklistUsers.find((blacklistUser) => blacklistUser.id === user.id)) return 'unlicensed';
        return 'licensed';
      }
    },
    [pendingUsers, blacklistUsers, currentMenuOption],
  );

  const getInvitedBy = React.useCallback((user) => {
    const userId = getParsedAuthInfo()?.user?.id;
    let invitedBy =
      user.accountStatus === USER_MENU_OPTION.Pending
        ? user.invitedBy.id === userId
          ? 'You'
          : user.invitedBy.firstname + ' ' + user.invitedBy.lastname
        : '';

    return invitedBy;
  }, []);

  const getTimestampString = (invitedDate: string) => {
    const date = new Date(invitedDate);
    const now = moment(new Date()).startOf('day');
    const givenDate = moment(date);
    const days = now.diff(givenDate.startOf('day'), 'days');
    if (days < 1) {
      return 'Today';
    }
    if (days === 1) {
      return 'Yesterday';
    }
    return moment(date).format('DD/MMM/YYYY');
  };

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>, user: User, index: string, status: string) => {
    setActionTypeEl(event.currentTarget);
    setSelectedUser(user);
    setActiveAction(index);
    setActiveStatus(status);
  };

  const handleChangeActionType = (type: null | ActionType) => {
    setRejectReason('');
    setActionTypeEl(null);
    setIsModalOpen(true);
    setAction(type);
    setActiveStatus('');
  };

  const handleCloseMenu = () => {
    setActionTypeEl(null);
    setActiveStatus('');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleAction = async (status: string) => {
    undo = false;
    let indexforselectedUser;
    setIsModalOpen(false);
    new Promise(async (resolve, reject) => {
      try {
        indexforselectedUser = pendingByAdmin.findIndex((user) => user.id === selectUser.id);
        pendingByAdmin.splice(indexforselectedUser, 1);
        setPendingByAdmin(pendingByAdmin);

        if (status === APPROVE) {
          pendingByInvitee.unshift({ ...selectUser });
          let updatedInvitee = pendingByInvitee.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_USER;
              user.updatedAt = moment();
            }
            return user;
          });
          setPendingByInvitee(updatedInvitee.sort(sortByInviteStatus));
        }

        await showToaster(resolve, status);
        setTimeout(async () => {
          resolve(true);
        }, TIMER); //miliseconds
      } catch (e) {
        reject('error');
      }
    })
      .then((result) => {
        if (!undo && result) {
          const variable = {
            inviteId: selectUser.id,
          };
          if (status !== APPROVE) {
            variable['note'] = rejectReason;
          }
          const response = client.mutate({
            mutation: status === APPROVE ? APPROVE_INVITE : DECLINE_INVITE,
            variables: variable,
          });
          if (status === REJECT) {
            pendingRecords.refetch();
          }

          AnalyticsManager.applyAnalytics({
            eventName: status === APPROVE ? EVENTS.approveInvite : EVENTS.declineInvite,
            params: {
              invite_id: selectUser.id,
            },
          });

          return response;
        } else {
          let updatedInviteeList = pendingByInvitee.filter(
            (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
          );
          setPendingByInvitee(updatedInviteeList);

          pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
          let updatedAdminList = pendingByAdmin.map((user) => {
            if (user.id === selectUser.id) {
              user.inviteStatus = PENDING_ADMIN;
              user.updatedAt = selectUser.updatedAt;
            }
            return user;
          });
          setPendingByAdmin(updatedAdminList);

          return;
        }
      })
      .catch((e) => {
        let message = 'Failed to send request, please check your internet connection and try again!';
        if (e?.networkError?.result?.errors[0]) {
          let errorCodeName = e.networkError.result.errors[0].code;
          if (errorCodeName) {
            message = e.networkError.result.errors[0].message;
          }
        }
        toast.error(message);

        let updatedInviteeList = pendingByInvitee.filter(
          (user) => user.id !== selectUser.id && user.inviteStatus === PENDING_USER,
        );
        setPendingByInvitee(updatedInviteeList);

        pendingByAdmin.splice(indexforselectedUser, 0, { ...selectUser });
        let updatedAdminList = pendingByAdmin.map((user) => {
          if (user.id === selectUser.id) {
            user.inviteStatus = PENDING_ADMIN;
            user.updatedAt = selectUser.updatedAt;
          }
          return user;
        });
        setPendingByAdmin(updatedAdminList);
      });
  };

  const showToaster = async (resolve: (value: unknown) => void, status: string) => {
    const name = selectUser.user
      ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
      : selectUser?.address?.address;
    toast(
      <CustomToaster
        logo={status === APPROVE && <CheckSuccess />}
        body={`Invite for “${name}” has been ${status === APPROVE ? 'approved' : 'rejected'}.`}
        isVisibleUndo={true}
        handleUndo={() => {
          undo = true;
          resolve(true);
        }}
      />,
      {
        autoClose: TIMER,
        className: status === APPROVE ? classes.acceptToastr : classes.rejectToastr,
      },
    );

    return true;
  };

  const handleResend = async () => {
    try {
      const name = selectUser.user
        ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
        : selectUser?.address?.address;
      setActionTypeEl(null);
      await client.mutate({
        mutation: ResendInvite,
        variables: {
          inviteId: selectUser.id,
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.resendInvite,
        params: {
          invite_id: selectUser.id,
        },
      });

      toast(<CustomToaster body={`Invite for “${name}” has been resent.`} isVisibleUndo={false} />, {
        className: classes.rejectToastr,
      });
    } catch (e) {
      let message = 'Failed to send request, please check your internet connection and try again!';
      if (e?.networkError?.result?.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (errorCodeName) {
          message = e.networkError.result.errors[0].message;
        }
      }
      toast.error(message);
    }
  };

  if (users.length === 0) {
    return <p className="noUser">There are no users yet</p>;
  }

  return (
    <React.Fragment>
      {showDirSyncToolTip && (
        <Popover
          open={Boolean(showDirSyncToolTip)}
          anchorEl={showDirSyncToolTip}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className="popper__container notes__popover"
          disablePortal
        >
          <ClickAwayListener onClickAway={() => setShowDirSyncToolTip(null)}>
            <StyledToolTipContainer width={'225px'} onMouseLeave={handlePopoverClose}>
              <HCBodyTwo>
                {selectUser?.isDirectorySynced ? MANAGED_LABEL_TOOLTIP_TEXT : UNMANAGED_LABEL_TOOLTIP_TEXT}{' '}
                <StyledLink href={DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK}>
                  <span style={{ color: theme.blueBorder }}>{LEARN_MORE}</span>
                </StyledLink>
              </HCBodyTwo>
            </StyledToolTipContainer>
          </ClickAwayListener>
        </Popover>
      )}
      {users.map((user: User, index: number) => {
        const adminType = getAdminTypeBaseOnScopes(user.scopes);
        const userLicensingStatus = getUserStatus(user);
        const invitedBy = getInvitedBy(user);
        const ldapCondition =
          ldapDirectorySyncFlag &&
          (userLicensingStatus === USER_MENU_OPTION.Licensed || user.accountStatus === USER_MENU_OPTION.Pending);
        const ldapColumnCondition = ldapDirectorySyncFlag && userLicensingStatus === USER_MENU_OPTION.Licensed;

        const uniqueIndex = `${index}-${user.id}`;

        return (
          <div
            className={`usersBlock ${
              (adminCoSignInvites && user.accountStatus === USER_MENU_OPTION.Pending) ||
              (currentMenuOption === USER_MENU_OPTION.All && classes.userBlockSection)
            }`}
            key={`user-${index}`}
          >
            <div className="usersBlock__avatarIcon">
              <UserAvatar profileSize="default" user={user} />
            </div>

            <div
              className={`usersBlock__userNames ${
                adminCoSignInvites && user.accountStatus === USER_MENU_OPTION.Pending && classes.userNameSection
              }`}
              onClick={() =>
                user.accountStatus !== USER_MENU_OPTION.Pending
                  ? window.routerHistory.push(`${AppRoutes.UserProfile}/${user.id}`)
                  : null
              }
            >
              <span className="usersBlock__userNames--fullName">{getUserFullName(user)}</span>
              {user.username && <span className="usersBlock__userNames--username">@{user.username}</span>}
              {user.accountStatus !== USER_MENU_OPTION.Pending && <ChevronRight />}
            </div>

            <div className="usersBlock__userTitle">
              {adminCoSignInvites && user.accountStatus === USER_MENU_OPTION.Pending ? (
                <>
                  Invited by &nbsp;
                  <span
                    className={classes.invitedBy}
                    onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.invitedBy.id}`)}
                  >
                    {invitedBy}
                  </span>
                  , {moment(user.createdAt).format('DD/MMM/YYYY')}
                </>
              ) : user.role ? (
                `${user.role}`
              ) : (
                '--'
              )}
            </div>
            <div className="usersBlock__userTags">
              {adminCoSignInvites && user.accountStatus === USER_MENU_OPTION.Pending ? (
                <>
                  {user.resolvedBy && user.resolvedBy.id !== user.invitedBy.id && (
                    <>
                      Approved by &nbsp;
                      <span
                        className={classes.invitedBy}
                        onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.resolvedBy.id}`)}
                      >
                        {invitedBy}
                      </span>
                      , <span>{getTimestampString(user.createdAt)}</span>
                    </>
                  )}
                </>
              ) : (
                <>
                  {ldapDirectorySyncFlag && user.scopes && adminType ? (
                    <StyledHypercareChip type={'admin'} text={`${adminType} admin`} valueClassName="adminTag" />
                  ) : (
                    user.scopes && adminType && <Chip label={`${adminType} admin`} className="adminTag" />
                  )}
                  {user.memberStatus && user.memberStatus === 'inactive' && (
                    <StyledHypercareChip type={'shell'} text={SHELL_ACCOUNT} valueClassName="shellTag" />
                  )}
                  {ldapDirectorySyncFlag && user.isDirectorySynced && (
                    <span onMouseEnter={(e) => handlePopoverOpen(e, user)}>
                      <StyledHypercareChip type={'neutral'} text={'Managed'} valueClassName="chip-instance" />
                    </span>
                  )}
                  {ldapDirectorySyncFlag && orgDirSyncStatus && !user.isDirectorySynced && (
                    <span onMouseEnter={(e) => handlePopoverOpen(e, user)}>
                      <StyledHypercareChip type={'warning'} text={'Unmanaged'} valueClassName="chip-instance" />
                    </span>
                  )}
                </>
              )}
            </div>

            <div
              className={`usersBlock__userStatus ${
                (ldapColumnCondition && classes.licensedStatus) ||
                (user.accountStatus === USER_MENU_OPTION.Pending && classes.status)
              }`}
            >
              <StyledStatus status={userLicensingStatus}>{userLicensingStatus}</StyledStatus>
            </div>

            <div
              className={`usersBlock__joinDate ${
                (ldapColumnCondition && classes.status) ||
                (user.accountStatus === USER_MENU_OPTION.Pending && classes.status)
              }`}
            >
              <span className={currentMenuOption === USER_MENU_OPTION.All ? classes.joinDate : undefined}>
                {user.joinDate ? moment(user.joinDate).format('DD/MMM/YYYY') : '--'}
              </span>
            </div>

            <div className={classes.actionButton}>
              {(ldapCondition ||
                (!ldapDirectorySyncFlag &&
                  user.accountStatus === USER_MENU_OPTION.Pending &&
                  currentMenuOption === USER_MENU_OPTION.All)) && (
                <>
                  <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                    <StyledButton
                      id={`action-button-${user.id}`}
                      aria-haspopup="true"
                      className={
                        user.inviteStatus === PENDING_ADMIN
                          ? classes.adminActionButtonBorder
                          : classes.inviteeActionButtonBorder
                      }
                      onClick={(e) => handleActionClick(e, user, uniqueIndex, user.inviteStatus)}
                      endIcon={actionTypeEl && uniqueIndex === activeAction ? <ExpandLess /> : <ExpandMore />}
                    >
                      Action
                    </StyledButton>
                  </Box>
                </>
              )}
            </div>
          </div>
        );
      })}
      <StyledMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={`action-menu-${selectUser?.id}`}
        keepMounted
        anchorEl={actionTypeEl}
        open={Boolean(actionTypeEl)}
        onClose={() => handleCloseMenu()}
      >
        {actionStatus === PENDING_ADMIN ? (
          <div key={activeAction}>
            <StyledMenuItem onClick={() => handleChangeActionType(APPROVE)}>Approve</StyledMenuItem>
            {/* {ldapDirectorySyncFlag && (
              <StyledMenuItem onClick={() => handleOpenUserProfile()}>View {getName()} profle</StyledMenuItem>
            )} */}
            <StyledMenuItem onClick={() => handleChangeActionType(REJECT)} className={classes.reject}>
              Reject
            </StyledMenuItem>
          </div>
        ) : actionStatus === PENDING_USER ? (
          <StyledMenuItem onClick={() => handleResend()}>Resend invite</StyledMenuItem>
        ) : (
          <UsersListActions
            adminType={getAdminTypeBaseOnScopes(selfProfileData?.scopes)}
            selectedUser={selectUser}
            handleCloseMenu={handleCloseMenu}
          />
        )}
      </StyledMenu>
      {isModalOpen && (
        <PendingInviteModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          action={action}
          selectUser={selectUser}
          handleAction={handleAction}
        />
      )}
    </React.Fragment>
  );
};

export default UsersList;
