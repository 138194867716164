import gql from 'graphql-tag';

export const UPDATE_ADDRESS_ACCESS = gql`
  mutation UpdateAddressAccessSTA(
    $organizationalUnit: OrganizationalUnitInput!
    $id: ID!
    $address: String!
    $access: AccessType!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $id) {
              ... on UserNotFoundError {
                __typename
                message
              }
              ... on FullOrganizationMemberMutation {
                address(address: $address) {
                  __typename
                  ... on AddressMutation {
                    updateAccess(access: $access) {
                      __typename
                      ... on Address {
                        __typename
                        id
                        address
                        type
                        access
                        label
                      }
                    }
                  }
                  ... on AddressNotFound {
                    __typename
                    message
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
