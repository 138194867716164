import gql from 'graphql-tag';

export const INVITE_USERS_V2 = gql`
  mutation InviteUsers($organizationalUnit: OrganizationalUnitInput!, $accounts: [AdminInviteUserDetails!]!) {
    adminMutation {
      __typename
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          __typename
          ... on AdminOrganizationMutation {
            inviteUsers(invitees: $accounts) {
              ... on InviteUsersResponse {
                ## length of accounts will always be equal to the number of account details passed in
                invitees {
                  __typename
                  ... on UserInvite {
                    id
                    status
                    user {
                      ...OrganizationMemberFragment
                    }
                    resolvedBy {
                      ...OrganizationMemberFragment
                    }
                  }
                  ... on Error {
                    message
                  }
                  ... on AddressInUse {
                    address
                  }
                  ... on AccountBlocked {
                    address
                  }
                  ... on InvitePendingAdminApproval {
                    address
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstName
    lastName
    role
    memberStatus
    addresses {
      address
      access
      label
    }
  }
`;
