// local
export const AUTH_INFO = 'authInfo';
export const HYPERCARE_USERNAME = 'hypercareUsername';
export const HYPERCARE_REGION = 'hypercareRegion';
export const CURRENT_ORGANIZATION = 'currentOrganization';
export const ORGANIZATION_ACCOUNTS_DATA = 'organizationAccountsData';
export const INTRO_DONE = 'intro-done';
export const isBannerExpanded = 'isBannerExpanded';

// session
export const IS_EXPIRED = 'isExpired';
export const PREVIOUS_ORGANIZATION = 'previousOrganization';

// cookie
export const SAVED_ACCOUNTS_COOKIE = 'savedAccounts';
