import {
  UNAUTH_REQUEST_UNDERSCORE,
  INVALID_TOKEN_UNDERSCORE,
  ACCESS_TOKEN_UNDERSCORE,
  INVALID_ACCESS_TOKEN,
} from 'src/constants/networkError';

export const USER_AUTH_EXPIRATION_NAMES = [
  UNAUTH_REQUEST_UNDERSCORE,
  INVALID_TOKEN_UNDERSCORE,
  ACCESS_TOKEN_UNDERSCORE,
  INVALID_ACCESS_TOKEN,
];

export const LICENSED = 'licensed';
export const PENDING = 'pending';
export const UNLICENSED = 'unlicensed';
export const USER_NOT_LOGIN_ON_DEVICE = 'User is not logged into any devices.';
export const DEVICE_LOGOUT_BUTTON = 'Log out of this device';
export const WEB_DEVICE = 'web';

export const ACCOUNT_MANAGEMENT = 'Account Management';
export const PROFILE = 'Profile';
