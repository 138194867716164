import gql from 'graphql-tag';

export const APPROVE_INVITE = gql`
  mutation ApproveInvite($inviteId: ID!, $organizationalUnit: OrganizationalUnitInput!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            invite(id: $inviteId) {
              ... on AdminInviteMutation {
                approve {
                  __typename
                  ... on Error {
                    message
                  }
                  ... on UserInvite {
                    id
                    status
                    user {
                      id
                      firstname: firstName
                      lastname: lastName
                      role
                      memberStatus
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
