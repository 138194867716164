import _ from 'lodash';
import * as React from 'react';
import BaseAccordion from './BaseAccordion';
import AssigneeAccordionDetails from './AccordionDetails/AssigneeAccordionDetails';
import ConfigureNumberViewModel from '../../ConfigureNumberViewModel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPager } from 'src/redux/actions/virtualPagersActions';
import store, { typedUseSelector } from 'src/redux/store';
import { OrganizationMemberFragment, OrganizationRoleFragment, VirtualPagerStatusTypes } from 'src/types';
import { toast } from 'react-toastify';
import { SuccessToast } from 'src/components/CustomToasts';
import { ACCORDION_ASSIGNEE_DEFAULT_DESCRIPTION, ACCORDION_ASSIGNEE_HEADER } from 'src/constants/virtualPagerStrings';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import { PaginatedLicensedMembers } from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import debounce from 'lodash/debounce';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const AssigneeAccordion = () => {
  const { configureUserAssignees, configureRoleAssignees, fetchUserByUserID, fetchOrganizationRoles } =
    ConfigureNumberViewModel();
  const dispatch = useDispatch();
  const { editedPager, currentPager } = typedUseSelector((state) => state.virtualPagerReducer);
  const [userList, setUserList] = React.useState<OrganizationMemberFragment[]>([]);
  const [roleList, setRoleList] = React.useState<OrganizationRoleFragment[]>([]);
  const [searchLicensedUserData, setSearchLicensedUserData] = React.useState<PaginatedLicensedMembers>(null);
  const [searchLoading, setSearchLoading] = React.useState(false);

  const [localSearchText, setLocalSearchText] = React.useState('');

  const userViewModel = UserViewModel();

  const assigneeSavedCondition =
    _.isEqual(editedPager.userIds, currentPager.userIds) && _.isEqual(editedPager.roleIds, currentPager.roleIds);

  const noSelectedAssigneesCondition = _.isEmpty(editedPager.userIds) && _.isEmpty(editedPager.roleIds);

  const paginatedUserDataQuery = userViewModel.useFetchPaginatedUsers({
    continuationId: null,
    direction: 'next',
  });

  const fetchSearchData = async (searchValue: string) => {
    const organizationType = store.getState().organizationReducer.type;
    const result = await userViewModel.getSearchedUsers({
      text: searchValue,
      limit: 30,
      continuationId: null,
      organizationType,
    });

    if (result?.error) {
      setSearchLicensedUserData(null);
    }

    if ('users' in result) {
      setSearchLicensedUserData(result);
    }

    setSearchLoading(false);
  };

  const handleSearchUsers = React.useCallback(
    debounce(async (searchValue: string) => {
      await fetchSearchData(searchValue);
    }, 250),
    [],
  );

  const handleSearch = async (updatedSearchText: string) => {
    setLocalSearchText(updatedSearchText);

    if (updatedSearchText === '') {
      setSearchLicensedUserData(null);
      return;
    }

    if (updatedSearchText.length <= 1) {
      return;
    }

    setSearchLoading(true);

    await handleSearchUsers(updatedSearchText);
  };

  const licensedUsers: OrganizationMemberFragment[] =
    localSearchText.length > 0
      ? searchLicensedUserData?.users || []
      : paginatedUserDataQuery?.data?.adminQuery.organizationalUnit?.paginatedMembers?.users;

  useEffect(() => {
    const fetchData = async () => {
      setRoleList(await fetchOrganizationRoles());
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const users = await fetchUserByUserID(editedPager.userIds);
      setUserList(users.filter((user) => user !== null));
    };
    fetchData();
  }, [editedPager]);

  const userListString = userList.map((val) => `${val.firstname} ${val.lastname}`).join(', ');

  const roleListString = roleList
    .filter((role) => editedPager.roleIds.includes(`${role.roleAuditId}`))
    .map((role) => role.roleName)
    .join(', ');

  const trackClick = (expanded) => {
    const containerStatus = noSelectedAssigneesCondition
      ? 'Pending Configuration'
      : assigneeSavedCondition
      ? 'Configured'
      : 'Unsaved Changes';
    if (!expanded) {
      const assigneeType = editedPager.userIds.length > 0 ? 'User' : 'Role';
      const assignee = editedPager.userIds.length > 0 ? userListString : roleListString;
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaVirtualPagerAssigneeClosed,
        params: {
          virtual_pager_number: currentPager.pagerNumber,
          assignee_type: assigneeType,
          assignee: assignee,
          container_status: containerStatus,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaConfigurationSetAssignNumberPressed,
        params: {
          container_status: containerStatus,
        },
      });
    }
  };

  return (
    <>
      {editedPager && currentPager ? (
        <BaseAccordion
          title={ACCORDION_ASSIGNEE_HEADER}
          description={{
            Generic: ACCORDION_ASSIGNEE_DEFAULT_DESCRIPTION,
            PagerSpecific: currentPager.userIds.length > 0 ? userListString : roleListString,
          }}
          trackClick={trackClick}
          handleSave={async () => {
            let toastMessage = 'Save assignees successfully!';
            try {
              if (editedPager.userIds.length > 0) {
                dispatch(setCurrentPager(await configureUserAssignees(editedPager._id, editedPager.userIds)));
                toastMessage = `${editedPager.userIds.length} assignees has been assigned!`;
                AnalyticsManager.applyAnalytics({
                  eventName: EVENTS.pvpaVirtualPagerAssigneeSaved,
                  params: {
                    virtual_pager_number: currentPager.pagerNumber,
                    assignee_type: 'User',
                    assignee: userListString,
                  },
                });
              } else {
                dispatch(setCurrentPager(await configureRoleAssignees(editedPager._id, editedPager.roleIds)));
                toastMessage = `${editedPager.roleIds.length} roles has been assigned!`;
                AnalyticsManager.applyAnalytics({
                  eventName: EVENTS.pvpaVirtualPagerAssigneeSaved,
                  params: {
                    virtual_pager_number: currentPager.pagerNumber,
                    assignee_type: 'Role',
                    assignee: roleListString,
                  },
                });
              }
              toast.success(<SuccessToast title={toastMessage} />, {
                className: 'toast-message toast-success',
                autoClose: 2000,
              });
            } catch (e) {
              toastMessage = 'Save assignees failed!';
              toast.error('An error has occurred, please try again later.', {
                className: 'toast-message',
                autoClose: 2000,
              });
            }
          }}
          isSaveButtonDisabled={assigneeSavedCondition || noSelectedAssigneesCondition}
          isUnsaved={!assigneeSavedCondition}
          isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
          isFieldValid={!_.isEmpty(editedPager.roleIds) || !_.isEmpty(editedPager.userIds)}
          AccordionDetail={
            !paginatedUserDataQuery?.loading ? (
              <AssigneeAccordionDetails
                searchLoading={searchLoading}
                localSearchText={localSearchText}
                setLocalSearchText={setLocalSearchText}
                handleSearch={handleSearch}
                paginatedUserData={paginatedUserDataQuery?.data}
                users={licensedUsers}
                fetchMorePaginatedUserData={paginatedUserDataQuery?.fetchMore}
                searchLicensedUserData={searchLicensedUserData}
                setSearchLicensedUserData={setSearchLicensedUserData}
                isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
                selectedUserList={userList}
                roleList={roleList}
              />
            ) : null
          }
        />
      ) : null}
    </>
  );
};

export default AssigneeAccordion;
