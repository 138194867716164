import gql from 'graphql-tag';
import { PageInfo, PaginatedPublicUser } from 'src/types/PaginatedTypes';

export type PaginatedBlackListedMembersSTA = {
  pageInfo: PageInfo;
  users: PaginatedPublicUser[];
};

export type FetchPaginatedRemovedUsersQueryResponseSTA = {
  adminQuery: {
    organizationalUnit: {
      paginatedBlacklistedUsers: PaginatedBlackListedMembersSTA;
    };
  };
};
export const FETCH_PAGINATED_REMOVED_USERS_QUERY_STA = gql`
  query FetchPaginatedRemovedUsers(
    $organizationalUnit: OrganizationalUnitInput!
    $filters: AdminPaginatedRemovedMembersFilter
    $pageInfo: CursorPageInput
  ) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedBlacklistedUsers(filters: $filters, pageInfo: $pageInfo) {
              ... on PaginatedBlacklistedUsersResponse {
                users {
                  id
                  username
                  firstname: firstName
                  lastname: lastName
                }
                pageInfo {
                  endCursor
                  hasNextPage
                  nextCursor
                  pageSize
                  startCursor
                  totalResults
                }
              }
            }
          }
        }
      }
    }
  }
`;
