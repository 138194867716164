import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { User } from 'src/types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { typedUseSelector } from 'src/redux/store';
import { InputLabel, MenuItem, makeStyles, Select } from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import Chevron from 'src/assets/svgs/Chevron';
import { HCLabelOne } from '../shared/HypercareComponents';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import ProfileViewModel from 'src/pages/UserProfilePage/profile-layout/viewModels/ProfileViewModel';

const selectUseStyles = makeStyles(() => {
  return {
    root: {
      border: 'none',
      display: 'flex',
      gap: '10px',
      alignSelf: 'stretch',
      padding: '0px',
      width: '100%',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: theme.ghostWhite,
      },
    },
    select: {
      '&&': {
        paddingRight: 0,
      },
      '& ul': {
        borderRadius: '4px',
        backgroundColor: '#fff',
        display: 'flex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
      },
      '& li': {
        padding: '10px 8px',
      },
    },
    selected: {
      borderRadius: '4px',
      backgroundColor: theme.ghostWhite,
    },
    font: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      textTransform: 'none',
    },
  };
});

const DateLabel = styled.div`
  margin: 24px 0px 4px 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  input {
    padding-top: 14.5px !important;
    padding-bottom: 14.5px !important;
  }
  & .MuiFormHelperText-contained {
    margin-left: 0px !important;
  }
`;

const StyledSelect = styled(Select)`
  option {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
  }
`;

const ModalContentWrapper = styled.div`
  margin-bottom: 1em;
  input {
    color: black !important;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const LicenseDatePickerContainer = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  .MuiFormLabel-root {
    min-width: max-content;
    text-transform: capitalize !important;
  }
  & > div {
    width: 48%;
  }
`;

const InputsWrapper = styled.div`
  margin-bottom: 1em;
`;
interface Props {
  showModal: boolean;
  user: User;
  isPending: boolean;
  handleCloseModal: () => void;
}

const ResendInviteModalSTA = ({ showModal, isPending, user, handleCloseModal }: Props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [licenseEndDate, setLicenseEndDate] = React.useState<moment.Moment>(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const selectClasses = selectUseStyles();
  const currentOrganization = typedUseSelector((state) => state.organizationReducer);
  const userViewModel = UserViewModel();
  const profileViewModel = ProfileViewModel();

  // pick first occurrence email and phone type where input email has higher priority than phone-umber
  const firstOccurrenceEmail = user.addresses.find((address) => address.type === 'email');

  const [selectedEmail, setSelectedEmail] = React.useState(firstOccurrenceEmail ? firstOccurrenceEmail.address : '');

  const emailAddresses = user.addresses.filter((address) => address.type === 'email');

  const handleChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const handleReinvite = async () => {
    const accounts = [
      {
        profile: {},
        addresses: [
          {
            address: selectedEmail,
            type: 'email',
          },
        ],
        licenseExpiryDate: licenseEndDate ? licenseEndDate.toISOString() : null,
      },
    ];
    setLoading(true);
    try {
      const res = await userViewModel.inviteUsers(accounts);
      if (
        res.data?.response?.data?.adminMutation?.organizationalUnit?.inviteUsers?.invitees?.[0]?.__typename ===
        'UserInvite'
      ) {
        toast.success('User has been reinvited', {
          className: 'Toast-Container',
        });
        handleCloseModal();
        window.routerHistory.push('/home');
      } else if (
        res.data?.response?.data?.adminMutation?.organizationalUnit?.inviteUsers?.invitees?.[0]?.__typename ===
        'AddressInUse'
      ) {
        toast.error('Unable to reinvite user, The email provided is associated with an existing account', {
          className: 'Toast-Container',
        });
      } else {
        toast.error('Unable to reinvite user', {
          className: 'Toast-Container',
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error('Failed to reinvite user', {
        className: 'Toast-Container',
      });
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    if (date) {
      setLicenseEndDate(date.endOf('day').millisecond(0o00));
    } else {
      setLicenseEndDate(null);
    }
    if (errorMessage) setErrorMessage('');
  };

  return (
    <ReactModal
      overlayClassName="modal__overlay"
      className="modal__confirmation"
      isOpen={showModal}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={handleCloseModal}
    >
      <div className="close" onClick={handleCloseModal} />
      <div className="modal__header">Reinvite {user.firstname}</div>
      <ModalContentWrapper>
        <InputsWrapper>
          {emailAddresses && emailAddresses.length > 0 && (
            <>
              <InputLabel shrink htmlFor="email">
                Email
              </InputLabel>
              <StyledSelect
                fullWidth
                id="email"
                name="email"
                value={selectedEmail}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={Chevron}
              >
                {emailAddresses.map((email, index) => (
                  <MenuItem key={index} value={email.address} classes={{ selected: selectClasses.selected }}>
                    <HCLabelOne>{email.address}</HCLabelOne>
                  </MenuItem>
                ))}
              </StyledSelect>
            </>
          )}
        </InputsWrapper>

        <LicenseDatePickerContainer>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
              <DateLabel>License Start Date</DateLabel>
              <StyledKeyboardDatePicker
                id="licenseStartDate"
                name="licenseStartDate"
                disabled
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                placeholder="DD/MM/YYYY"
                helperText="The date the user's account becomes active in your organization"
                value={new Date()}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                format="DD/MM/YYYY"
                onChange={() => null}
              />
            </div>
            <div>
              <DateLabel>License End Date (optional)</DateLabel>
              <StyledKeyboardDatePicker
                id="licenseEndDate"
                name="licenseEndDate"
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                placeholder="DD/MM/YYYY"
                disabled={isLoading}
                helperText="The date that the user's account gets automatically removed from the organization"
                value={licenseEndDate}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                format="DD/MM/YYYY"
                onError={(err) => setErrorMessage(err ? (err as string) : errorMessage)}
                minDate={moment().add(1, 'days')}
                onChange={(date) => handleDateChange(date)}
              />
            </div>
          </MuiPickersUtilsProvider>
        </LicenseDatePickerContainer>
      </ModalContentWrapper>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <Button
        disabled={isLoading || !!errorMessage}
        fullWidth
        disableTouchRipple
        color="secondary"
        variant="contained"
        onClick={handleReinvite}
      >
        Reinvite user
      </Button>
    </ReactModal>
  );
};

export default ResendInviteModalSTA;
