import gql from 'graphql-tag';
import { GQLErrorType } from '../types/result';

export type UpdateAddressLabelMutationResponse = {
  adminMutation: {
    organizationalUnit:
      | {
          __typename: 'AdminOrganizationMutation';
          member: {
            address:
              | {
                  __typename: 'AddressMutation';
                  updateLabel:
                    | {
                        __typename: 'Address';
                        id: string;
                      }
                    | GQLErrorType<'InvalidAddressLabel'>;
                }
              | GQLErrorType<'AddressNotFound'>;
          };
        }
      | GQLErrorType<'OrganizationalUnitNotFoundError'>
      | GQLErrorType<'UserNotAdminForScope'>;
  };
};

export const UPDATE_ADDRESS_LABEL = gql`
  mutation UpdateAddressLabelSTA(
    $organizationalUnit: OrganizationalUnitInput!
    $id: ID!
    $address: String!
    $label: AddressLabel
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $id) {
              ... on UserNotFoundError {
                __typename
                message
              }
              ... on FullOrganizationMemberMutation {
                address(address: $address) {
                  __typename
                  ... on AddressMutation {
                    updateLabel(label: $label) {
                      __typename
                      ... on Address {
                        __typename
                        id
                        address
                        type
                        access
                        label
                      }
                    }
                  }
                  ... on AddressNotFound {
                    __typename
                    message
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
