import React from 'react';
import { Mutation, MutationFunction } from 'react-apollo';
import { LOGOUT_ALL_DEVICES, DEVICE_LOGOUT } from 'src/gql/mutation/RemoteLogoutMutation';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogActions, IconButton } from '@material-ui/core';
import {
  DialogBoxTitle,
  HCHeadingThree,
  PrimaryButton,
  SecondaryButton,
} from 'src/components/shared/HypercareComponents';
import { MemberSessions } from '../../gql/v2/query/FetchDevicesForMember';
import { formatLastLoginTime } from '../../utils/sta/util';
import { DeviceInfo } from '../../pages/UserProfilePage/sta/UserDeviceItemSTA';
import { REMOVE_SESSION_BY_ID_SELF_MUTATION } from '../../gql/v2/mutation/RemoveSessionByIdSelfMutation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: '24px 24px 16px 24px',
      padding: '0px',
      fontSize: '21px !important',
    },
    dialogDescription: {
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#4A4A4A',
      marginBottom: '0px',
    },
    dialogAction: {
      margin: '16px 24px 16px 24px',
      padding: '0px',
    },
    dialogIconButton: {
      position: 'relative',
      right: '10px',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    dialogContent: {
      padding: '0px 24px 40px 24px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  }),
);

interface Props {
  modalTitle: string;
  modalBody: string;
  isAllDevice: boolean;
  openModal: boolean;
  handleCloseModal: () => void;
  handleUserLogout: (isAllDevice: boolean) => void;
  session: MemberSessions;
}

export const DeviceLogoutModalSTA = ({
  modalTitle,
  modalBody,
  isAllDevice,
  openModal,
  handleCloseModal,
  handleUserLogout,
  session,
}: Props) => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = React.useState(false);

  const handleSubmit = (isAllDevice) => {
    setIsSubmit(true);
    handleUserLogout(isAllDevice);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <HCHeadingThree margin="24px 24px 16px 24px" lineHeight={'132%'}>
            {modalTitle}
          </HCHeadingThree>
          <IconButton onClick={handleCloseModal} className={classes.dialogIconButton}>
            <CloseIcon color="#4A4A4A" width="15" height="15" />
          </IconButton>
        </div>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogDescription} id="alert-dialog-description">
            {!isAllDevice && (
              <>
                {session.device?.id && <DeviceInfo>Device ID: {session.device.id}</DeviceInfo>}{' '}
                {session?.lastLogin && <DeviceInfo>Last login: {formatLastLoginTime(session.lastLogin)}</DeviceInfo>}{' '}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <SecondaryButton type="submit" onClick={handleCloseModal}>
            {`Don’t log out`}
          </SecondaryButton>
          <PrimaryButton type="button" disabled={isSubmit} onClick={() => handleSubmit(isAllDevice)}>
            {`Confirm`}
          </PrimaryButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};
