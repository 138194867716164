import gql from 'graphql-tag';
import {
  BasicOrganizationMemberFragment,
  FullOrganizationMemberFragment,
} from '../fragment/OrganizationMemberFragment';

export const FETCH_BASIC_MEMBERS_BY_USERS = gql`
  query FetchMembersByUserIds($organizationalUnit: OrganizationalUnitInput!, $userIds: [ID!]!) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          __typename
          ... on OrganizationalUnitNotFoundError {
            message
          }
          ... on AdminOrganizationQuery {
            syncStatus
            membersForIds(ids: $userIds) {
              __typename
              ... on BadRequestError {
                message
              }
              ...FetchMembersForIDsResponseFragment
            }
          }
        }
      }
    }
  }
  ${BasicOrganizationMemberFragment}
`;

export const FETCH_FULL_MEMBERS_BY_USERS = gql`
  query FetchMembersByUserIds($organizationalUnit: OrganizationalUnitInput!, $userIds: [ID!]!) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          __typename
          ... on OrganizationalUnitNotFoundError {
            message
          }
          ... on AdminOrganizationQuery {
            syncStatus
            membersForIds(ids: $userIds) {
              __typename
              ... on BadRequestError {
                message
              }
              ...FetchMembersForIDsResponseFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberFragment}
`;
