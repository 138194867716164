import React, { useState } from 'react';
import styled from 'styled-components';
import { InputAdornment, ListSubheader, withStyles } from '@material-ui/core';
import SearchIcon from 'src/assets/svgs/SearchIcon';

import { StyledTextField } from 'src/pages/VirtualPagersPage/components/shared';
import theme from 'src/assets/styles/theme';
import SearchFieldSelectDropDown from './SearchFieldSelectDropDown';
import { setSearchText } from 'src/redux/actions/virtualPagersActions';
import { useDispatch } from 'react-redux';
import ClearIcon from 'src/assets/svgs/ClearIcon';
import { typedUseSelector } from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { SearchFieldType } from 'src/types';
import { ASSIGNEE, DISPLAY_NAME, NUMBER } from 'src/constants/virtualPagerStrings';

const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 16px;
`;

const StyledInputAdornment = withStyles({
  root: {
    userSelect: 'none',
    color: theme.mainFontColor,
  },
})(InputAdornment);

export const StyledListSubheader = withStyles({
  root: {
    borderColor: theme.mainTealColor,
  },
})(ListSubheader);

type searchFieldTypeToTextMapping = { [key in Exclude<SearchFieldType, 'all'>]: string };

const options: searchFieldTypeToTextMapping = {
  number: NUMBER,
  userIds: ASSIGNEE,
  friendlyName: DISPLAY_NAME,
};

const SearchBar: React.FC = () => {
  const searchText = typedUseSelector((state) => state.virtualPagerReducer.searchText);
  const searchType = typedUseSelector((state) => state.virtualPagerReducer.searchType);
  const [localSearchText, setLocalSearchText] = useState<string>(searchText);
  const dispatch = useDispatch();

  const clearSearchText = () => {
    setLocalSearchText('');
    dispatch(setSearchText(''));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      dispatch(setSearchText(localSearchText));
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaListSearchPerformed,
        params: {
          search_type: options[searchType],
          search_query: localSearchText,
        },
      });
      e.target.blur();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchText(e.target.value);
    if (e.target.value === '') {
      dispatch(setSearchText(''));
    }
  };

  return (
    <SearchBarContainer>
      <SearchFieldSelectDropDown />
      <StyledTextField
        variant="outlined"
        id="outlined-adornment-search"
        style={{ background: 'white' }}
        inputProps={{
          style: {
            padding: '0px 2px 0px 8px',
            width: '573px',
            height: '36px',
            borderRadius: '2px',
          },
        }}
        InputProps={{
          startAdornment: (
            <StyledInputAdornment position="start">
              <SearchIcon width={20} height={20} stroke={theme.mainFontColor} />
            </StyledInputAdornment>
          ),
          endAdornment: (
            <StyledInputAdornment
              position="end"
              onClick={clearSearchText}
              style={{
                cursor: 'pointer',
                visibility: localSearchText === '' ? 'hidden' : 'visible',
              }}
            >
              <ClearIcon width={20} height={20} stroke={theme.mainFontColor} />
            </StyledInputAdornment>
          ),
          onKeyDown: handleKeyDown,
        }}
        placeholder="Search"
        value={localSearchText}
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
