import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { HCHeadingThree, HCBodySpan, SecondaryButton, TextButton } from 'src/components/shared/HypercareComponents';
import InfoIcon from 'src/assets/svgs/Info';
import {
  CANCEL,
  CLEAR_FILTERS,
  SEARCH,
  SEARCH_VIEW_HEADER,
  SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_1,
  SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_2,
  SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_3,
  SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_4,
} from 'src/constants/virtualPagerStrings';
import getNumberViewModal from './GetNumberViewModel';
import store from 'src/redux/store';
import { TwilioNumbers } from 'src/types';

import CountryDropdown from './components/CountryDropdown';
import SearchNumberResultGroup from './components/SearchResultGroup';
import { CloseBtn, Header, WideWidthStyleModal } from 'src/components/modals/ModalStyleComponents';
import { GetNumbersNextButton } from '../components/buttons';
import FormattedInputField from './components/TwillioNumberInputField';
import SearchResultLoader from './components/SearchResultLoader';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const ModalHeader = styled(Header)``;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 16px 24px 16px 0px;
  gap: 8px;
`;
const InfoBox = styled.div`
  background: ${theme.lightGrey};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const SearchGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
`;

const ButtonGroup = styled.div<{ isInvalidNumber: boolean }>`
  display: flex;
  gap: 8px;
  padding-bottom: ${(props) => (props.isInvalidNumber ? '23px' : '0px')};
`;

const CustomButtonSecondaryButton = styled(SecondaryButton)`
  color: ${theme.mainFontColor} !important;
  &:disabled {
    color: ${theme.warmGrey} !important;
    background-color: ${theme.greyBackground} !important;
    border-color: ${theme.greyBackground} !important;
  }
`;

const InfoIconContainer = styled.div`
  padding: 0px;
  margin: 0px;
`;

const CustomWideWidthStyleModal = styled(WideWidthStyleModal)`
  .ReactModal__Content {
    overflow-y: hidden !important;
    width: 100%;
    max-width: 680px;
    border-radius: 6px;
    max-height: 90%;
    padding: 0;
  }
`;

const ScrollableContent = styled.div`
  max-height: calc(70vh - 250px);
  overflow-y: auto;
  margin-top: 24px;
  border-radius: 4px;
`;

const ModalNumberSearchView = ({
  isOpen,
  closeModal,
  setPurchasingNumberList,
  purchasingNumberList,
  setConfirmPurchaseVisible,
}) => {
  // The searchNumbers use state is used to initialize the use effect which calls the twilio API
  const [searchNumbers, setSearchNumbers] = useState(false);

  const [twilioNumbersData, setTwilioNumbersData] = useState<TwilioNumbers[] | null>(null);
  const [twilioError, setTwilioError] = useState('');

  // The invalidNumber useState is used to adjust the alignment of the search items if the error message appears
  const [isInvalidNumber, setIsInvalidNumber] = React.useState<boolean>(false);

  // useState variable for loading
  const [isLoading, setIsLoading] = useState(false);

  // Initializing the Country Dropdown
  const currentRegion = store.getState()?.hypercareRegionReducer.hypercareRegion;
  const initialState = ['CA', 'US'].includes(currentRegion) ? currentRegion : '';
  const [selectedCountry, setSelectedCountry] = useState(initialState);

  // Initializing the Phone Number Input Field
  const initialSearchState = '';
  const [inputFieldValues, setInputFieldValues] = useState(initialSearchState);

  // API call to get the Twilio Numbers
  const { fetchTwilioAvailableNumbers } = getNumberViewModal();

  const isSearchButtonDisabled = inputFieldValues === '' || inputFieldValues.match(/[^0-9]/) !== null;

  const resetFilters = () => {
    setSelectedCountry(initialState);
    setInputFieldValues(initialSearchState);
    setSearchNumbers(false);
    setTwilioNumbersData(null);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersSearchClearFiltersPressed,
    });
  };

  const showConfirmPurchaseModal = () => {
    setConfirmPurchaseVisible(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersSelectedVirtualPagerNextButtonPressed,
      params: {
        selected_number_count: purchasingNumberList.length,
        selected_numbers: purchasingNumberList.map((number) => number.phoneNumber),
      },
    });
  };

  const handleSearchClick = () => {
    setIsLoading(true);
    setSearchNumbers(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersSearchInitiated,
      params: {
        numeric_search_query: inputFieldValues,
        country: selectedCountry,
      },
    });
  };

  useEffect(() => {
    const fetchTwilioData = async () => {
      if (searchNumbers) {
        const stringNumber = inputFieldValues;
        const formattedNumber = stringNumber.replace(/[\s()-]/g, '');
        let numbers = null;
        let errorMessage;
        try {
          const response = await fetchTwilioAvailableNumbers(selectedCountry, formattedNumber);
          numbers = response.numbers;
          errorMessage = '';
          setTwilioNumbersData(numbers);
          setTwilioError(errorMessage);
        } catch (error) {
          console.error('Error fetching data:', error);
          errorMessage = error.toString();
          setTwilioError(errorMessage);
        } finally {
          setIsLoading(false);
          setSearchNumbers(false);
          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.pvpaGetNumbersSearchResultsPopulated,
            params: {
              numeric_search_query: inputFieldValues,
              country: selectedCountry,
              result_count: numbers ? numbers.length : 0,
              error: errorMessage,
            },
          });
        }
      }
    };
    fetchTwilioData();
  }, [searchNumbers]);

  return (
    <CustomWideWidthStyleModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <div style={{ padding: '24px' }}>
        <ModalHeader>
          <HCHeadingThree>{SEARCH_VIEW_HEADER}</HCHeadingThree>
          <CloseBtn onClick={closeModal} />
        </ModalHeader>
        <InfoBox>
          <InfoIconContainer>
            <InfoIcon
              width={24}
              height={24}
              circlefill={theme.mainTealColor}
              strokefill={theme.white}
              stroke={theme.white}
            />
          </InfoIconContainer>
          <div>
            <HCBodySpan fontWeight={600} fontSize={'16px'}>
              {SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_1}
            </HCBodySpan>
            <HCBodySpan fontSize={'16px'}>{SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_2}</HCBodySpan>
            <a href="mailto:support@hypercare.com">
              <HCBodySpan fontSize={'16px'} isLink={true}>
                {SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_3}
              </HCBodySpan>
            </a>
            <HCBodySpan fontSize={'16px'}>{SEARCH_VIEW_MODAL_INFO_DESCRIPTION_PART_4}</HCBodySpan>
          </div>
        </InfoBox>

        <SearchGroupContainer>
          <div style={{ display: 'flex', gap: '12px' }}>
            <div style={{ paddingBottom: isInvalidNumber ? '23px' : '0px' }}>
              <CountryDropdown selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
            </div>
            <FormattedInputField
              InputFieldValues={inputFieldValues}
              setInputFieldValues={setInputFieldValues}
              isInvalidNumber={isInvalidNumber}
              setIsInvalidNumber={setIsInvalidNumber}
              onKeyPress={handleSearchClick}
            />
          </div>
          <ButtonGroup isInvalidNumber={isInvalidNumber}>
            <TextButton
              style={{ height: '36px', whiteSpace: 'nowrap' }}
              onClick={resetFilters}
              color={theme.mainFontColor}
            >
              {CLEAR_FILTERS}
            </TextButton>
            <CustomButtonSecondaryButton disabled={isSearchButtonDisabled} onClick={handleSearchClick}>
              {SEARCH}
            </CustomButtonSecondaryButton>
          </ButtonGroup>
        </SearchGroupContainer>

        {isLoading ? (
          <div style={{ marginTop: '24px' }}>
            <SearchResultLoader />
          </div>
        ) : (
          !!twilioNumbersData && (
            <ScrollableContent>
              <SearchNumberResultGroup
                numberData={twilioNumbersData}
                purchasingNumberList={purchasingNumberList}
                setPurchasingNumberList={setPurchasingNumberList}
              />
            </ScrollableContent>
          )
        )}
      </div>

      {!!twilioNumbersData && (
        <ModalFooter>
          <SecondaryButton onClick={closeModal}>{CANCEL}</SecondaryButton>
          <GetNumbersNextButton
            isDisabled={purchasingNumberList.length === 0}
            handleOnClick={showConfirmPurchaseModal}
            totalPurchases={purchasingNumberList.length}
          />
        </ModalFooter>
      )}
    </CustomWideWidthStyleModal>
  );
};

export default ModalNumberSearchView;
