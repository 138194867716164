import React, { useContext } from 'react';
import ProfilePageRepository from '../ProfilePageRepository';
import { toast } from 'react-toastify';
import { ADDRESS_ALREADY_INUSE, INVALID_PHONE_NUMBER } from 'src/constants/formErrors';
import { UserAddressAccess, UserAddress, UserProfileValues, AddressType } from 'src/types';
import {
  DUPLICATE_ADDRESSES,
  GET_OPC_NETWORK_ERROR,
  INVALID_ADDRESS_COUNT,
  INVALID_ADDRESS_UPDATE_REQUEST,
  OPC_DEFAULT_ERROR,
  UNVERIFIED_ADDRESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_FIELDS_NETWORK_ERROR,
  UPDATE_LICENSE_DATE_ERROR,
} from 'src/constants/networkError';
import { ADDRESS_ALREADY_INUSE_MESSAGE } from 'src/constants/strings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AuthContext } from 'src/auth';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { diffTwoAddressesArrays } from 'src/utils/diffTwoAddressArray';
import { sanitizePhoneNumbers } from '../../../../utils/formatPhoneNumber';

export default function ProfileViewModel() {
  const { updateAuthUserInfo } = useContext(AuthContext);
  const repo = ProfilePageRepository();

  const useGetOrganizationLabelOptions = () => {
    const result = repo.useFetchOrganizationLabelOptions();
    return {
      data: result.data?.organizationalUnitQuery.organizationalUnit.addressLabels,
      error: result.error,
      loading: result.loading,
      //refetch: result.refetch,
    };
  };

  const handleEditAddresses = async (
    userId: string,
    addressesToEdit: UserAddress[],
    preEditAddresses: UserAddress[],
  ) => {
    let success = true;

    for (const addressToEdit of addressesToEdit) {
      const correspondingExistingAddress = preEditAddresses.find((a) => a.address === addressToEdit.address);
      if (!correspondingExistingAddress) continue;

      const isLabelEdited = addressToEdit.label !== correspondingExistingAddress.label;
      const isAccessEdited = addressToEdit.access !== correspondingExistingAddress.access;

      try {
        if (isLabelEdited) {
          const result = await editAddressLabel(userId, addressToEdit.address, addressToEdit.label);
          if (result.error) success = false;
        }
        if (isAccessEdited) {
          const result = await editAddressAccess(userId, addressToEdit.address, addressToEdit.access);
          if (result.error) success = false;
        }
      } catch (e) {
        success = false;
      }
    }

    return success;
  };

  const handleUpdateProfile = async (
    userId: string,
    selfId: string,
    values: UserProfileValues,
    preEditAddresses: UserAddress[],
  ): Promise<{ success: boolean | undefined; error: boolean | string }> => {
    const diffResult = diffTwoAddressesArrays(values.addresses, preEditAddresses, true);
    const { added, deleted, edited } = diffResult;
    let updateAddAddressResult = null;
    let updateDeleteAddressResult = null;
    let updateEditAddressesSuccess = true;
    if (deleted.length > 0) {
      updateDeleteAddressResult = await handleDeleteAddresses(userId, deleted);
    }
    if (added.length > 0) {
      updateAddAddressResult = await handleAddAddresses(userId, added);
    }
    if (edited.length > 0) {
      updateEditAddressesSuccess = await handleEditAddresses(userId, edited, preEditAddresses);
    }
    const updateAddressPreferenceResult = await updateAddressPreference(userId, values.addresses);
    const updateProfileFieldsResult = await updateProfileFields(userId, selfId, values);
    const updateLicenseDateResult = await updateLicenseDate(userId, values);
    const successCheck =
      updateAddressPreferenceResult?.success && updateProfileFieldsResult?.success && updateLicenseDateResult?.success;
    const addressesUpdateSuccess =
      updateAddAddressResult?.success && updateDeleteAddressResult?.success && updateEditAddressesSuccess;

    if (
      ((updateAddAddressResult?.success || updateDeleteAddressResult?.success) && successCheck) ||
      (addressesUpdateSuccess && successCheck) ||
      successCheck
    ) {
      return { success: true, error: false };
    }

    let error: string | boolean = UPDATE_PROFILE_ERROR;

    if (updateAddAddressResult?.error) {
      error = updateAddAddressResult.error;
    } else if (updateAddressPreferenceResult?.error) {
      error = updateAddressPreferenceResult.error;
    }

    if (updateProfileFieldsResult?.error) {
      error = UPDATE_PROFILE_FIELDS_NETWORK_ERROR;
    }

    if (updateLicenseDateResult?.error) {
      error = UPDATE_LICENSE_DATE_ERROR;
    }

    return { success: false, error };
  };

  const updateAddressPreference = async (userId: string, addresses: UserAddress[]) => {
    const addressArray = addresses.map((address) => {
      return address.address;
    });
    const result = await repo.updateProfilePreference(userId, addressArray);
    const responseObject = result.result?.data?.adminMutation?.organizationalUnit?.member?.updateAddressPreference;
    if (responseObject?.addresses) {
      return {
        success: true,
        error: false,
      };
    }

    let error = OPC_DEFAULT_ERROR;

    if (responseObject?.message || result.error) {
      switch (responseObject?.__typename) {
        case INVALID_ADDRESS_UPDATE_REQUEST:
          error = GET_OPC_NETWORK_ERROR(INVALID_ADDRESS_UPDATE_REQUEST);
          break;
        case DUPLICATE_ADDRESSES:
          error = GET_OPC_NETWORK_ERROR(DUPLICATE_ADDRESSES);
          break;

        case UNVERIFIED_ADDRESS:
          error = GET_OPC_NETWORK_ERROR(UNVERIFIED_ADDRESS);
          break;

        case INVALID_ADDRESS_COUNT:
          error = GET_OPC_NETWORK_ERROR(INVALID_ADDRESS_COUNT);
          break;

        default:
          return { success: false, error };
      }
      return { success: false, error };
    }
  };

  const handleAddAddresses = async (userId: string, added: UserAddress[]) => {
    try {
      const addedAddressesPayload = added.map((ele) => {
        if (
          ele.type === AddressType.PHONE_NUMBER ||
          ele.type === AddressType.ALPHANUMERIC_PAGER ||
          ele.type === AddressType.NUMERIC_PAGER
        )
          ele.address = sanitizePhoneNumbers(ele.address);
        delete ele['id'];
        delete ele['syncStatus'];
        delete ele['__typename'];
        return ele;
      });
      const result = await repo.addUserAddresses(userId, addedAddressesPayload);

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.newAddressAddded,
        params: {
          type: addedAddressesPayload[0].type,
          address: addedAddressesPayload[0].address,
        },
      });

      const responseObject = result?.data?.admin?.user?.addAddresses;
      if (responseObject?.addresses) {
        return {
          success: true,
          error: false,
        };
      }
    } catch (e) {
      let errMsg: string;
      console.error(e.error.message);
      if (e?.name === INVALID_PHONE_NUMBER) {
        errMsg = e.message;
      } else if (e?.name === ADDRESS_ALREADY_INUSE) {
        errMsg = e.message;
      } else if (e?.networkError?.result?.errors[0]?.name) {
        const errorName = e.networkError.result.errors[0].name;
        if (errorName === ADDRESS_ALREADY_INUSE) errMsg = ADDRESS_ALREADY_INUSE_MESSAGE;
      } else {
        errMsg = e.error.message;
      }
      return {
        success: false,
        error: errMsg || e.message,
      };
    }
  };

  const handleDeleteAddresses = async (userId, deleted) => {
    try {
      const removedAddressesPayload = deleted.map((ele) => ele.id);
      const result = await repo.removeUserAddresses(userId, removedAddressesPayload);
      const responseObject = result?.data?.admin?.user?.removeAddresses || result?.data?.admin?.user?.addAddresses;
      if (responseObject?.addresses) {
        return {
          success: true,
          error: false,
        };
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const updateProfileFields = async (userId, selfId, values) => {
    const result = await repo.updateProfileFormFields(userId, values);
    if (result.result) {
      const { id } = result.result.data.adminMutation.organizationalUnit.member.updateProfile;
      if (selfId === id) updateAuthUserInfo(result?.result.data.adminMutation.organizationalUnit.member.updateProfile);
      return { success: true, error: false };
    }
    if (result.error) {
      return { success: false, error: result.error };
    }
  };

  const updateLicenseDate = async (userId, values) => {
    const result = await repo.updateLicenseStartEndDate(userId, values);
    if (result.result) {
      return { success: true, error: false };
    }
    if (result.error) {
      return { success: false, error: result.error };
    }
  };

  const editAddressLabel = async (userId: string, address: string, label: string) => {
    const org = checkOrganizationalUnit();
    if (org.type === 'error') {
      return {
        error: 'missing org',
      };
    }
    const result = await repo.editAddressLabel({ organizationalUnit: org }, userId, address, label);

    return {
      data: result.data,
      error: result.error,
    };
  };

  const editAddressAccess = async (userId: string, address: string, access: UserAddressAccess) => {
    const org = checkOrganizationalUnit();
    if (org.type === 'error') {
      return {
        error: 'missing org',
      };
    }
    const result = await repo.editAddressAccess({ organizationalUnit: org }, userId, address, access);

    return {
      data: result.data,
      error: result.error,
    };
  };

  return {
    updateAddressPreference,
    updateProfileFields,
    handleUpdateProfile,
    handleAddAddresses,
    handleDeleteAddresses,
    useGetOrganizationLabelOptions,
    editAddressLabel,
    editAddressAccess,
  };
}
