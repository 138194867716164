import gql from 'graphql-tag';

export const ADD_ADDRESSES_MUTATION = gql`
  mutation AddAddresses($organizationalUnit: OrganizationalUnitInput!, $id: ID!, $addresses: [CreateAddressInput!]!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $id) {
              ... on UserNotFoundError {
                __typename
                message
              }
              ... on FullOrganizationMemberMutation {
                addAddresses(addresses: $addresses) {
                  __typename
                  addresses {
                    __typename
                    ... on Address {
                      address
                      type
                      organization {
                        id
                        name
                      }
                      syncStatus
                      label
                      createdAt
                      updatedAt
                    }
                    ... on AddressInUse {
                      __typename
                      message
                    }
                    ... on UserNotFoundError {
                      __typename
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
