import React, { useState } from 'react';
import { PaginatedLicensedMembersSTA } from '../../../gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import { PaginatedBlackListedMembersSTA } from '../../../gql/v2/query/sta/FetchPaginatedRemovedUsersQuerySTA';
import { PaginatedPendingInvitesSTA } from '../../../types/PaginatedTypes';

export const usePaginatedSearchStateSTA = () => {
  const [searchLicensedUserData, setSearchLicensedUserData] = useState<PaginatedLicensedMembersSTA>(null);
  const [searchBlackListedUserData, setSearchBlackListedUserData] = useState<PaginatedBlackListedMembersSTA>(null);
  const [searchPendingInviteData, setSearchPendingInviteData] = useState<PaginatedPendingInvitesSTA>(null);
  const [searchPendingAdminApprovalInviteData, setSearchPendingAdminApprovalInviteData] =
    useState<PaginatedPendingInvitesSTA>(null);

  const [localSearchText, setLocalSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  return {
    searchLicensedUserData,
    searchBlackListedUserData,
    searchPendingInviteData,
    setSearchLicensedUserData,
    setSearchBlackListedUserData,
    setSearchPendingInviteData,
    searchPendingAdminApprovalInviteData,
    setSearchPendingAdminApprovalInviteData,
    localSearchText,
    searchLoading,
    setLocalSearchText,
    setSearchLoading,
  };
};
