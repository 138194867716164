import React from 'react';

export const CredentialsIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1948_3264)">
      <path
        d="M16 22C16.7911 22 17.5645 21.7654 18.2223 21.3259C18.8801 20.8864 19.3928 20.2616 19.6955 19.5307C19.9983 18.7998 20.0775 17.9956 19.9231 17.2196C19.7688 16.4437 19.3878 15.731 18.8284 15.1716C18.269 14.6122 17.5563 14.2312 16.7804 14.0769C16.0044 13.9225 15.2002 14.0017 14.4693 14.3045C13.7384 14.6072 13.1136 15.1199 12.6741 15.7777C12.2346 16.4355 12 17.2089 12 18C12 19.0609 12.4214 20.0783 13.1716 20.8284C13.9217 21.5786 14.9391 22 16 22ZM16 16C16.3956 16 16.7822 16.1173 17.1111 16.3371C17.44 16.5568 17.6964 16.8692 17.8478 17.2346C17.9991 17.6001 18.0387 18.0022 17.9616 18.3902C17.8844 18.7781 17.6939 19.1345 17.4142 19.4142C17.1345 19.6939 16.7781 19.8844 16.3902 19.9616C16.0022 20.0387 15.6001 19.9991 15.2346 19.8478C14.8692 19.6964 14.5568 19.44 14.3371 19.1111C14.1173 18.7822 14 18.3956 14 18C14 17.4696 14.2107 16.9609 14.5858 16.5858C14.9609 16.2107 15.4696 16 16 16Z"
        fill="#4A4A4A"
      />
      <path d="M18 6H14V8H18V6Z" fill="#4A4A4A" />
      <path
        d="M24 2H8C7.46973 2.00053 6.96133 2.21141 6.58637 2.58637C6.21141 2.96133 6.00053 3.46973 6 4V28C6.00061 28.5302 6.21152 29.0386 6.58646 29.4135C6.9614 29.7885 7.46975 29.9994 8 30H24C24.5302 29.9993 25.0385 29.7883 25.4134 29.4134C25.7883 29.0385 25.9993 28.5302 26 28V4C25.9994 3.46975 25.7885 2.9614 25.4135 2.58646C25.0386 2.21152 24.5302 2.00061 24 2ZM20 28H12V26C12 25.7348 12.1054 25.4804 12.2929 25.2929C12.4804 25.1054 12.7348 25 13 25H19C19.2652 25 19.5196 25.1054 19.7071 25.2929C19.8946 25.4804 20 25.7348 20 26V28ZM22 28V26C22 25.2044 21.6839 24.4413 21.1213 23.8787C20.5587 23.3161 19.7956 23 19 23H13C12.2044 23 11.4413 23.3161 10.8787 23.8787C10.3161 24.4413 10 25.2044 10 26V28H8V4H24V28H22Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1948_3264">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
