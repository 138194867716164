import * as qs from 'qs';
import { AudioType, HTTPMethods } from 'src/types';
import { getAuthToken, getAdminScopeToken, getCurrentOrganizationId } from 'src/utils/getLocalAuth';
import getPVPAApiEndpoint from 'src/utils/getPVPAApiEndpoint';
import { v4 as uuidv4 } from 'uuid';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import client from 'src/clients/apolloClient';
import { FETCH_BASIC_MEMBERS_BY_USERS } from 'src/gql/v2/query/FetchMembersByUsers';
type PVPARequestProps = {
  method: HTTPMethods;
  path: string;
  headers?: { [key: string]: string };
  body?: { [key: string]: any };
};

class PVPAApiHelper {
  static PVPAEndpoint = getPVPAApiEndpoint();

  private static async executePVPARequest({ method, path, headers = {}, body = {} }: PVPARequestProps) {
    const myHeaders = this.getHeaders(headers);
    const requestOptions = {
      method: method,
      headers: myHeaders,
      ...(method === 'GET' ? {} : { body: JSON.stringify(body) }),
    };

    try {
      const data = await fetch(`${this.PVPAEndpoint}${path}`, requestOptions);
      const response = data.json();

      return response;
    } catch (e) {
      console.error('Error:', e);
      return e;
    }
  }

  private static async executeAPIRequest({ method, path, headers = {}, body = {} }: PVPARequestProps) {
    const myHeaders = this.getHeaders(headers);
    const requestOptions = {
      method: method,
      headers: myHeaders,
      ...(method === 'GET' ? {} : { body: JSON.stringify(body) }),
    };

    try {
      const data = await fetch(`${getApiEndpoint()}${path}`, requestOptions);
      const response = data.json();

      return response;
    } catch (e) {
      console.error('Error:', e);
      return e;
    }
  }

  private static getHeaders(headers: { [key: string]: string }): Headers {
    const myHeaders = new Headers();
    myHeaders.append('Hypercare-Scope', getAdminScopeToken());
    myHeaders.append('X-Request-ID', uuidv4());
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${getAuthToken()}`);

    for (const key in headers) {
      myHeaders.append(key, headers[key]);
    }

    return myHeaders;
  }

  public static async fetchOrgPurchasedNumbers(
    orgId: number,
    nextCursor: string | null,
    sortField: string,
    sortOrder: string,
    type: string,
    friendlyName?: string,
    number?: string,
    userIds?: string,
    limit?: number,
  ) {
    const queryParams = {
      type: type,
      limit: limit || 100,
      sortField: sortField,
      sortOrder: sortOrder,
      orgId: orgId,
      cursor: nextCursor,
      friendlyName: friendlyName,
      number: number,
      userIds: userIds,
    };
    const queryString = qs.stringify(queryParams);

    return await this.executePVPARequest({
      method: 'GET',
      path: `/numbers/search?${queryString}`,
    });
  }

  //Private
  public static fetchUserByUserID = async (userIds: string[]) => {
    const currentOrgId = getCurrentOrganizationId();
    const membersRes = await client.query({
      query: FETCH_BASIC_MEMBERS_BY_USERS,
      variables: {
        organizationalUnit: {
          id: currentOrgId,
          type: 'organization',
        },
        userIds: userIds,
      },
    });

    const members = membersRes?.data?.adminQuery?.organizationalUnit?.membersForIds?.members;
    if (!members) {
      throw new Error('Error while fetching members by user ids');
    }

    return members.map((m) => {
      if (m.id) {
        return {
          id: m.id,
          firstname: m.firstName,
          lastname: m.lastName,
          role: m.role,
        };
      }
      return null;
    });
  };

  public static fetchRoleByRoleID = async (roleId: number) => {
    const res = await this.executeAPIRequest({
      method: 'POST',
      path: '/graphql/private',
      body: {
        query:
          'query FetchRole($roleId: Int!) { locating { role(id: $roleId) { ...RoleFragment } }}fragment RoleFragment on Role { id auditId name department { name }}',
        variables: { roleId: roleId },
      },
    });

    const response = {
      roleId: res.data.locating.role.id,
      roleAudiId: res.data.locating.role.auditId,
      roleName: res.data.locating.role.name,
      department: res.data.locating.role.department.name,
    };

    return response;
  };

  public static async fetchTwilioAvailableNumbers(iso_country: string, contains: string) {
    const queryParams = {
      limit: 20,
      iso_country: iso_country,
      contains: contains,
    };
    const queryString = qs.stringify(queryParams);

    return await this.executePVPARequest({
      method: 'GET',
      path: `/numbers/fetch-available?${queryString}`,
    });
  }

  public static async purchaseTwilioNumbers(number: string, orgId: string, locality: string) {
    return await this.executePVPARequest({
      method: 'POST',
      path: `/numbers/purchase`,
      body: {
        number: number,
        organizationId: orgId,
        userIds: [],
        locality: locality,
      },
    });
  }

  public static async searchOrgUsers(searchString: string, continuationId?: string) {
    return await this.executeAPIRequest({
      method: 'POST',
      path: '/v2/graphql/private/schema',
      body: {
        query:
          'query search($text: String!, $direction: PaginationDirection, $continuationId: ID, $limit: Int) {\n    searchQuery(text: $text) {\n        searchUsers(continuationId: $continuationId, limit: $limit, direction: $direction) {\n            continuationId\n            totalResultsCount\n            users {\n                user {\n                    id\n                    firstName\n                    lastName\n                }\n                matchedSequences\n            }\n        }\n    }\n}\n',
        variables:
          continuationId !== null ? { text: searchString, continuationId: continuationId } : { text: searchString },
      },
    });
  }

  public static async configurePager(
    numberId: string,
    type: string,
    friendlyName?: string,
    alertPreference?: string,
    status?: string,
    callbackId?: string,
    voicemailId?: string,
    isGroupChat?: boolean,
    userIds?: string[],
    roleIds?: string[],
  ) {
    return await this.executePVPARequest({
      method: 'PATCH',
      path: `/numbers/configure`,
      body: {
        numberObjectId: numberId,
        type: type,
        friendlyName: friendlyName,
        alertPreference: alertPreference,
        status: status,
        callbackId: callbackId,
        voicemailId: voicemailId,
        isGroupChat: isGroupChat,
        userIds: userIds,
        roleIds: roleIds,
      },
    });
  }

  public static async generateTextToSpeech(fileName: string, text: string) {
    return await this.executePVPARequest({
      method: 'POST',
      path: `/text-to-speech/generate-link`,
      body: {
        fileName: fileName,
        text: text,
      },
    });
  }

  public static async fetchOrgAudios(organizationId: string, limit: number, filter: string) {
    const queryParams = {
      organizationId: organizationId,
      limit: limit,
      filter: filter,
    };
    const queryString = qs.stringify(queryParams);

    return await this.executePVPARequest({
      method: 'GET',
      path: `/audio-steps?${queryString}`,
    });
  }

  private static async uploadFileToMainAPI(fileInput: File) {
    try {
      var myHeaders = new Headers();
      myHeaders.append('Accept', '*/*');
      myHeaders.append('X-Request-ID', uuidv4());
      myHeaders.append('Host', getApiEndpoint());
      myHeaders.append('Connection', 'keep-alive');
      myHeaders.append('Hypercare-Scope', getAdminScopeToken());
      myHeaders.append('Accept-Encoding', 'gzip, deflate, br');
      myHeaders.append('Authorization', `Bearer ${getAuthToken()}`);
      myHeaders.append('Content-Length', fileInput.size.toString());

      const formData = new FormData();
      formData.append('file', fileInput, fileInput.name);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      };

      const data = await fetch(`${getApiEndpoint()}/upload_files`, requestOptions);
      const response = data.json();

      return response;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  public static async uploadAudioToMainAPI(fileInput: File) {
    try {
      const mainAPIResponse = await this.uploadFileToMainAPI(fileInput);
      return mainAPIResponse.response[0];
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  public static async uploadAudioToPVPA(audioURL: string, fileName: string) {
    return await this.executePVPARequest({
      method: 'POST',
      path: `/audio-step/upload-audio`,
      body: {
        url: audioURL,
        fileName: fileName,
      },
    });
  }

  public static async fetchSingleAudio(audioStepId: string) {
    const queryParams = { audioStepId: audioStepId };
    const queryString = qs.stringify(queryParams);

    return await this.executePVPARequest({
      method: 'GET',
      path: `/audio-step?${queryString}`,
    });
  }

  public static async createAudioSteps(organizationId: number, name: string, type: AudioType, audio: string) {
    return await this.executePVPARequest({
      method: 'POST',
      path: `/audio-steps/create`,
      body: {
        organizationId: organizationId,
        name: name,
        type: type,
        audio: audio,
      },
    });
  }

  public static async fetchScheduleByMonthAndYear(month: string, year: number) {
    return await this.executeAPIRequest({
      method: 'POST',
      path: '/graphql/private',
      body: {
        query:
          'query fetchSites ($month: ScheduleMonths, $year: Int) {\n    locating {\n        sites {\n            ...SiteFragment\n        }\n    }\n}\n\nfragment SiteFragment on Site { \n    # id\n    # name\n    # image\n    departments {\n        ...DepartmentFragment\n    }\n    # favouritedRoles {\n    #     ...RoleFragment\n    # }\n    # createdAt\n    # updatedAt\n}\n\nfragment DepartmentFragment on Department {\n    id\n    name\n    # image\n    schedule(month: $month, year: $year) {\n        ...ScheduleFragment\n    }\n    # createdAt\n    # updatedAt\n}\n\nfragment ScheduleFragment on Schedule {\n    # id\n    # name\n    # startDate\n    # endDate\n    # state\n    roles {\n        ...RoleFragment\n    }\n    # createdAt\n    # updatedAt\n}\n\nfragment RoleFragment on Role {\n    id\n    auditId\n    name\n    # startTime\n    # duration\n    # pagerNumber\n    # site {\n    #     id\n    #     name\n    # }\n    # currentShift {\n    #     ...ShiftFragment\n    # }\n    # nextShift {\n    #     ...ShiftFragment\n    # }\n    # createdAt\n    # updatedAt\n}\n\n# fragment ShiftFragment on Shift {\n#     id\n#     startDate\n#     endDate\n#     user {\n#         ...GeneralUserFragment\n#     }\n# }\n\n# fragment GeneralUserFragment on GeneralUser {\n#     id\n#     firstname\n#     lastname\n#     username\n# }',
        variables: { month: month, year: year },
      },
    });
  }

  public static async fetchPagerById(pagerId: string) {
    return await this.executePVPARequest({
      method: 'GET',
      path: `/number?id=${pagerId}`,
    });
  }
}

export default PVPAApiHelper;
