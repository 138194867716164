import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import Button from '@material-ui/core/Button';
import { getDeviceName } from 'src/utils/getDeviceName';
import { DEVICE_LOGOUT_BUTTON, WEB_DEVICE } from 'src/constants/user';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import { MemberSessions } from '../../../gql/v2/query/FetchDevicesForMember';
import moment from 'moment-timezone';
import { formatLastLoginTime } from '../../../utils/sta/util';

const DeviceDetailsContainer = styled.div`
  padding-bottom: 8px;
  margin-left: 4%;
`;

const LogoutButtonContainer = styled.div`
  display: grid;
  align-items: center;
`;

const DeviceTitle = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.darkenFontColor};
  margin-bottom: 8px;
  width: 24rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DeviceInfo = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
  margin-bottom: 4px;
`;

const ButtonStyled = styled(Button)`
  font-family: 'Nunito Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 24px !important;
  color: #4a4a4a !important;
  padding: 8px 12px !important;
  background: #ffffff !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  text-transform: inherit !important;

  &:hover {
    background-color: #d8d8d8 !important;
  }
`;

const DeviceListContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  padding: 16px;
  gap: 8px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
`;

export const UserDeviceItemSTA = ({
  memberSession,
  handleOpenModal,
}: {
  memberSession: MemberSessions;
  handleOpenModal: (device: MemberSessions) => void;
}) => {
  return (
    <DeviceDetailsContainer>
      <DeviceListContainer>
        <div>
          <DeviceTitle>{getDeviceName(memberSession.device?.type)}</DeviceTitle>
          {memberSession.device?.id && <DeviceInfo>Device ID: {memberSession.device?.id}</DeviceInfo>}{' '}
          {memberSession?.lastLogin && (
            <DeviceInfo>Last login: {formatLastLoginTime(memberSession.lastLogin)}</DeviceInfo>
          )}{' '}
        </div>
        <LogoutButtonContainer>
          <ButtonStyled onClick={() => handleOpenModal(memberSession)}>{DEVICE_LOGOUT_BUTTON}</ButtonStyled>
        </LogoutButtonContainer>
      </DeviceListContainer>
    </DeviceDetailsContainer>
  );
};
