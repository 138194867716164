const mainButtonColor = '#ff0043';
const mainBlueColor = '#3569e4';
const mainGreenColor = '#009689';
const mainTealColor = '#00859A';
const inputLabelColor = '#aeaeae';
const mainFontColor = '#4a4a4a';
const darkenFontColor = '#222222';
const mainBackgroundColor = '#fafafa';
const borderBottomLight = '#d8d8d8';
const warmGrey = '#9b9b9b';
const disabled = '#fcd6db';
const lightYellow = '#FFF4CD';
const orangedYellow = '#FFE382';
const errorRed = '#FF3E55';
const backdropRed = '#ffd8dd';
const warmGreyColor = '#BCBCBC';
const lightGrey = '#F2F2F2';
const white = '#FFFFFF';
const lighterGrey = '#DCE1E6';
const lighterTeal = '#CCE7EB';
const warmRed = '#D7293E';
const black = '#000000';
const hoverGrey = '#f6f6f9';
const yellowBackground = '#FFF4CD';
const blueBackground = '#CCE7EB';
const greyBackground = '#D8D8D8';
const yellowBorder = '#ECAD0B';
const blueBorder = '#00859A';
const greyBorder = '#BCBCBC';
const ghostWhite = '#F6F6F9';
const borderColor = '#d1d1d1';
const textLightTertiary = '#767676';
const orangeBackground = '#FDECE1';
const redBorder = '#FF9FAA';
const orangeBorder = '#FA7A23';
const errorRedBorder = '#F44336';
const backgroundColor = '#f8fafc';
const watermelon = '#ff3e55';
// TODO: remove existing scss-styling and inline-styling, convert all to styled-components

export default {
  textLightTertiary,
  mainButtonColor,
  mainBlueColor,
  inputLabelColor,
  darkenFontColor,
  mainFontColor,
  mainGreenColor,
  mainTealColor,
  mainBackgroundColor,
  borderBottomLight,
  warmGrey,
  disabled,
  lightYellow,
  orangedYellow,
  errorRed,
  warmGreyColor,
  lightGrey,
  white,
  lighterGrey,
  lighterTeal,
  warmRed,
  black,
  backdropRed,
  hoverGrey,
  yellowBackground,
  blueBackground,
  greyBackground,
  yellowBorder,
  blueBorder,
  greyBorder,
  ghostWhite,
  borderColor,
  orangeBackground,
  orangeBorder,
  redBorder,
  errorRedBorder,
  backgroundColor,
  watermelon,
};
