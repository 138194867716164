import React from 'react';

export const CalendarIconV2 = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1948_3282)">
      <path
        d="M26 4H22V3C22 2.44772 21.5523 2 21 2C20.4477 2 20 2.44772 20 3V4H12V3C12 2.44772 11.5523 2 11 2C10.4477 2 10 2.44772 10 3V4H6C4.9 4 4 4.9 4 6V26C4 27.1 4.9 28 6 28H26C27.1 28 28 27.1 28 26V6C28 4.9 27.1 4 26 4ZM26 26H6V12H26V26ZM26 10H6V6H10V7C10 7.55228 10.4477 8 11 8C11.5523 8 12 7.55228 12 7V6H20V7C20 7.55228 20.4477 8 21 8C21.5523 8 22 7.55228 22 7V6H26V10Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1948_3282">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
