import React, { useState } from 'react';
import moment from 'moment';
import UserAvatar from 'src/components/shared/UserAvatar';
import Button from '@material-ui/core/Button';
import ResetPasswordModal from 'src/components/modals/ResetPasswordModal';
import { User, UserLicensingStatus, FetchUserProfileResultFinal } from 'src/types';
import styled from 'styled-components';
import ResendInviteModal from 'src/components/modals/ResendInviteModal';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import HypercareModal from 'src/components/shared/HypercareModal';
import { CANCEL } from 'src/constants/strings';
import { toast } from 'react-toastify';
import { DISCARDED } from 'src/constants/strings';
import { UserAddress } from 'src/types/User';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import ResendInviteModalSTA from 'src/components/modals/ResendInviteModalSTA';

const LicenseExpirationBoxWrapper = styled.div`
  margin-top: 20px;
  button {
    max-width: fit-content;
  }
`;

const ExpirationBoxContent = styled.div`
  background: rgb(255, 244, 233);
  border: 1px solid wheat;
  min-width: 240px;
  border-radius: 4px;
  padding: 20px 20px 20px 50px;
  line-height: 1.4;
  position: relative;
  color: ${(props) => props.theme.mainFontColor};
  max-width: 300px;
  &:before {
    display: block;
    position: absolute;
    left: 20px;
    top: 24px;
    content: '!';
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.mainFontColor};
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: ${(props) => props.theme.mainFontColor};
  }
`;

const ExpirationTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const ExpirationDetail = styled.div`
  font-size: 14px;
  margin-bottom: 1em;
`;

interface Props {
  user: User;
  enableEditing: () => void;
  onCancelForm: () => void;
  onSubmitForm: () => void;
  isInputDisabled: boolean;
  isSubmitting: boolean;
  userLicenseStatus: UserLicensingStatus;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
}
const FormButtons = ({
  user,
  enableEditing,
  onCancelForm,
  onSubmitForm,
  isInputDisabled,
  isSubmitting,
  userLicenseStatus,
  setCurrentAddresses,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const handleOpenModal = () => {
    setShowModal(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.resetPasswordButtonPressed,
    });
  };

  const handleDiscardModal = (resetForm) => {
    setCurrentAddresses(user?.addresses);
    resetForm();
    setShowDiscardModal(false);
    toast(DISCARDED, {
      className: 'toast-discard',
      autoClose: 2000,
    });
  };

  const handleIsEditButtonPressed = () => {
    enableEditing();
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileEditButtonPressed,
    });
  };

  const handleDiscardButtonPressed = () => {
    setShowDiscardModal(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileDiscardButtonPressed,
    });
  };

  const handleUpdateButtonPressed = () => {
    onSubmitForm();
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileDoneButtonPressed,
    });
  };

  return (
    <div className={ldapDirectorySyncFlag ? 'profileBox__editButtonDirSyncWrapper' : 'profileBox__editButtonWrapper'}>
      {isInputDisabled && !isSubmitting ? (
        <React.Fragment>
          <Button
            fullWidth
            disableTouchRipple
            variant="contained"
            color="secondary"
            onClick={handleIsEditButtonPressed}
          >
            Edit Profile
          </Button>
          {!ldapDirectorySyncFlag && (
            <Button fullWidth disableTouchRipple variant="outlined" color="secondary" onClick={handleOpenModal}>
              Reset Password
            </Button>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            fullWidth
            disableTouchRipple
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleUpdateButtonPressed}
          >
            {isSubmitting ? 'Sending Request...' : 'Update Profile'}
          </Button>
          {!isSubmitting && (
            <Button
              fullWidth
              disableTouchRipple
              color="secondary"
              variant="outlined"
              onClick={handleDiscardButtonPressed}
            >
              {CANCEL}
            </Button>
          )}
        </React.Fragment>
      )}
      {showModal && <ResetPasswordModal showModal={showModal} handleCloseModal={() => setShowModal(false)} />}
      {showDiscardModal && (
        <HypercareModal
          id="discard-modal"
          width="xs"
          title="Discard unsaved changes?"
          titleFontSize="21px"
          subtitle=""
          closeModal={() => setShowDiscardModal(false)}
          isModalVisible={showDiscardModal}
          modalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Keep editing',
              onClickHandler: () => setShowDiscardModal(false),
              id: 'discard-nevermind',
            },
            {
              type: 'primary',
              buttonLabel: 'Discard',
              onClickHandler: () => handleDiscardModal(onCancelForm),
              id: 'discard-confirm',
            },
          ]}
        />
      )}
    </div>
  );
};

const ProfileBoxImage = ({ user }: { user: User }) => {
  return (
    <div className="profileBox__imageWrapper">
      <UserAvatar profileSize="large" user={user} />
    </div>
  );
};

const ProfileBoxJoinDate = ({ user }: { user: User }) => {
  if (user && user.joinDate) {
    return <div className="profileBox__joinDate">Joined {moment(user.joinDate).format('MMM DD, YYYY')}</div>;
  }
  return null;
};

const LicenseExpirationBox = ({ isPending, user }: { isPending: boolean; user: User }) => {
  const [showResendInviteModal, setShowResendInviteModal] = React.useState<boolean>(false);
  const staFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);

  const warningTitle = isPending ? `An invitation has been sent` : `License expired`;
  const warningDetail = isPending
    ? `Profile information will not be able to be edited until user have accepted the invitation`
    : `Profile information will not be able to be edited until user has been reinvited and accepts the invitation`;

  return (
    <React.Fragment>
      <LicenseExpirationBoxWrapper>
        <ExpirationBoxContent>
          <ExpirationTitle>{warningTitle}</ExpirationTitle>
          <ExpirationDetail>{warningDetail}</ExpirationDetail>
          <Button
            fullWidth
            disableTouchRipple
            color="secondary"
            variant="contained"
            onClick={() => setShowResendInviteModal(true)}
            disabled={!staFlag && !user.addresses.some((address) => address.type === 'email')}
          >
            Reinvite user
          </Button>
        </ExpirationBoxContent>
      </LicenseExpirationBoxWrapper>
      {showResendInviteModal && !staFlag && (
        <ResendInviteModalSTA
          user={user}
          isPending={isPending}
          showModal={showResendInviteModal}
          handleCloseModal={() => setShowResendInviteModal(false)}
        />
      )}
      {showResendInviteModal && staFlag && (
        <ResendInviteModal
          user={user}
          isPending={isPending}
          showModal={showResendInviteModal}
          handleCloseModal={() => setShowResendInviteModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default (props: Props) => {
  const { userLicenseStatus } = props;

  return (
    <div className="profileBox">
      <ProfileBoxImage user={props.user} />
      {userLicenseStatus === 'licensed' ? (
        <React.Fragment>
          <FormButtons {...props} />
          <ProfileBoxJoinDate user={props.user} />
        </React.Fragment>
      ) : (
        <LicenseExpirationBox isPending={userLicenseStatus === 'pending'} user={props.user} />
      )}
    </div>
  );
};
