import gql from 'graphql-tag';

export type RemoveSessionForUserMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        session: {
          remove: {
            id: string;
          };
        };
      };
    };
  };
};

export const REMOVE_DEVICE_FOR_USER_MUTATION = gql`
  mutation RemoveDeviceForUser($sessionId: ID!, $memberId: ID!, $organizationalUnit: OrganizationalUnitInput!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $memberId) {
              ... on FullOrganizationMemberMutation {
                session(id: $sessionId) {
                  ... on SessionNotFound {
                    __typename
                    message
                  }
                  ... on SessionMutation {
                    remove {
                      ... on FullOrganizationMember {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
