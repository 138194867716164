import React, { createContext } from 'react';

interface IAuthRegionModalContext {
  changeRegionModalOpenStatus: boolean;
  setChangeRegionModalOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const HypercareAuthRegionModalContext = createContext<IAuthRegionModalContext>({
  changeRegionModalOpenStatus: false,
  setChangeRegionModalOpenStatus: () => {},
});

export default HypercareAuthRegionModalContext;
