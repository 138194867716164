export enum VirtualPagerStatusTypes {
  ALL = 'all',
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING = 'pending configuration',
  RELEASED = 'released',
}

export type PVPAUploadAudioResponse = {
  ServerSideEncryption: string;
  CopyObjectResult: {
    ETag: string;
    LastModified: string;
  };
  key: string;
  bucket: string;
  url: string;
  presignedUrl: string;
};

export type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD' | 'CONNECT' | 'TRACE';

export enum AlertTypes {
  STAT = 'stat',
  URGENT = 'urgent',
  REGULAR = 'regular',
}

export type SortOrderType = 'desc' | 'asc';
export type ChatType = 'single' | 'group' | '';
export type PagerPurchasedState = 'success' | 'fail';
export type SortFieldType = 'dateCreated' | 'lastUsed';
export type MessageAlertType = 'regular' | 'urgent' | 'stat';
export type AudioModalMenuOptions = 'upload' | 'textToSpeech';
export type AudioModalType = 'callback' | 'voicemail';
export type AudioType = 'callback' | 'voicemail';
export type SearchFieldType = 'all' | 'number' | 'friendlyName' | 'userIds';
export type VirtualPagersMenuOptionStatus = (typeof VirtualPagerStatusTypes)[keyof typeof VirtualPagerStatusTypes];

export interface VirtualPagers {
  _id: string;
  departmentId?: string | null;
  isGroupChat?: boolean;
  locality: string;
  organizationId?: number;
  alertPreference?: MessageAlertType | null;
  pagerNumber: string;
  region: string | null;
  roleIds?: string[];
  userIds?: string[];
  voicemail: string | null;
  callback: string | null;
  friendlyName?: string | null;
  dateCreated: string;
  dateUpdated?: string;
  lastUsed?: string;
  status: (typeof VirtualPagerStatusTypes)[keyof typeof VirtualPagerStatusTypes];
}

export interface TwilioNumbers {
  friendlyName: string;
  phoneNumber: string;
  lata: string;
  locality: string;
  postalCode: string;
  rateCenter: string;
  latitude: string;
  longitude: string;
  capabilities: {
    voice: boolean;
    SMS: boolean;
    MMS: boolean;
  };
}

export interface SearchResultUser {
  id: string;
  firstName?: string;
  firstname?: string;
  lastName?: string;
  lastname?: string;
}

export interface UserSearchResponseObject {
  user: OrganizationMemberFragment;
  matchedSequences: string[];
}

export type FetchAudioStepsByOrgIdResponse = { audioSteps: audioObject[]; defaultAudio: audioObject };
export interface TextToSpeechResponse {
  ServerSideEncryption: string;
  Location: string;
  Bucket: string;
  Key: string;
  signedURL: string;
}

export type OrganizationMemberFragment = BasicOrganizationMember | null;

interface BasicOrganizationMember {
  id: string;
  firstname: string;
  lastname: string;
  role?: string;
}

export interface OrganizationRoleFragment {
  roleId: number;
  roleAuditId: number;
  roleName: string;
  department: string;
}

export interface audioObject {
  id: string;
  audio: string;
  action: string;
  method: string;
  priority: string;
  organizationId: number;
  name: string;
  isDefault?: boolean;
}

export interface uploadAudioResponse {
  id: number;
  url: string;
  fileName: string;
  mimeType: string;
  userId: string;
  createdAt: string;
}
export interface generatedLinkResponse {
  ServerSideEncryption: string;
  Location: string;
  Bucket: string;
  Key: string;
  signedURL: string;
}

export interface DepartmentFragment {
  id: number;
  name: string;
  schedule: {
    roles: Array<{
      id: number;
      auditId: number;
      name: string;
    }>;
  };
}

export type PagerStats = { [key in VirtualPagerStatusTypes]?: number };

export type FetchOrgPurchasedNumbersResponse = {
  nextResponseCursor: string;
  fetchedNumbers: VirtualPagers[];
  pagerStatus: PagerStats;
};

export type DefaultAudioMap = {
  callback: string;
  voicemail: string;
};
