import React, { useState } from 'react';
import moment from 'moment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ChevronRight from 'src/assets/svgs/ChevronRight';
import UserAvatar from 'src/components/shared/UserAvatar';
import { Box, Chip } from '@material-ui/core';
import getAdminTypeBaseOnScopes from 'src/utils/getAdminTypeBaseOnScopes';
import { User, UserMenuOptionStatus } from 'src/types';
import { PENDING_ADMIN, USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import { getUserFullName } from 'src/utils/getUserFullName';
import { AppRoutes } from 'src/router/AppRoutes';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { StyledHypercareChip } from 'src/components/shared/StyledHypercareChip';
import UsersListActions from 'src/pages/HomePage/views/UsersListActions';
import {
  MANAGED_LABEL_TOOLTIP_TEXT,
  SHELL_ACCOUNT,
  UNMANAGED_LABEL_TOOLTIP_TEXT,
} from 'src/constants/dirSyncConstants';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { HCBodyTwo, HCHeadingFour } from 'src/components/shared/HypercareComponents';
import { StyledLink, StyledToolTipContainer } from 'src/components/shared/SharedStyles';
import {
  DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK,
  END_OF_USER_LIST_MESSAGE,
  NO_LICENSED_USERS,
} from 'src/constants/strings';
import theme from 'src/assets/styles/theme';
import { LEARN_MORE } from 'src/constants/virtualPagerStrings';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';
import { usePaginatedDataState } from 'src/pages/HomePage/hooks/usePaginatedDataState';
import { FetchPaginatedUsersQueryResponse, PaginatedLicensedMembers } from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import { PaginatedInvites, PaginatedUsersType } from 'src/types/PaginatedTypes';
import { StyledButton, StyledMenu, StyledStatus, useStyles } from 'src/pages/HomePage/InviteStyle';
import InfiniteScroll from 'react-infinite-scroller';
import { NoSearchResultsFound } from 'src/pages/HomePage/views/NoSearchResultsFound';
import { UserViewModel } from 'src/pages/HomePage/viewModels/UserViewModel';
import { ApolloQueryResult } from 'apollo-client';

interface Props {
  localSearchText: string;
  currentMenuOption: UserMenuOptionStatus;
  users?: PaginatedUsersType[] | PaginatedInvites[];
  fetchMorePaginatedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedUsersQueryResponse>>;
  paginatedUserData: FetchPaginatedUsersQueryResponse;
  searchLicensedUserData: PaginatedLicensedMembers;
  setSearchLicensedUserData: React.Dispatch<React.SetStateAction<PaginatedLicensedMembers>>;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

export const PaginatedUserList: React.FC<Props> = ({
  users = [],
  currentMenuOption,
  fetchMorePaginatedUserData,
  paginatedUserData,
  localSearchText,
  searchLicensedUserData,
  setSearchLicensedUserData,
  isDoneRows,
  setIsDoneRows,
}) => {
  const classes = useStyles();
  const [selectUser, setSelectedUser] = React.useState(null);
  const [actionStatus, setActiveStatus] = React.useState('');
  const [actionTypeEl, setActionTypeEl] = React.useState<null | HTMLElement>(null);
  const [activeAction, setActiveAction] = React.useState(null);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const viewModel = DirSyncViewModel();
  const { data: orgDirSyncStatus } = viewModel.fetchOrgDirSyncStatus();
  const { data: selfProfileData } = viewModel.fetchSelfProfile();
  const [showDirSyncToolTip, setShowDirSyncToolTip] = useState<HTMLElement | null | any>(null);

  const [continuationId, setContinuationId] = useState(
    paginatedUserData?.adminQuery.organizationalUnit.paginatedMembers.continuationId,
  );

  const { setSeenContinuationIds, seenContinuationIds, additionalRows, setAdditionalRows } = usePaginatedDataState();

  const newUserListData = [...users, ...(additionalRows || [])];

  const { getSearchedUsers } = UserViewModel();
  const getMorePaginatedSearchData = async () => {
    const continuationId = searchLicensedUserData.continuationId;

    if (seenContinuationIds.includes(continuationId) || !continuationId) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchedUsers({ text: localSearchText, limit: 30, continuationId });

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchLicensedUserData({
          ...searchLicensedUserData,
          continuationId: result.continuationId,
          users: [...searchLicensedUserData.users, ...result.users],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getMorePaginatedData = async () => {
    if (seenContinuationIds.includes(continuationId) || continuationId === null) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedUserData({
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          continuationId,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedUsersQueryResponse,
          { fetchMoreResult }: { fetchMoreResult: FetchPaginatedUsersQueryResponse },
        ) => {
          const newFetchedUsers = fetchMoreResult.adminQuery.organizationalUnit.paginatedMembers;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          setAdditionalRows([...additionalRows, ...(newFetchedUsers.users || [])]);
          setContinuationId(newFetchedUsers.continuationId);
        },
      });
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>, user: User) => {
    setShowDirSyncToolTip(e.currentTarget);
    setSelectedUser(user);
  };

  const handlePopoverClose = (e: React.MouseEvent) => {
    setShowDirSyncToolTip(null);
  };

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>, user: User, index: string, status: string) => {
    setActionTypeEl(event.currentTarget);
    setSelectedUser(user);
    setActiveAction(index);
    setActiveStatus(status);
  };

  const handleCloseMenu = () => {
    setActionTypeEl(null);
    setActiveStatus('');
  };

  if (newUserListData?.length === 0 || !newUserListData) {
    return (
      <div>
        {localSearchText.length >= 1 ? <NoSearchResultsFound /> : <p className="noUser">{NO_LICENSED_USERS}</p>}
      </div>
    );
  }

  return (
    <div style={{ maxHeight: `calc(100vh - 305px)`, overflow: 'auto' }}>
      <InfiniteScroll
        loadMore={localSearchText.length >= 1 ? getMorePaginatedSearchData : getMorePaginatedData}
        hasMore={!isDoneRows}
        initialLoad={false}
        useWindow={false}
      >
        {showDirSyncToolTip && (
          <Popover
            open={Boolean(showDirSyncToolTip)}
            anchorEl={showDirSyncToolTip}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className="popper__container notes__popover"
            disablePortal
          >
            <ClickAwayListener onClickAway={() => setShowDirSyncToolTip(null)}>
              <StyledToolTipContainer width={'225px'} onMouseLeave={handlePopoverClose}>
                <HCBodyTwo>
                  {selectUser?.isDirectorySynced ? MANAGED_LABEL_TOOLTIP_TEXT : UNMANAGED_LABEL_TOOLTIP_TEXT}{' '}
                  <StyledLink href={DIR_SYNC_USER_MANAGED_PILL_SUPPORT_LINK}>
                    <span style={{ color: theme.blueBorder }}>{LEARN_MORE}</span>
                  </StyledLink>
                </HCBodyTwo>
              </StyledToolTipContainer>
            </ClickAwayListener>
          </Popover>
        )}
        {newUserListData.map((user: User, index: number) => {
          const adminType = getAdminTypeBaseOnScopes(user.scopes);
          const ldapCondition = ldapDirectorySyncFlag;

          const uniqueIndex = `${index}-${user.id}`;

          return (
            <div className={`usersBlock`} key={`user-${index}`}>
              <div className="usersBlock__avatarIcon">
                <UserAvatar profileSize="default" user={user} />
              </div>

              <div
                className={`usersBlock__userNames ${classes.userNameSection}`}
                onClick={() => window.routerHistory.push(`${AppRoutes.UserProfile}/${user.id}`)}
              >
                <span className="usersBlock__userNames--fullName">{getUserFullName(user)}</span>
                <ChevronRight />
              </div>

              <div className="usersBlock__userTitle">{user.role ? `${user.role}` : '--'}</div>

              <div className="usersBlock__userTags">
                {ldapDirectorySyncFlag && user.scopes && adminType ? (
                  <StyledHypercareChip type={'admin'} text={`${adminType} admin`} valueClassName="adminTag" />
                ) : (
                  user.scopes && adminType && <Chip label={`${adminType} admin`} className="adminTag" />
                )}
                {user.memberStatus && user.memberStatus === 'inactive' && (
                  <StyledHypercareChip type={'shell'} text={SHELL_ACCOUNT} valueClassName="shellTag" />
                )}
                {ldapDirectorySyncFlag && user.isDirectorySynced && (
                  <span onMouseEnter={(e) => handlePopoverOpen(e, user)}>
                    <StyledHypercareChip type={'neutral'} text={'Managed'} valueClassName="chip-instance" />
                  </span>
                )}
                {ldapDirectorySyncFlag && orgDirSyncStatus && !user.isDirectorySynced && (
                  <span onMouseEnter={(e) => handlePopoverOpen(e, user)}>
                    <StyledHypercareChip type={'warning'} text={'Unmanaged'} valueClassName="chip-instance" />
                  </span>
                )}
              </div>

              <div className={`usersBlock__userStatus ${classes.licensedStatus}`}>
                <StyledStatus status={'licensed'}>{'Licensed'}</StyledStatus>
              </div>

              <div className={`usersBlock__joinDate ${classes.status}`}>
                <span className={currentMenuOption === USER_MENU_OPTION.All ? classes.joinDate : undefined}>
                  {user.joinDate ? moment(user.joinDate).format('DD/MMM/YYYY') : '--'}
                </span>
              </div>

              <div className={classes.actionButton}>
                {(ldapCondition ||
                  (!ldapDirectorySyncFlag &&
                    user.accountStatus === USER_MENU_OPTION.Pending &&
                    currentMenuOption === USER_MENU_OPTION.All)) && (
                  <>
                    <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                      <StyledButton
                        id={`action-button-${user.id}`}
                        aria-haspopup="true"
                        className={
                          user.inviteStatus === PENDING_ADMIN
                            ? classes.adminActionButtonBorder
                            : classes.inviteeActionButtonBorder
                        }
                        onClick={(e) => handleActionClick(e, user, uniqueIndex, user.inviteStatus)}
                        endIcon={actionTypeEl && uniqueIndex === activeAction ? <ExpandLess /> : <ExpandMore />}
                      >
                        Action
                      </StyledButton>
                    </Box>
                  </>
                )}
              </div>
            </div>
          );
        })}
        {isDoneRows && (
          <div style={{ height: '25px', margin: '5px' }}>
            <HCHeadingFour fontWeight={400} color={theme.mainFontColor}>
              {END_OF_USER_LIST_MESSAGE}
            </HCHeadingFour>
          </div>
        )}
      </InfiniteScroll>

      <StyledMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={`action-menu-${selectUser?.id}`}
        keepMounted
        anchorEl={actionTypeEl}
        open={Boolean(actionTypeEl)}
        onClose={() => handleCloseMenu()}
      >
        <UsersListActions
          adminType={getAdminTypeBaseOnScopes(selfProfileData?.scopes)}
          selectedUser={selectUser}
          handleCloseMenu={handleCloseMenu}
        />
      </StyledMenu>
    </div>
  );
};
