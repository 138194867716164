import * as React from 'react';
import BaseAccordion from './BaseAccordion';
import FriendlyNameAccordionDetails from './AccordionDetails/FriendlyNameAccordionDetails';
import ConfigureNumberViewModel from '../../ConfigureNumberViewModel';
import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { setCurrentPager } from 'src/redux/actions/virtualPagersActions';
import { VirtualPagerStatusTypes } from 'src/types';
import {
  ACCORDION_DISPLAY_NAME_DEFAULT_DESCRIPTION,
  ACCORDION_DISPLAY_NAME_HEADER,
} from 'src/constants/virtualPagerStrings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const FriendlyNameAccordion = () => {
  const dispatch = useDispatch();
  const { currentPager } = typedUseSelector((state) => state.virtualPagerReducer);
  const { configureFriendlyName } = ConfigureNumberViewModel();
  const [friendlyName, setFriendlyName] = React.useState<string>('');

  const trackClick = (expanded) => {
    const containerStatus =
      friendlyName === ''
        ? 'Pending Configuration'
        : friendlyName === currentPager.friendlyName
        ? 'Configured'
        : 'Unsaved Changes';
    if (!expanded) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaDisplayNameClosed,
        params: {
          virtual_pager_number: currentPager.pagerNumber,
          virtual_pager_display_name: friendlyName,
          container_status: containerStatus,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaConfigurationSetDisplayNamePressed,
        params: {
          container_status: containerStatus,
        },
      });
    }
  };

  React.useEffect(() => {
    if (currentPager) {
      setFriendlyName(currentPager.friendlyName ? currentPager.friendlyName : '');
    }
  }, []);

  return (
    <>
      {currentPager ? (
        <BaseAccordion
          title={ACCORDION_DISPLAY_NAME_HEADER}
          description={{
            Generic: ACCORDION_DISPLAY_NAME_DEFAULT_DESCRIPTION,
            PagerSpecific: currentPager.friendlyName,
          }}
          trackClick={trackClick}
          handleSave={async () => {
            dispatch(setCurrentPager(await configureFriendlyName(currentPager._id, friendlyName)));
            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.pvpaDisplayNameSaved,
              params: {
                virtual_pager_number: currentPager.pagerNumber,
                virtual_pager_display_name: friendlyName,
              },
            });
          }}
          isSaveButtonDisabled={friendlyName === '' || currentPager.friendlyName === friendlyName}
          isUnsaved={currentPager.friendlyName ? friendlyName !== currentPager.friendlyName : friendlyName !== ''}
          isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
          isFieldValid={friendlyName !== ''}
          AccordionDetail={
            <FriendlyNameAccordionDetails
              isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
              friendlyName={friendlyName}
              setFriendlyName={setFriendlyName}
            />
          }
        />
      ) : null}
    </>
  );
};

export default FriendlyNameAccordion;
