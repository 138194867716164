import gql from 'graphql-tag';
import {
  FullOrganizationMemberDirSyncFragment,
  FullOrganizationMemberFragment,
} from 'src/gql/v2/fragment/FullOrganizationMemberDirSyncFragment';

export const FETCH_MEMBERS = gql`
  query FetchMembers($organizationalUnit: OrganizationalUnitInput!) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
          ... on AdminSiteQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
          ... on AdminDepartmentQuery {
            members {
              ...FullOrganizationMemberFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberFragment}
`;

export const FETCH_DIR_SYNC_MEMBERS = gql`
  query FetchMembers($organizationalUnit: OrganizationalUnitInput!) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
          ... on AdminSiteQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
          ... on AdminDepartmentQuery {
            members {
              ...FullOrganizationMemberDirSyncFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationMemberDirSyncFragment}
`;

export const FETCH_MEMBERS_BY_IDS = gql`
  query fetchMembersByIds($organizationalUnit: OrganizationalUnitInput!, $ids: [ID!]!) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          __typename
          ... on OrganizationalUnitNotFoundError {
            message
          }
          ... on AdminOrganizationQuery {
            syncStatus
            membersForIds(ids: $ids) {
              __typename
              ... on BadRequestError {
                message
              }
              ...FetchMembersForIDsResponseFragment
            }
          }
        }
      }
    }
  }

  fragment FetchMembersForIDsResponseFragment on FetchMembersForIDsResponse {
    members {
      ... on UserNotFoundError {
        message
      }
      ... on FullOrganizationMember {
        id
        joinDate
        licenseStartTime
        licenseExpiryTime
        firstname: firstName
        lastname: lastName
        role
        isDirectorySynced
        isSSOEnabled
        memberStatus
        addresses {
          address
          type
          label
          access
          syncStatus
        }
        scopes {
          ...OrganizationScopeFragment
        }
      }
    }
  }

  fragment OrganizationScopeFragment on OrganizationScope {
    organizationId
    siteId
    departmentId
    status
  }
`;
