import React from 'react';
import { EXISTING, NEW } from 'src/constants/inviteUserTypes';
import { ORGANIZATION } from 'src/constants/organizationTypes';
interface Props {
  setFormType: (newType: 'existing' | 'new') => void;
  currentFormType: 'existing' | 'new';
  organizationType: string;
}

const InviteUserSelection = ({ setFormType, currentFormType, organizationType }: Props) => {
  return (
    <div className="modal__formSelectors">
      <div
        onClick={() => setFormType(EXISTING)}
        className={`modal__formSelector__wrapper--default ${
          currentFormType === EXISTING ? 'modal__formSelector__wrapper--selected' : ''
        }`}
      >
        {organizationType === ORGANIZATION ? <span>Invite by email</span> : <span>Add users</span>}
      </div>
      <div
        onClick={() => setFormType(NEW)}
        className={`modal__formSelector__wrapper--default ${
          currentFormType === NEW ? 'modal__formSelector__wrapper--selected' : ''
        }`}
      >
        <span>Create a new user</span>
      </div>
    </div>
  );
};

export default InviteUserSelection;
