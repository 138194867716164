import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import ProfileDangerZoneOptions from 'src/components/profile-danger-zone/ProfileDangerZoneOptions';
import ProfileDangerZoneInfo from 'src/components/profile-danger-zone/ProfileDangerZoneInfo';
import DirSyncModal from 'src/components/modals/DirSyncModal';
import {
  DISABLE_DIRECTORY_SERVICE_MANAGEMENT,
  DISABLE_DIRECTORY_MANAGEMENT_DESC,
  DISABLE_DIRECTORY_MANAGEMENT,
  LOGOUT_ALL_DEVICES,
  LOGOUT_ALL_DEVICES_DESC,
  RESET_PASSWORD,
  RESET_PASSWORD_DESC,
  REMOVE_MEMBER_ORG_DESC,
  ENABLE_DIRECTORY_SERVICE_MANAGEMENT,
  ENABLE_DIRECTORY_MANAGEMENT_DESC,
  ENABLE_DIRECTORY_MANAGEMENT,
  REMOVE_MEMBER_ORG_TITLE,
  REMOVE_MEMBER_FROM_ORGANIZATION,
} from 'src/constants/dirSyncConstants';
import DirSyncViewModel from 'src/pages/HomePage/viewModels/DirSyncViewModel';
import { ModalActionType } from 'src/types/Modal';
import { REMOTE_LOGOUT, REMOVE_USER } from 'src/constants/modalTypes';
import ConfirmationActionModal from 'src/components/modals/ConfirmationActionModal';
import ResetPasswordModal from 'src/components/modals/ResetPasswordModal';
import { User } from 'src/types';
import store, { typedUseSelector } from 'src/redux/store';
import getAdminTypeBaseOnScopes from 'src/utils/getAdminTypeBaseOnScopes';
import { ORG } from 'src/constants/strings';
import { isRemoveButtonDisabledForOrganizationLevel } from '../../../utils/organizationHelper/organizationHelper';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';

const DangerZoneSection = styled.section`
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
`;

const DangerZoneInnerContainer = styled.div`
  background: ${theme.white};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  min-height: 13rem;
  display: flex;
`;

const DangerZoneListGridContainer = styled.div`
  width: 100%;
  padding-top: 34px;
  margin-left: 4%;
`;

type Props = {
  user: User;
};

const ProfileDangerZone = ({ user }: Props) => {
  const [dirSyncModalOpen, setDirSyncModalOpen] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [modalActionType, setModalActionType] = React.useState(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const staFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const viewModel = DirSyncViewModel();

  const { data: userProfile, refetch: refetchProfileData } = viewModel.fetchMemberProfile(user.id);
  const { data: orgSyncStatus } = viewModel.fetchOrgDirSyncStatus();
  const { data: selfProfileData } = viewModel.fetchSelfProfile();

  const isDirectorySynced = typedUseSelector((state) => state.userDataReducer?.isDirectorySynced);

  const { type: orgType } = store.getState().organizationReducer;

  const handleCloseDirSyncModal = () => {
    refetchProfileData();
    setDirSyncModalOpen(false);
  };
  const handleResetPassword = () => {
    setShowResetPasswordModal(true);
  };

  const setModalStatus = (type: ModalActionType, bool: boolean) => {
    setModalActionType(type);
    setShowConfirmationModal(bool);
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
    setModalActionType('');
  };

  const handleDirSyncModalOpen = () => {
    setDirSyncModalOpen(true);
  };

  return (
    <>
      <DangerZoneSection>
        <DangerZoneInnerContainer>
          <ProfileDangerZoneInfo />
          <DangerZoneListGridContainer>
            {orgSyncStatus && (
              <ProfileDangerZoneOptions
                heading={
                  !isDirectorySynced ? ENABLE_DIRECTORY_SERVICE_MANAGEMENT : DISABLE_DIRECTORY_SERVICE_MANAGEMENT
                }
                subHeading={!isDirectorySynced ? ENABLE_DIRECTORY_MANAGEMENT_DESC : DISABLE_DIRECTORY_MANAGEMENT_DESC}
                buttonText={!isDirectorySynced ? ENABLE_DIRECTORY_MANAGEMENT : DISABLE_DIRECTORY_MANAGEMENT}
                onButtonClick={handleDirSyncModalOpen}
                disabled={getAdminTypeBaseOnScopes(selfProfileData?.scopes) !== ORG}
              />
            )}
            {!staFlag && (
              <ProfileDangerZoneOptions
                heading={LOGOUT_ALL_DEVICES}
                subHeading={LOGOUT_ALL_DEVICES_DESC}
                buttonText={LOGOUT_ALL_DEVICES}
                onButtonClick={() => setModalStatus(REMOTE_LOGOUT, true)}
                disabled={false}
              />
            )}
            <ProfileDangerZoneOptions
              heading={RESET_PASSWORD}
              subHeading={RESET_PASSWORD_DESC}
              buttonText={RESET_PASSWORD}
              onButtonClick={handleResetPassword}
              disabled={userProfile?.isSSOEnabled}
            />
            <ProfileDangerZoneOptions
              heading={REMOVE_MEMBER_ORG_TITLE(orgType)}
              subHeading={REMOVE_MEMBER_ORG_DESC(orgType)}
              buttonText={REMOVE_MEMBER_FROM_ORGANIZATION(orgType)}
              onButtonClick={() => setModalStatus(REMOVE_USER, true)}
              disabled={isRemoveButtonDisabledForOrganizationLevel(isDirectorySynced, orgType)}
            />
          </DangerZoneListGridContainer>
        </DangerZoneInnerContainer>
      </DangerZoneSection>
      {dirSyncModalOpen && (
        <DirSyncModal open={dirSyncModalOpen} handleCloseModal={() => handleCloseDirSyncModal()} user={user} />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          profile={user}
          showModal={showResetPasswordModal}
          handleCloseModal={() => setShowResetPasswordModal(false)}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationActionModal
          profile={user}
          showModal={showConfirmationModal}
          confirmationType={modalActionType}
          handleCloseModal={closeModal}
        />
      )}
    </>
  );
};

export default ProfileDangerZone;
