import store from 'src/redux/store';
import { AuthPayload } from 'src/types';
import { Mixpanel } from './Mixpanel';
import { getParsedAuthRegion } from 'src/utils/localStorageHelper';
import { SavedOrganization } from '../types/sta';

export const EVENTS = {
  networkRequest: 'network_request',
  switchRegionPressed: 'switch_region_pressed',
  loginSSOButtonPressed: 'sso_button_pressed',
  loginButtonPressed: 'login_button_pressed',
  loggedIn: 'logged_in',
  switchOrganization: 'switch_organization',
  accountUserTabs: 'account_user_tab_pressed',
  viewMemberProfile: 'view_member_profile',
  addUserButtonPressed: 'add_user_button_pressed',
  inviteByEmailOrPhoneTabPressed: 'invite_by_email_or_phone_tab_pressed',
  sendInvitesButtonPressed: 'send_invites_button_pressed',
  createNewUserTabPressed: 'create_a_new_user_tab_pressed',
  createNewUserButtonPressed: 'create_a_new_user_button_pressed',
  cancelAddUserModalButtonPressed: 'cancel_add_user_modal_button_pressed',
  refreshAnalyticReports: 'refresh_analytic_reports',
  fetchAnalyticReportByDate: 'fetch_analytic_report_by_date',
  highlightsTabPressed: 'highlights_tab_pressed',
  membersTabPressed: 'members_tab_pressed',
  reportsTabPressed: 'reports_tab_pressed',
  membersExportUsersCsv: 'members_export_users_csv',
  membersExportUsersXls: 'members_export_users_xls',
  exportReportPressed: 'export_report_pressed',
  exportTemplateMessageReportButtonPressed: 'export_tm_report_button_pressed',
  exportTemplateMessageReportQuitEditingButtonPressed: 'export_tm_report_quit_editing_button_pressed',
  viewDepartmentSchedulesRadioButton: 'view_department_schedules_radio_button',
  viewDownoadEquityReportRadioButton: 'view_downoad_equity_report_radio_button',
  backToEditButtonPressed: 'back_to_edit_button_pressed',
  quitAnywayButtonPressed: 'quit_anyway_button_pressed',
  downloadEquityReport: 'download_equity_report',
  downloadSchedule: 'download_schedule_option_pressed',
  downloadEquityReportButtonPressed: 'download_equity_report_button_pressed',
  inputScheduleButtonPressed: 'input_schedule_button_pressed',
  addNewSchedule: 'add_new_schedule',
  editScheduleOptionPressed: 'edit_schedule_option_pressed',
  viewScheduledUserProfile: 'view_scheduled_user_profile',
  removeSchedule: 'remove_schedule',
  createNewRoleButtonPressed: 'create_new_role_button_pressed',
  createRole: 'create_role',
  deleteRole: 'delete_role',
  publishAndNotifyButtonPressed: 'publish_and_notify_button_pressed',
  createNewEscalationButtonPressed: 'create_new_escalation_button_pressed',
  publishEscalationButtonPressed: 'publish_escalation_button_pressed',
  updateProfile: 'update_profile',
  resetPasswordButtonPressed: 'reset_password_button_pressed',
  resetPassword: 'reset_password',
  promoteToAdminButtonPressed: 'promote_to_admin_button_pressed',
  allDeviceLogoutButtonPressed: 'all_device_logout_button_pressed',
  allDeviceLogout: 'all_device_logout',
  deviceLogout: 'device_logout',
  removeMemberPressed: 'remove_member_pressed',
  removeMember: 'remove_member',
  approveInvite: 'approve_invite_button_pressed',
  declineInvite: 'decline_invite_button_pressed',
  resendInvite: 'resend_invite_button_pressed',
  sessionTimeoutIdleDialogShown: 'session_timeout_idle_dialog_shown',
  sessionTimeoutIdleDialogContinue: 'session_timeout_idle_dialog_continue',
  sessionTimeoutIdleDialogLogout: 'session_timeout_idle_dialog_logout',
  sessionTimeoutIdleLogout: 'session_timeout_idle_logout',
  sessionTimeoutForceLogout: 'session_timeout_force_logout',
  profileAddNoteButtonPressed: 'profile_add_note_button_pressed',
  profileNoteEditMenuOptionPressed: 'profile_note_edit_menu_option_pressed',
  profileNoteDeleteMenuOptionPressed: 'profile_note_delete_menu_option_pressed',
  profileNoteCreated: 'profile_note_created',
  profileNoteUpdated: 'profile_note_updated',
  profileNoteDeleted: 'profile_note_deleted',
  profileNoteEditVisibilityOptionPressed: 'profile_note_edit_visibility_option_pressed',
  profileNoteNewVisibilityOptionPressed: 'profile_note_new_visibility_option_pressed',
  contactsSpecificPagerButtonPressed: 'contacts_specific_pager_button_pressed',
  contactsSpecificPhoneButtonPressed: 'contacts_specific_phone_button_pressed',
  phoneButtonPressed: 'phone_button_pressed',
  editProfileOrderChanged: 'edit_profile_order_changed',
  profileEditButtonPressed: 'profile_edit_button_pressed',
  profileDiscardButtonPressed: 'profile_discard_button_pressed',
  editProfileDoneButtonPressed: 'edit_profile_done_button_pressed',
  editProfileAddAnotherMethodButtonPressed: 'edit_profile_add_another_method_button_pressed',
  editProfileRemoveButtonPressed: 'edit_profile_remove_button_pressed',
  newAddressAddded: 'new_address_added',
  loginContactSupport: 'login_contact_support_pressed',
  // PVPA
  pvpaMainPageViewed: 'pvpa_main_page_viewed',
  pvpaEmptyStateMainPageSeeHowThisWorksVideoPressed: 'pvpa_empty_state_main_page_see_how_this_works_video_pressed',
  pvpaEmptyStateMainPageVisitSupportCenterPressed: 'pvpa_empty_state_main_page_visit_support_center_pressed',
  pvpaGetNumbersPressed: 'pvpa_get_numbers_pressed',
  pvpaPurchaseVirtualPagerCountryFieldChanged: 'pvpa_purchase_virtual_pager_country_field_changed',
  pvpaGetNumbersSearchInitiated: 'pvpa_get_numbers_search_initiated',
  pvpaGetNumbersSearchResultsPopulated: 'pvpa_get_numbers_search_results_populated',
  pvpaGetNumbersSearchClearFiltersPressed: 'pvpa_get_numbers_search_clear_filters_pressed',
  pvpaGetNumbersSelectedVirtualPagerNextButtonPressed: 'pvpa_get_numbers_selected_virtual_pager_next_button_pressed',
  pvpaGetNumbersConfirmPurchasePurchaseButtonPressed: 'pvpa_get_numbers_confirm_purchase_purchase_button_pressed',
  pvpaGetNumbersConfirmPurchaseCancelButtonPressed: 'pvpa_get_numbers_confirm_purchase_cancel_button_pressed',
  pvpaGetNumbersProcessingShow: 'pvpa_get_numbers_processing_show',
  pvpaGetNumbersProcessingCompletedShow: 'pvpa_get_numbers_processing_completed_show',
  pvpaGetNumbersDonePressed: 'pvpa_get_numbers_done_pressed',

  pvpaListSearchTypeSelected: 'pvpa_list_search_type_selected',
  pvpaListSearchPerformed: 'pvpa_list_search_performed',
  pvpaListTabChosen: 'pvpa_list_tab_chosen',
  pvpaPagerConfigurationPageEntered: 'pvpa_pager_configuration_page_entered',

  // Configuration Page
  pvpaConfigurationSetDisplayNamePressed: 'pvpa_configuration_set_display_name_pressed',
  pvpaConfigurationSetAssignNumberPressed: 'pvpa_configuration_set_assign_number_pressed',
  pvpaConfigurationSetAlertNotificationPressed: 'pvpa_configuration_set_alert_notification_pressed',
  pvpaConfigurationSetAudioPressed: 'pvpa_configuration_set_audio_pressed',

  // Configuration: Set A Display Name
  pvpaDisplayNameSaved: 'pvpa_display_name_saved',
  pvpaDisplayNameClosed: 'pvpa_display_name_closed',

  // Configuration: Assign Number To
  pvpaVirtualPagerAssigneeSaved: 'pvpa_virtual_pager_assignee_saved',
  pvpaVirtualPagerAssigneeClosed: 'pvpa_virtual_pager_assignee_closed',

  // Configuration: Alerts And Notifications
  pvpaAlertAndNotificationSeeHowThisWorksPressed: 'pvpa_alert_and_notification_see_how_this_works_pressed',
  pvpaAlertAndNotificationSavePressed: 'pvpa_alert_and_notification_save_pressed',
  pvpaAlertAndNotificationSetupClosedPressed: 'pvpa_alert_and_notification_setup_closed_pressed',

  // Configuration: Audio Handling Add Leave Callback Message
  pvpaAudioHandlingConfigurationSeeHowThisWorksPressed: 'pvpa_audio_handling_configuration_see_how_this_works_pressed',
  pvpaAddNewCallbackPressed: 'pvpa_add_new_callback_pressed',
  pvpaAddNewCallbackUploadAudio: 'pvpa_add_new_callback_upload_audio',
  pvpaAddNewCallbackUploadedAudioDeletePressed: 'pvpa_add_new_callback_uploaded_audio_delete_pressed',
  pvpaAddNewCallbackUploadedAudioPlaybackPressed: 'pvpa_add_new_callback_uploaded_audio_playback_pressed',
  pvpaAddNewCallbackTextToSpeechGeneratePressed: 'pvpa_add_new_callback_text_to_speech_generate_pressed',
  pvpaAddNewCallbackTextToSpeechModifyPressed: 'pvpa_add_new_callback_text_to_speech_modify_pressed',
  pvpaAddNewCallbackTextToSpeechGeneratedAudioPlaybackPressed:
    'pvpa_add_new_callback_text_to_speech_generated_audio_playback_pressed',
  pvpaAddNewCallbackUseAudioPressed: 'pvpa_add_new_callback_use_audio_pressed',
  pvpaAddNewCallbackCancelPressed: 'pvpa_add_new_callback_cancel_pressed',

  // Configuration: Audio Handling Add Leave Voicemail Message
  pvpaAddNewVoicemailPressed: 'pvpa_add_new_voicemail_pressed',
  pvpaAddNewVoicemailUploadAudio: 'pvpa_add_new_voicemail_upload_audio',
  pvpaAddNewVoicemailUploadedAudioDeletePressed: 'pvpa_add_new_voicemail_uploaded_audio_delete_pressed',
  pvpaAddNewVoicemailUploadedAudioPlaybackPressed: 'pvpa_add_new_voicemail_uploaded_audio_playback_pressed',
  pvpaAddNewVoicemailTextToSpeechGeneratePressed: 'pvpa_add_new_voicemail_text_to_speech_generate_pressed',
  pvpaAddNewVoicemailTextToSpeechModifyPressed: 'pvpa_add_new_voicemail_text_to_speech_modify_pressed',
  pvpaAddNewVoicemailTextToSpeechGeneratedAudioPlaybackPressed:
    'pvpa_add_new_voicemail_text_to_speech_generated_audio_playback_pressed',
  pvpaAddNewVoicemailUseAudioPressed: 'pvpa_add_new_voicemail_use_audio_pressed',
  pvpaAddNewVoicemailCancelPressed: 'pvpa_add_new_voicemail_cancel_pressed',
  pvpaRemoveVoicemailPressed: 'pvpa_remove_voicemail_pressed',

  pvpaAudioSetupSavePressed: 'pvpa_audio_setup_save_pressed',
  pvpaAudioSetupClosePressed: 'pvpa_audio_setup_close_pressed',
  pvpaNumberActivatedPopupShow: 'pvpa_number_activated_popup_show',
  pvpaNumberStatusChange: 'pvpa_number_status_change',
  pvpaDisableNumberPressed: 'pvpa_disable_number_pressed',
  pvpaDisableNumberConfirmPressed: 'pvpa_disable_number_confirm_pressed',
  pvpaVirtualPagerContactSupportToReleasePressed: 'pvpa_virtual_pager_contact_support_to_release_pressed',
  pvpaReactivateNumberPressed: 'pvpa_reactivate_number_pressed',
  pvpaReactivateNumberConfirmPressed: 'pvpa_reactivate_number_confirm_pressed',
  pvpaDisableNumberLearnMorePressed: 'pvpa_disable_number_learnmore_pressed',
  pvpaReleaseNumberLearnMorePressed: 'pvpa_release_number_learnmore_pressed',
};

export const PAGE_VIEWS = {
  loginView: 'HypercareAdminLoginView',
  switchOrganizationView: 'HypercareAdminSwitchOrganizationView',
  accountManagementView: 'HypercareAdminAccountManagementView',
  userProfileView: 'HypercareAdminUserProfileView',
  removeMemberView: 'HypercareAdminRemoveMemberView',
  resetPasswordView: 'HypercareAdminResetPasswordView',
  logoutAccountView: 'HypercareAdminLogoutAccountView',
  inviteExistingMemberView: 'HypercareAdminInviteExistingMemberView',
  inviteNewMemberView: 'HypercareAdminInviteNewMemberView',
  schedulingPage: 'HypercareAdminSchedulingView',
};

// TODO: authPayload type
class AnalyticsManager {
  public static events = EVENTS;
  public static pageViews = PAGE_VIEWS;

  public static mixpanelLoginEvent(authInfo: AuthPayload | SavedOrganization) {
    try {
      const userClone = authInfo.user;
      const userOrganization = store.getState().organizationReducer;

      Mixpanel.identify(userClone.id);
      Mixpanel.people.set({
        $first_name: userClone.firstname,
        $last_name: userClone.lastname,
        username: userClone.username,
        id: userClone.id,
        $email: userClone.email,
        title: userClone.role,
        company: userOrganization.name,
        company_id: userOrganization.organization_id,
        region: getParsedAuthRegion(),
      });
    } catch (e) {
      console.error(e);
    }
  }

  public static identifyMixpanelUserOrg({ userId, company, company_id }) {
    Mixpanel.identify(userId);
    Mixpanel.people.set({
      company,
      company_id,
      region: getParsedAuthRegion(),
    });
  }

  public static recordMixpanelEvent({ eventName, params }: { eventName: string; params: any }) {
    const userOrganization = store.getState().organizationReducer;
    if (params) {
      params['organization_id'] = userOrganization?.organization_id;
      params['organization'] = userOrganization?.name;
      params['company_id'] = userOrganization?.organization_id;
    } else {
      params = {
        organization_id: userOrganization?.organization_id,
        organization: userOrganization?.name,
        company_id: userOrganization?.organization_id,
      };
    }
    Mixpanel.track(eventName, params);
  }

  public static applyAnalytics(...args: { eventName: string; params?: any }[]) {
    this.recordMixpanelEvent.apply(this, Array.prototype.slice.call(args, 0));
  }

  public static recordPageVisited(pageKey: string) {
    Mixpanel.page(pageKey);
  }

  public static resetMixpanel() {
    Mixpanel.reset();
  }
}

export default AnalyticsManager;
