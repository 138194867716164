import { useMutation, useQuery } from 'react-apollo';
import { NETWORK_ERROR } from 'src/constants/networkError';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import { OrganizationalUnitInputType } from 'src/gql/v2/types/input';
import {
  FETCH_PAGINATED_USERS_QUERY_STA,
  FetchPaginatedUsersQueryResponseSTA,
} from 'src/gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import {
  FETCH_PAGINATED_REMOVED_USERS_QUERY_STA,
  FetchPaginatedRemovedUsersQueryResponseSTA,
} from 'src/gql/v2/query/sta/FetchPaginatedRemovedUsersQuerySTA';
import {
  FETCH_PAGINATED_INVITES_QUERY_STA,
  FetchPaginatedInvitesQueryResponseSTA,
} from 'src/gql/v2/query/sta/FetchPaginatedInvitesQuerySTA';
import { SEARCH_USERS_QUERY, SearchUsersQueryResponse } from 'src/gql/v2/query/SearchUsersQuery';
import {
  SEARCH_REMOVED_USERS_QUERY_STA,
  SearchRemovedUsersQueryResponseSTA,
} from 'src/gql/v2/query/sta/SearchRemovedUsersQuerySTA';
import {
  SEARCH_PENDING_INVITES_QUERY_STA,
  SearchPendingInvitesQueryResponseSTA,
} from 'src/gql/v2/query/sta/SearchPendingInvitesQuerySTA';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import client from 'src/clients/apolloClient';
import { ApolloQueryResult } from 'apollo-client';
import { PresentedUserLicensingStatus } from 'src/constants/inviteUserTypes';
import { FetchOrganizationScopeList } from 'src/types';
import { GET_SELF_SCOPE } from 'src/gql/query/GetUserProfileQuery';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import { check } from 'prettier';
import CreateShellAccountMutation from 'src/gql/v2/mutation/CreateShellAccountMutation';
import { FETCH_DEVICES_FOR_MEMBER, FetchDevicesForMember } from '../../gql/v2/query/FetchDevicesForMember';
import { INVITE_USERS_V2 } from 'src/gql/v2/mutation/InviteUsersMutation';
import {
  FETCH_PAGINATED_USERS_QUERY,
  FetchPaginatedUsersQueryResponse,
} from '../../gql/v2/query/FetchPaginatedUsersQuery';
import {
  FETCH_PAGINATED_REMOVED_USERS_QUERY,
  FetchPaginatedRemovedUsersQueryResponse,
} from '../../gql/v2/query/FetchPaginatedRemovedUsersQuery';
import {
  FETCH_PAGINATED_INVITES_QUERY,
  FetchPaginatedInvitesQueryResponse,
} from '../../gql/v2/query/FetchPaginatedInvitesQuery';
import {
  SEARCH_REMOVED_USERS_QUERY,
  SearchRemovedUsersQueryResponse,
} from '../../gql/v2/query/SearchRemovedUsersQuery';
import {
  SEARCH_PENDING_INVITES_QUERY,
  SearchPendingInvitesQueryResponse,
} from '../../gql/v2/query/SearchPendingInvitesQuery';
import {
  REMOVE_SESSION_BY_ID_SELF_MUTATION,
  RemoveSessionByIdSelfMutationResult,
} from '../../gql/v2/mutation/RemoveSessionByIdSelfMutation';
import {
  REMOVE_DEVICE_FOR_USER_MUTATION,
  RemoveSessionForUserMutationResult,
} from '../../gql/v2/mutation/RemoveSessionForUserMutation';
import { LOGOUT_ALL_DEVICES, LogoutAllDeviceResult } from '../../gql/mutation/RemoteLogoutMutation';

export type FetchPaginatedRequestInput = {
  direction: string;
  continuationId: string;
  limit?: number;
  skip?: boolean;
};

export type FetchPaginatedRequestInputSTA = {
  pageInfo: {
    cursor: string;
    direction?: string;
    pageSize: number;
  };
  filters?: {
    searchQuery?: string;
    inviteStatus?: PresentedUserLicensingStatus;
  };
  skip?: boolean;
};

export interface FetchPaginatedInviteRequestInput extends FetchPaginatedRequestInput {
  inviteStatus: PresentedUserLicensingStatus;
}

export type SearchRequestInput = {
  text: string;
  limit: number;
  continuationId?: string;
  organizationType?: string;
};

export interface FetchSearchPaginatedInviteRequestInput extends SearchRequestInput {
  inviteStatus: PresentedUserLicensingStatus;
}

export type SearchRequestInputSTA = {
  text: string;
  pageInfo: {
    cursor: string;
    direction?: string;
    pageSize: number;
  };
  filters?: {
    searchQuery?: string;
    inviteStatus?: PresentedUserLicensingStatus;
  };
  organizationType?: string;
  skip?: boolean;
};

export const UserRepository = () => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const useFetchPaginatedUsers = ({ continuationId, direction }: FetchPaginatedRequestInput) => {
    const result = useQuery<
      FetchPaginatedUsersQueryResponse,
      FetchPaginatedRequestInput & OrganizationalUnitInputType & { isDirSyncFeatureFlagEnabled: boolean }
    >(FETCH_PAGINATED_USERS_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
        limit: 30,
      },
    });

    return {
      ...result,
    };
  };

  const useFetchPaginatedUsersSTA = ({ pageInfo }: FetchPaginatedRequestInputSTA) => {
    const result = useQuery<
      FetchPaginatedUsersQueryResponseSTA,
      FetchPaginatedRequestInputSTA & OrganizationalUnitInputType & { isDirSyncFeatureFlagEnabled: boolean }
    >(FETCH_PAGINATED_USERS_QUERY_STA, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        pageInfo: {
          pageSize: pageInfo.pageSize,
          cursor: pageInfo.cursor,
        },
        isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
      },
    });

    return {
      ...result,
    };
  };

  const useFetchPaginatedRemovedUsersSTA = ({ pageInfo, skip }: FetchPaginatedRequestInputSTA) => {
    const result = useQuery<
      FetchPaginatedRemovedUsersQueryResponseSTA,
      FetchPaginatedRequestInputSTA & OrganizationalUnitInputType
    >(FETCH_PAGINATED_REMOVED_USERS_QUERY_STA, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        pageInfo: {
          pageSize: pageInfo.pageSize,
          cursor: pageInfo.cursor,
        },
      },
      skip,
    });

    return {
      ...result,
    };
  };
  const useFetchPaginatedRemovedUsers = ({ continuationId, direction, skip }: FetchPaginatedRequestInput) => {
    const result = useQuery<
      FetchPaginatedRemovedUsersQueryResponse,
      FetchPaginatedRequestInput & OrganizationalUnitInputType
    >(FETCH_PAGINATED_REMOVED_USERS_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        limit: 30,
      },
      skip,
    });

    return {
      ...result,
    };
  };
  const useFetchPaginatedInvites = ({
    continuationId,
    direction,
    inviteStatus,
    skip,
  }: FetchPaginatedInviteRequestInput) => {
    const result = useQuery<
      FetchPaginatedInvitesQueryResponse,
      OrganizationalUnitInputType & FetchPaginatedInviteRequestInput
    >(FETCH_PAGINATED_INVITES_QUERY, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        direction,
        continuationId,
        inviteStatus,
        limit: 30,
      },
      skip,
    });

    return {
      ...result,
    };
  };
  const useFetchPaginatedInvitesSTA = ({ pageInfo, filters, skip }: FetchPaginatedRequestInputSTA) => {
    const result = useQuery<
      FetchPaginatedInvitesQueryResponseSTA,
      OrganizationalUnitInputType & FetchPaginatedRequestInputSTA
    >(FETCH_PAGINATED_INVITES_QUERY_STA, {
      variables: {
        organizationalUnit: checkOrganizationalUnit() as any,
        pageInfo: {
          pageSize: pageInfo.pageSize,
          cursor: pageInfo.cursor,
        },
        filters: {
          inviteStatus: filters.inviteStatus,
        },
      },
      skip,
    });

    return {
      ...result,
    };
  };

  const searchUsers = async ({ text, limit, continuationId, organizationType }: SearchRequestInput) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<SearchUsersQueryResponse> = await client.query({
        query: SEARCH_USERS_QUERY,
        variables: {
          organizationalUnit: organizationType !== ORGANIZATION ? checkOrganizationalUnit() : getOrganizationObject(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          continuationId,
          limit,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });

      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchRemovedUsers = async ({ text, limit, continuationId }: SearchRequestInput) => {
    try {
      const searchRemovedUsersDataQuery: ApolloQueryResult<SearchRemovedUsersQueryResponse> = await client.query({
        query: SEARCH_REMOVED_USERS_QUERY,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          limit,
          continuationId,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchRemovedUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchPendingInvites = async ({
    text,
    limit,
    continuationId,
    inviteStatus,
  }: FetchSearchPaginatedInviteRequestInput) => {
    try {
      const searchInvitesDataQuery: ApolloQueryResult<SearchPendingInvitesQueryResponse> = await client.query({
        query: SEARCH_PENDING_INVITES_QUERY,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          limit,
          continuationId,
          inviteStatus,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchInvitesDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchUsersSTA = async ({ pageInfo, organizationType, filters }: SearchRequestInputSTA) => {
    try {
      const searchUsersDataQuery: ApolloQueryResult<FetchPaginatedUsersQueryResponseSTA> = await client.query({
        query: FETCH_PAGINATED_USERS_QUERY_STA,
        variables: {
          organizationalUnit: organizationType !== ORGANIZATION ? checkOrganizationalUnit() : getOrganizationObject(),
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          pageInfo: {
            pageSize: pageInfo.pageSize,
            cursor: pageInfo.cursor,
          },
          filters: {
            searchQuery: filters.searchQuery,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });

      return searchUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchRemovedUsersSTA = async ({ text, pageInfo }: SearchRequestInputSTA) => {
    try {
      const searchRemovedUsersDataQuery: ApolloQueryResult<SearchRemovedUsersQueryResponseSTA> = await client.query({
        query: SEARCH_REMOVED_USERS_QUERY_STA,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          pageInfo: {
            pageSize: pageInfo.pageSize,
            cursor: pageInfo.cursor,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchRemovedUsersDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const searchPendingInvitesSTA = async ({ text, pageInfo, filters }: SearchRequestInputSTA) => {
    try {
      const searchInvitesDataQuery: ApolloQueryResult<SearchPendingInvitesQueryResponseSTA> = await client.query({
        query: SEARCH_PENDING_INVITES_QUERY_STA,
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          text,
          isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
          pageInfo: {
            pageSize: pageInfo.pageSize,
            cursor: pageInfo.cursor,
          },
          filters: {
            inviteStatus: filters.inviteStatus,
          },
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      return searchInvitesDataQuery;
    } catch (err) {
      console.error(err, 'Error occurred when searching for users');
    }
  };

  const fetchOrganizationScopeList = async () => {
    try {
      const organizationListResult: ApolloQueryResult<FetchOrganizationScopeList> = await client.query({
        query: GET_SELF_SCOPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
      return organizationListResult;
    } catch (err) {
      console.error(err, 'Error occurred when fetching organizations for users');
    }
  };

  const createShellAccounts = async (accounts) => {
    try {
      const response = await client.mutate({
        mutation: CreateShellAccountMutation,
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          accounts,
        },
      });
      return Promise.resolve({ error: null, response });
    } catch (err) {
      return Promise.resolve({ error: err.message, response: null });
    }
  };

  const inviteUsers = async (accounts) => {
    try {
      const response = await client.mutate({
        mutation: INVITE_USERS_V2,
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: checkOrganizationalUnit(),
          accounts,
        },
      });
      return Promise.resolve({ error: null, response });
    } catch (err) {
      return Promise.resolve({ error: err.message, response: null });
    }
  };

  const useFetchUserDevices = ({ userId }: { userId: string }) => {
    const result = useQuery<FetchDevicesForMember>(FETCH_DEVICES_FOR_MEMBER, {
      variables: {
        organizationalUnit: checkOrganizationalUnit(),
        memberId: userId,
      },
      fetchPolicy: 'no-cache',
    });

    return {
      ...result,
    };
  };

  const useLogoutSelfDevice = () => {
    const result = useMutation<
      RemoveSessionByIdSelfMutationResult,
      {
        sessionId: string;
      }
    >(REMOVE_SESSION_BY_ID_SELF_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      logoutSelfDevice: result[0],
      ...result[1],
      error,
    };
  };

  const useLogoutUserDevice = () => {
    const result = useMutation<
      RemoveSessionForUserMutationResult,
      OrganizationalUnitInputType & { sessionId: string } & { memberId: string }
    >(REMOVE_DEVICE_FOR_USER_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      logoutUserDevice: result[0],
      ...result[1],
      error,
    };
  };

  const useLogoutAllDevice = () => {
    const result = useMutation<LogoutAllDeviceResult>(LOGOUT_ALL_DEVICES, {
      fetchPolicy: 'no-cache',
    });

    let error = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      logoutAllDevice: result[0],
      ...result[1],
      error,
    };
  };

  return {
    useFetchPaginatedUsers,
    useFetchPaginatedUsersSTA,
    useFetchPaginatedRemovedUsersSTA,
    useFetchPaginatedRemovedUsers,
    useFetchPaginatedInvitesSTA,
    useFetchPaginatedInvites,
    searchUsersSTA,
    searchUsers,
    searchRemovedUsersSTA,
    searchRemovedUsers,
    searchPendingInvitesSTA,
    searchPendingInvites,
    fetchOrganizationScopeList,
    createShellAccounts,
    useFetchUserDevices,
    inviteUsers,
    useLogoutSelfDevice,
    useLogoutUserDevice,
    useLogoutAllDevice,
  };
};
