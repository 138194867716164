import React from 'react';
import styled from 'styled-components';
import { SorterType, UserMenuOptionStatus } from 'src/types';
import { sortByPaginatedInviteStatus } from 'src/utils/userSorting';
import { PresentedUserLicensingStatus, USER_MENU_OPTION } from 'src/constants/inviteUserTypes';
import { PENDING_ADMIN, PENDING_USER } from 'src/constants/inviteUserTypes';
import {
  FetchPaginatedUsersQueryResponseSTA,
  PaginatedLicensedMembersSTA,
} from 'src/gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import {
  PaginatedInvites,
  PaginatedPendingInvitesSTA,
  PaginatedPublicUser,
  PaginatedUsersType,
} from 'src/types/PaginatedTypes';
import {
  FetchPaginatedRemovedUsersQueryResponseSTA,
  PaginatedBlackListedMembersSTA,
} from 'src/gql/v2/query/sta/FetchPaginatedRemovedUsersQuerySTA';
import { NoSearchResultsFound } from 'src/pages/HomePage/views/NoSearchResultsFound';
import { NO_PENDING_INVITES } from 'src/constants/strings';
import { UsersListSearchingLoader } from 'src/components/loaders/UsersListSearchingLoader';
import { ApolloQueryResult } from 'apollo-client';
import { PendingUserListContainerSTA } from './PendingUserListContainerSTA';
import { RemovedUsersListSTA } from './RemovedUsersListSTA';
import { PaginatedUserListSTA } from './PaginatedUserListSTA';
import SortButton from '../SortButton';
import { FetchPaginatedInvitesQueryResponseSTA } from '../../../../gql/v2/query/sta/FetchPaginatedInvitesQuerySTA';

const StyledContainer = styled.div`
  padding: 12px 0px !important;
  margin-top: 28px !important;
`;

interface Props {
  searchLoading: boolean;
  localSearchText: string;
  licensedUsers: PaginatedUsersType[];
  blackListedUsers: PaginatedPublicUser[];
  adminPendingUsers: PaginatedInvites[];
  currentMenuOption: UserMenuOptionStatus;
  adminCoSignInvites: boolean;
  fetchMorePaginatedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedUsersQueryResponseSTA>>;
  fetchMorePaginatedRemovedUserData: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedRemovedUsersQueryResponseSTA>>;
  paginatedUserData: FetchPaginatedUsersQueryResponseSTA;
  paginatedRemovedUserDataQuery: FetchPaginatedRemovedUsersQueryResponseSTA;
  paginatedPendingAdminInvitesQuery: FetchPaginatedInvitesQueryResponseSTA;
  fetchMorePaginatedAdminInviteQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponseSTA>>;
  searchLicensedUserData: PaginatedLicensedMembersSTA;
  setSearchLicensedUserData: React.Dispatch<React.SetStateAction<PaginatedLicensedMembersSTA>>;
  searchBlackListedUserData: PaginatedBlackListedMembersSTA;
  setSearchBlackListedUserData: React.Dispatch<React.SetStateAction<PaginatedBlackListedMembersSTA>>;
  searchPendingAdminApprovalInviteData: PaginatedPendingInvitesSTA;
  setSearchPendingAdminApprovalInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvitesSTA>>;
  pendingRecords: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponseSTA>>;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

export const AllUsersSectionLayoutSTA = ({
  licensedUsers,
  localSearchText,
  blackListedUsers,
  adminPendingUsers,
  currentMenuOption,
  adminCoSignInvites,
  fetchMorePaginatedUserData,
  fetchMorePaginatedRemovedUserData,
  paginatedUserData,
  paginatedRemovedUserDataQuery,
  paginatedPendingAdminInvitesQuery,
  fetchMorePaginatedAdminInviteQuery,
  searchLoading,
  searchLicensedUserData,
  setSearchLicensedUserData,
  searchBlackListedUserData,
  setSearchBlackListedUserData,
  searchPendingAdminApprovalInviteData,
  setSearchPendingAdminApprovalInviteData,
  pendingRecords,
  isDoneRows,
  setIsDoneRows,
}: Props) => {
  const [pendingByAdmin, setPendingByAdmin] = React.useState<PaginatedInvites[]>([]);
  const [sorterType, setSorterType] = React.useState<SorterType>('lastName');

  //Pending user is an array of invites
  const pendingUsers = adminPendingUsers;

  React.useEffect(() => {
    const formatUserData = async (status: string, users: PaginatedInvites[]) => {
      const pendingUser = users.filter((user) => user.status === status).sort(sortByPaginatedInviteStatus);
      status === PENDING_ADMIN && setPendingByAdmin(pendingUser);
    };

    formatUserData(PENDING_ADMIN, pendingUsers);
  }, [pendingUsers]);

  if (searchLoading)
    return (
      <StyledContainer className="usersList">
        <div className="usersList__wrapper">
          <div className="usersList__wrapper__content">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <UsersListSearchingLoader />
            </div>
          </div>
        </div>
      </StyledContainer>
    );

  return adminCoSignInvites && currentMenuOption === USER_MENU_OPTION.Pending ? (
    pendingByAdmin.length === 0 ? (
      <StyledContainer className="usersList">
        <div className="usersList__wrapper">
          <div className="usersList__wrapper__content">
            {localSearchText.length >= 1 ? <NoSearchResultsFound /> : <p className="noUser">{NO_PENDING_INVITES}</p>}
          </div>
        </div>
      </StyledContainer>
    ) : (
      <>
        <PendingUserListContainerSTA
          users={pendingByAdmin}
          pendingByAdmin={pendingByAdmin}
          pendingRecords={pendingRecords}
          setPendingByAdmin={setPendingByAdmin}
          paginatedPendingAdminInvitesQuery={paginatedPendingAdminInvitesQuery}
          fetchMorePaginatedAdminInviteQuery={fetchMorePaginatedAdminInviteQuery}
          localSearchText={localSearchText}
          searchPendingAdminApprovalInviteData={searchPendingAdminApprovalInviteData}
          setSearchPendingAdminApprovalInviteData={setSearchPendingAdminApprovalInviteData}
          isDoneRows={isDoneRows}
          setIsDoneRows={setIsDoneRows}
        />
      </>
    )
  ) : (
    <div className="usersListV2">
      <SortButton sorterType={sorterType} setSorterType={setSorterType} currentMenuOption={currentMenuOption} />
      <div className="usersListV2__wrapper">
        <div className="usersListV2__wrapper__content">
          {currentMenuOption === 'unlicensed' ? (
            <RemovedUsersListSTA
              currentMenuOption={currentMenuOption}
              blacklistUsers={blackListedUsers}
              fetchMorePaginatedRemovedUserData={fetchMorePaginatedRemovedUserData}
              paginatedRemovedUserDataQuery={paginatedRemovedUserDataQuery}
              localSearchText={localSearchText}
              searchBlackListedUserData={searchBlackListedUserData}
              setSearchBlackListedUserData={setSearchBlackListedUserData}
              isDoneRows={isDoneRows}
              setIsDoneRows={setIsDoneRows}
            />
          ) : (
            <PaginatedUserListSTA
              localSearchText={localSearchText}
              paginatedUserData={paginatedUserData}
              currentMenuOption={currentMenuOption}
              users={licensedUsers}
              fetchMorePaginatedUserData={fetchMorePaginatedUserData}
              searchLicensedUserData={searchLicensedUserData}
              setSearchLicensedUserData={setSearchLicensedUserData}
              isDoneRows={isDoneRows}
              setIsDoneRows={setIsDoneRows}
            />
          )}
        </div>
      </div>
    </div>
  );
};
