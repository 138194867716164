import React, { useState } from 'react';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { StaLogin } from '../../../types';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../../types/sta';
import { localStorageService } from '../../../services/localStorageService';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';
import { getClientWebAppUrl, getRedirectUriForAdmin, isAccessTokenValid } from '../../../utils/sta/util';
import { typedUseSelector } from '../../../redux/store';
import { RowItemContainer, RowItemsRightSide } from '../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../microfrontend/login/SavedAccountOrganizationRowItems';
import { CheckMarkWithoutBackground } from '../../../microfrontend/svgs/CheckMarkWithoutBackground';
import { AddButtonV2 } from '../../../microfrontend/svgs/AddButtonV2';
import { HCLabelOne } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import { LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT, LOGIN_PAGE_SWITCH_ACCOUNT } from '../../../constants/strings';
import { GearIcon } from '../../../microfrontend/svgs/GearIcon';
import { AuthContext } from '../../../auth';
import { OrganizationList } from '../../../components/popup-dropdowns/OrganizationDropdown/OrganizationList';
import { LAST_ACTIVE_TIME } from '../../../constants/sessionTimeoutTypes';
import { useDispatch } from 'react-redux';
import { organizationDropDownAction } from '../../../redux/actions/flagAction';
import trySwitchOrganization from '../../../utils/trySwitchOrganization';
import { SITE } from '../../../constants/organizationTypes';

interface IDropdownProps {
  auth0props: Auth0ContextInterface;
  STALogin: StaLogin;
  logout: () => void;
  currentActiveAccount: SavedOrganization;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dropdown = ({ currentActiveAccount }: IDropdownProps) => {
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const currentOrg = typedUseSelector((store) => store.organizationReducer);
  const dispatch = useDispatch();

  const handleLoginToAccount = async (savedOrg: SavedOrganization) => {
    trySwitchOrganization({
      name: savedOrg.organization.name,
      type: 'organization',
      site_id: null,
      image: savedOrg.organization.imageURL,
      organization_id: savedOrg.organization.id,
      department_id: null,
      organization_name: savedOrg.organization.name,
    });
    dispatch(organizationDropDownAction(false));
    localStorage.removeItem(LAST_ACTIVE_TIME);
  };

  const handleClickAddOrCreateAnotherAccount = () => {
    const encodedUriString = encodeURIComponent(`redirect_uri=${getRedirectUriForAdmin()}`);

    window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
  };
  const handleClickManageAccounts = () => {
    const encodedUriString = encodeURIComponent(`redirect_uri=${getRedirectUriForAdmin()}&flowEntry=ManageAccount`);

    window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
  };

  return (
    <div>
      <RowItemContainer>
        {currentCacheData?.savedOrganizations.map((savedOrg) => (
          <SavedAccountOrganizationRowItems
            key={savedOrg.user.id}
            user={savedOrg.user}
            organization={savedOrg.organization}
            isSignedIn={isAccessTokenValid(savedOrg)}
            handleRowClick={() => handleLoginToAccount(savedOrg)}
            rightSideContent={
              <RowItemsRightSide>
                {currentOrg.type === 'organization' && currentOrg.organization_id === savedOrg.organization.id && (
                  <CheckMarkWithoutBackground />
                )}
              </RowItemsRightSide>
            }
            showOrganizationContent={<OrganizationList currentOrganization={currentOrg} />}
          />
        ))}
        <SavedAccountActionButtons
          onIconClick={() => handleClickAddOrCreateAnotherAccount()}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
              {LOGIN_PAGE_SWITCH_ACCOUNT}
            </HCLabelOne>
          }
        />
        <SavedAccountActionButtons
          icon={<GearIcon />}
          onIconClick={() => handleClickManageAccounts()}
          description={
            <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
              {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
            </HCLabelOne>
          }
        />
      </RowItemContainer>
    </div>
  );
};

export const SwitchAccountsDropdownItems = ({
  currentActiveAccount,
  setOpen,
}: {
  currentActiveAccount: SavedOrganization;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <AuthContext.Consumer>
      {({ auth0props, staLogin, logout }) => (
        <Dropdown
          currentActiveAccount={currentActiveAccount}
          logout={logout}
          STALogin={staLogin}
          auth0props={auth0props}
          setOpen={setOpen}
        />
      )}
    </AuthContext.Consumer>
  );
};
