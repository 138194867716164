import gql from 'graphql-tag';
import { PageInfo, PaginatedPublicUser } from 'src/types/PaginatedTypes';

export type SearchRemovedUsersQueryResponseSTA = {
  adminQuery: {
    organizationalUnit: {
      searchQuery: {
        searchRemovedUsers: {
          pageInfo: PageInfo;
          users: PaginatedPublicUser[];
        };
      };
    };
  };
};

export const SEARCH_REMOVED_USERS_QUERY_STA = gql`
  query SearchRemovedUsers($text: String!, $organizationalUnit: OrganizationalUnitInput!, $pageInfo: CursorPageInput) {
    adminQuery {
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            searchQuery(text: $text) {
              searchRemovedUsers(pageInfo: $pageInfo) {
                ... on RemovedUsersSearchResponse {
                  pageInfo {
                    endCursor
                    hasNextPage
                    nextCursor
                    pageSize
                    resultsCount
                    startCursor
                    totalResults
                  }
                  users {
                    avatar {
                      url
                    }
                    accountStatus
                    firstname: firstName
                    lastname: lastName
                    id
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
