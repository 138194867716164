import gql from 'graphql-tag';

export const UPDATE_ADDRESS_PREFERENCE_STA = gql`
  mutation UpdateAddressPreferenceSTA($organizationalUnit: OrganizationalUnitInput!, $id: ID!, $address: [String!]!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $id) {
              ... on UserNotFoundError {
                __typename
                message
              }
              ... on FullOrganizationMemberMutation {
                updateAddressPreference(address: $address) {
                  __typename
                  ...SetAddressOrderResultFields
                }
              }
            }
          }
        }
      }
    }
  }

  fragment SetAddressOrderResultFields on UpdateAddressPreferenceResult {
    __typename
    ... on FullOrganizationMember {
      addresses {
        ...AddressFields
      }
    }
    ... on InvalidAddressUpdateRequest {
      message
    }
    ... on DuplicateAddresses {
      message
    }
    ... on UnverifiedAddress {
      message
    }
    ... on InvalidAddressCount {
      message
    }
  }

  fragment AddressFields on Address {
    __typename
    id
    address
    type
    access
  }
`;
