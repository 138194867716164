import React from 'react';
import { Dialog, DialogContent, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  MaterialDialogHeaderTitle,
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from '../../components/shared/HypercareComponents';
import CloseMark from '../../assets/svgs/CloseMark';

interface MaterialDialogHeaderProps {
  loading?: boolean;
  dialogTitle: string;
  closeIconButtonClickHandler: () => void;
  showCloseIconButton?: boolean;
  titleFontSize?: string;
  titleColor?: string;
}

interface AlertModalButton {
  buttonLabel: string;
  type: string;
  onClickHandler: (args?) => void;
  disabled?: boolean;
  id?: string;
  color?: string;
}

interface AlertModalProps {
  title: string;
  subtitle?: string;
  closeAlertModal?: (arg: boolean) => void;
  isAlertModalVisible: boolean;
  showCloseButton?: boolean;
  alertModalButtons: Array<AlertModalButton>;
  width?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  titleFontSize?: string;
  modalContent?: any;
  id?: string;
  titleColor?: string;
  disableBackDropClick?: boolean;
}

const useStyles = makeStyles((_) => ({
  alertTitle: {
    marginBottom: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  paperWidthMd: {
    maxWidth: '50vw',
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '16px',
  },
  muiDialogTitle: {
    padding: '16px 24px 0 24px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '21px',
    lineHeight: '29px',
  },
  paper: {
    padding: '16px',
    textAlign: 'center',
    color: 'red',
  },
}));

enum AlertModalButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  LeftOutlined = 'leftOutlined',
}

const DialogActionsView = ({ alertModalButtons }) => {
  const modalButtonsLength = alertModalButtons.length;
  return (
    <React.Fragment>
      {alertModalButtons.map((button, index) => {
        const { type, buttonLabel, onClickHandler, disabled, id, color } = button;
        if (type === AlertModalButtonTypes.Secondary)
          return (
            <SecondaryButton
              key={index}
              style={{ marginRight: modalButtonsLength > 2 ? 8 : 0 }}
              onClick={onClickHandler}
              disabled={disabled}
              data-testid={id}
              color={color}
            >
              {buttonLabel}
            </SecondaryButton>
          );
        else if (type === AlertModalButtonTypes.Primary)
          return (
            <PrimaryButton key={index} onClick={onClickHandler} disabled={disabled} data-testid={id}>
              {buttonLabel}
            </PrimaryButton>
          );
        else return null;
      })}
    </React.Fragment>
  );
};

const CustomDialogActionsView = ({ alertModalButtons }) => {
  let alertModalLeftButton = alertModalButtons.find((button) => button.type === AlertModalButtonTypes.LeftOutlined),
    { onClickHandler, buttonLabel, type, disabled = false } = alertModalLeftButton;
  return (
    <Grid container justify={'space-between'}>
      <Grid item>
        <SecondaryButton key={type} onClick={onClickHandler} disabled={disabled}>
          {buttonLabel}
        </SecondaryButton>
      </Grid>
      <Grid item>
        <DialogActionsView alertModalButtons={alertModalButtons} />
      </Grid>
    </Grid>
  );
};

export const MaterialDialogHeader = ({
  loading = false,
  dialogTitle,
  closeIconButtonClickHandler,
  showCloseIconButton = true,
  titleFontSize,
  titleColor,
}: MaterialDialogHeaderProps) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography classes={{ root: classes.muiDialogTitle }}>
      <MaterialDialogHeaderTitle color={titleColor} fontSize={titleFontSize}>
        {dialogTitle}
      </MaterialDialogHeaderTitle>
      {showCloseIconButton && (
        <IconButton
          disabled={loading}
          aria-label="close"
          className={classes.closeButton}
          onClick={closeIconButtonClickHandler}
        >
          <CloseMark />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export const CustomModal = ({
  title,
  subtitle,
  closeAlertModal,
  isAlertModalVisible,
  alertModalButtons,
  width,
  titleFontSize,
  showCloseButton = true,
  modalContent,
  id,
  titleColor,
  disableBackDropClick,
}: AlertModalProps) => {
  const classes = useStyles({});
  return (
    <Dialog
      fullWidth={true}
      open={isAlertModalVisible}
      classes={{
        paperWidthMd: classes.paperWidthMd,
      }}
      onClose={
        closeAlertModal
          ? (event, reason) => {
              if (reason === 'backdropClick' && disableBackDropClick) {
                return;
              }
              closeAlertModal(false);
            }
          : undefined
      }
      maxWidth={width ? width : alertModalButtons.length > 2 ? 'md' : 'xs'}
      data-testid={id}
    >
      <MaterialDialogHeader
        titleFontSize={titleFontSize}
        titleColor={titleColor}
        showCloseIconButton={showCloseButton}
        dialogTitle={title}
        closeIconButtonClickHandler={() => closeAlertModal(false)}
      />
      <DialogContent>
        {modalContent ? modalContent : <Typography classes={{ root: classes.alertTitle }}>{subtitle}</Typography>}
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        {alertModalButtons.length > 2 ? (
          <CustomDialogActionsView alertModalButtons={alertModalButtons} />
        ) : (
          <DialogActionsView alertModalButtons={alertModalButtons} />
        )}
      </StyledDialogActions>
    </Dialog>
  );
};
