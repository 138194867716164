import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import ApiHelper from 'src/api';
import client from 'src/clients/apolloClient';
import saveAuthInfo, {
  getParsedAuthInfo,
  getParsedAuthRegion,
  updateLocalStorageAuthInfo,
} from 'src/utils/localStorageHelper';
import { authorizeUserAction, resetAction } from 'src/redux/actions/authorizeAction';
import { connect } from 'react-redux';
import { GET_SELF_PROFILE } from 'src/gql/query/GetUserProfileQuery';
import { setCurrentOrganization, SetCurrentOrganizationAction } from 'src/redux/actions/headerAction';
import { AuthHelper } from 'src/auth';
import { toast } from 'react-toastify';
import store, { RootState } from 'src/redux/store';
import { USER_NOT_ADMIN } from 'src/constants/formErrors';
import {
  AuthPayload,
  AuthProviderState,
  AuthRegion,
  AuthSSOVendors,
  DefaultUser,
  LoginResponse,
  User,
  UserOrganizationSwitcherPayload,
} from 'src/types';
import { AnyAction } from 'redux';
import throttle from 'lodash.throttle';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';
import { AUTH_INFO, HYPERCARE_REGION, IS_EXPIRED, ORGANIZATION_ACCOUNTS_DATA } from 'src/constants/storageKeys';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { AppRoutes } from 'src/router/AppRoutes';
import { SESSION_EXPIRED_ERROR_MESSAGE, USER_NOT_ADMIN_ERROR_MESSAGE } from 'src/constants/strings';
import { OrganizationAccountsCacheData, SavedOrganization } from '../types/sta';
import { localStorageService } from '../services/localStorageService';
import AdminAccessModalContext from '../contexts/AdminAccessModalContext';
import { ACCOUNT_QUERY_PARAM } from '../constants/queryParams';

const defaultUser: DefaultUser = {
  email: '',
  firstname: '',
  id: '',
  lastname: '',
  role: '',
  username: '',
  status: null,
  joinDate: '',
  profilePic: {
    url: '',
  },
};

interface ActionProps {
  tokenLastRefreshAt: number | null;
  resetRedux: () => void;
  authorizeUser: () => void;
  setCurrentOrganization: (currentOrganization: UserOrganizationSwitcherPayload) => SetCurrentOrganizationAction;
  auth0props: Auth0ContextInterface;
  adminAccessModalContextOpenStatus: boolean;
  setAdminAccessModalContextOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = React.createContext<AuthProviderState>({
  isLoggedIn: false,
  login: (username: string, password: string): Promise<LoginResponse> | null => {
    return null;
  },
  loginViaSSO: (token: string, provider: string): Promise<LoginResponse> | null => {
    return null;
  },
  logout: () => {},
  setAuthRegion: (region: AuthRegion) => {},
  authInfo: { user: defaultUser },
  updateAuthUserInfo: (user: User) => {
    return null;
  },
  auth0props: null,
  authRegion: 'CA',
  staLogin: (payload: SavedOrganization) => {
    return Promise.resolve(null);
  },
});

class AuthProvider extends React.Component<ActionProps> {
  public state: AuthProviderState;

  constructor(props: ActionProps) {
    super(props);
    const { auth0props, setAdminAccessModalContextOpenStatus } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const account = urlParams.get(ACCOUNT_QUERY_PARAM) || '';

    const authInfo = localStorage.getItem(AUTH_INFO);
    const parsedAuthInfo = authInfo ? JSON.parse(authInfo) : null;
    const authRegion = getParsedAuthRegion();

    if (parsedAuthInfo && !account) this.props.authorizeUser();

    this.state = {
      isLoggedIn: parsedAuthInfo && !account ? true : false,
      login: this.login,
      loginViaSSO: this.loginViaSSO,
      logout: this.logout,
      setAuthRegion: this.setAuthRegion,
      authRegion: authRegion,
      authInfo: parsedAuthInfo || { user: defaultUser },
      auth0props: auth0props,
      updateAuthUserInfo: this.updateAuthUserInfo,
      staLogin: this.staLogin,
    };
  }

  public componentDidUpdate(prevProps: ActionProps) {
    const { auth0props, tokenLastRefreshAt } = this.props;
    if (JSON.stringify(auth0props) !== JSON.stringify(prevProps.auth0props)) {
      this.setState({
        auth0props,
      });
    }
    if (tokenLastRefreshAt !== prevProps.tokenLastRefreshAt) {
      this.setState({
        authInfo: getParsedAuthInfo(),
      });
    }
  }

  public componentDidMount() {
    this.detectSessionExpiration();
    window.addEventListener('storage', throttle(this.checkLocalStorageChanged, 2000));
  }

  public componentWillUnmount() {
    window.removeEventListener('storage', throttle(this.checkLocalStorageChanged, 2000));
  }

  // TODO: error flow handling of third party api
  public login = async (username: string, password: string): Promise<LoginResponse> => {
    const result = await ApiHelper.fetchAuthToken({ username, password });
    try {
      await this.handleLogin(result);
    } catch (err) {
      // currently block user from login if its not admin
      return {
        success: false,
        error: err.message,
      };
    }
    return result;
  };

  public staLogin = async (payload: SavedOrganization) => {
    try {
      await this.onLoginSuccess(payload, true);
      this.saveAccountToCache(payload);

      this.props.authorizeUser();

      this.setState(
        {
          isLoggedIn: true,
          authInfo: payload,
        },
        () => toast.dismiss(),
      );

      return { success: true };
    } catch (e) {
      console.error(e);
      if (e.message === USER_NOT_ADMIN) {
        this.props.setAdminAccessModalContextOpenStatus(true);
      }
    }
  };
  private saveAccountToCache = (payload: SavedOrganization) => {
    let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(
      ORGANIZATION_ACCOUNTS_DATA,
    ) ?? {
      savedOrganizations: [],
      selectedAccountUserId: '',
    };

    savedCacheData.savedOrganizations = [payload];
    savedCacheData.selectedAccountUserId = payload.user.id;

    window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(savedCacheData));
  };

  public loginViaSSO = async (token: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.fetchSSOAccessToken(token, provider);
    await this.handleLogin(result);
    return result;
  };

  public updateAuthUserInfo = (user: User) => {
    this.setState({
      authInfo: { user },
    });
    updateLocalStorageAuthInfo(user);
  };

  public setAuthRegion = (region: AuthRegion) => {
    localStorage.setItem(HYPERCARE_REGION, region);
    this.setState({
      authRegion: region,
    });
  };

  public logout = () => {
    const { auth0props } = this.props;
    // app
    AuthHelper.logout();
    // provider state
    this.setState(
      {
        isLoggedIn: false,
        authInfo: { user: defaultUser },
      },
      () => {
        // redux
        this.props.resetRedux();
        if (auth0props.isAuthenticated) {
          // auth0
          auth0props.logout({
            federated: true,
            returnTo: `${
              window.location.protocol +
              '//' +
              window.location.hostname +
              (window.location.port ? ':' + window.location.port : '')
            }/login`,
          });
        }
      },
    );
  };

  public render() {
    return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
  }

  private handleLogin = async (
    result:
      | {
          success: boolean;
          data: any;
          error?: undefined;
        }
      | {
          success: boolean;
          error: any;
          data?: undefined;
        },
  ) => {
    if (result.success) {
      const authPayload: AuthPayload = { ...(result.data as AuthPayload) };
      try {
        await this.onLoginSuccess(authPayload);
        this.props.authorizeUser();

        this.setState(
          {
            isLoggedIn: true,
            authInfo: result.data,
          },
          () => toast.dismiss(),
        );
      } catch (e) {
        console.error(e);
        if (e.message === USER_NOT_ADMIN) {
          toast.error(USER_NOT_ADMIN_ERROR_MESSAGE, {
            className: 'Toast-Container',
            autoClose: 5000,
          });
          window.routerHistory.replace('/login');
          throw new Error(USER_NOT_ADMIN);
        }
      }
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  };

  private onLoginSuccess2 = async (authInfo: SavedOrganization) => {
    if (!authInfo) {
      return;
    }
    // save to local storage
    await saveAuthInfo(authInfo);

    const { user } = authInfo;

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loggedIn,
      params: {
        user_id: user.id,
        email: user.email,
        first_name: user.firstname,
        last_name: user.lastname,
      },
    });
    // set up third party apis
    await AuthHelper.setUpThirdParty(authInfo);
    // TODO: timeout logout at expiration time
    // bug: https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
    // AuthHelper.setTimeoutLogout(authInfo, this.logout);
  };

  private onLoginSuccess = async (authInfo: AuthPayload | SavedOrganization, isSta?: boolean) => {
    // save to local storage
    await saveAuthInfo(authInfo);
    // fetch self organization data
    const { data } = await client.query({
      query: GET_SELF_PROFILE,
      fetchPolicy: 'network-only',
    });

    let parsedAuthInfo: AuthPayload;
    if (data) {
      const { organizations, email, id, firstname, lastname } = data.me;
      // adding the missing email
      parsedAuthInfo = {
        ...authInfo,
        user: {
          ...authInfo.user,
          email,
        },
      };
      const { setCurrentOrganization } = this.props;
      // save organization scope
      await AuthHelper.saveScopeInfo(organizations, setCurrentOrganization, isSta, authInfo);

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.loggedIn,
        params: {
          user_id: id,
          email: email,
          first_name: firstname,
          last_name: lastname,
        },
      });
    }
    // set up third party apis
    await AuthHelper.setUpThirdParty(parsedAuthInfo ? parsedAuthInfo : authInfo);
    // TODO: timeout logout at expiration time
    // bug: https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
    // AuthHelper.setTimeoutLogout(authInfo, this.logout);
  };

  private detectSessionExpiration = () => {
    const isResultingFromExpiredSession = sessionStorage.getItem(IS_EXPIRED);
    if (isResultingFromExpiredSession) {
      toast.warn(SESSION_EXPIRED_ERROR_MESSAGE, {
        className: 'Toast-Container',
        autoClose: 5000,
      });
    }
    sessionStorage.removeItem(IS_EXPIRED);
  };

  private checkLocalStorageChanged = (e: StorageEvent) => {
    if (window.document.hasFocus()) return;
    const { storageArea } = e;
    if (this.state.isLoggedIn && storageArea.authInfo) {
      const { accessToken } = JSON.parse(storageArea.authInfo);
      if (accessToken && accessToken !== this.state.authInfo.accessToken) {
        window.location.reload();
      }
    }

    if (this.state.isLoggedIn && storageArea.currentOrganization) {
      const reduxOrganization = store.getState().organizationReducer;
      const { organizationReducer } = JSON.parse(storageArea.currentOrganization);
      if (
        organizationReducer.organization_id &&
        (reduxOrganization.organization_id !== organizationReducer.organization_id ||
          reduxOrganization.site_id !== organizationReducer.site_id ||
          reduxOrganization.department_id !== organizationReducer.department_id)
      ) {
        this.props.setCurrentOrganization(organizationReducer);
        window.routerHistory.push(AppRoutes.Home);
        window.location.reload();
      }
    }
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    resetRedux: () => {
      dispatch(resetAction());
    },
    authorizeUser: () => {
      dispatch(authorizeUserAction());
    },
    setCurrentOrganization: (payload: UserOrganizationSwitcherPayload) => {
      dispatch(setCurrentOrganization(payload));
    },
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    tokenLastRefreshAt: state.flagReducer.tokenLastRefreshAt,
  };
};

const ConnectedAuthProvider = connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

export default (props) => (
  <Auth0Context.Consumer>
    {(auth0props: Auth0ContextInterface) => (
      <AdminAccessModalContext.Consumer {...props} auth0props={auth0props}>
        {({ adminAccessModalContextOpenStatus, setAdminAccessModalContextOpenStatus }) => {
          return (
            <ConnectedAuthProvider
              {...props}
              auth0props={auth0props}
              adminAccessModalContextOpenStatus={adminAccessModalContextOpenStatus}
              setAdminAccessModalContextOpenStatus={setAdminAccessModalContextOpenStatus}
            />
          );
        }}
      </AdminAccessModalContext.Consumer>
    )}
  </Auth0Context.Consumer>
);
