import React from 'react';
import {
  FetchPaginatedInviteRequestInput,
  FetchPaginatedRequestInput,
  FetchPaginatedRequestInputSTA,
  FetchSearchPaginatedInviteRequestInput,
  SearchRequestInput,
  SearchRequestInputSTA,
  UserRepository,
} from 'src/data/repository/UserRepository';
import { PENDING_ADMIN, PENDING_USER } from '../../../constants/inviteUserTypes';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';

export const UserViewModel = () => {
  const repo = UserRepository();

  const useFetchPaginatedUsers = ({ continuationId, direction }: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedUsers({ continuationId, direction });

    return {
      ...result,
    };
  };

  const useFetchPaginatedUsersSTA = ({ pageInfo }: FetchPaginatedRequestInputSTA) => {
    const result = repo.useFetchPaginatedUsersSTA({ pageInfo });

    return {
      ...result,
    };
  };

  const useFetchPaginatedRemovedUsers = ({ continuationId, direction, skip }: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedRemovedUsers({ continuationId, direction, skip });
    return {
      ...result,
    };
  };

  const useFetchPaginatedRemovedUsersSTA = ({ pageInfo, skip }: FetchPaginatedRequestInputSTA) => {
    const result = repo.useFetchPaginatedRemovedUsersSTA({ pageInfo, skip });
    return {
      ...result,
    };
  };

  const useFetchPaginatedInvites = ({
    continuationId,
    direction,
    inviteStatus,
    skip,
  }: FetchPaginatedInviteRequestInput) => {
    const result = repo.useFetchPaginatedInvites({ continuationId, direction, inviteStatus, skip });
    return {
      ...result,
    };
  };

  const useFetchPaginatedInvitesSTA = ({ filters, pageInfo, skip }: FetchPaginatedRequestInputSTA) => {
    const result = repo.useFetchPaginatedInvitesSTA({ pageInfo, filters, skip });
    return {
      ...result,
    };
  };

  const getSearchedUsers = async ({ text, limit, continuationId, organizationType }: SearchRequestInput) => {
    const result = await repo.searchUsers({ text, limit, continuationId, organizationType });

    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchUsers,
    };
  };

  const getSearchedUsersSTA = async ({ text, filters, pageInfo, organizationType }: SearchRequestInputSTA) => {
    const result = await repo.searchUsersSTA({ text, filters, pageInfo, organizationType });

    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.paginatedMembers,
    };
  };

  const getSearchedRemovedUsersSTA = async ({ text, filters, pageInfo }: SearchRequestInputSTA) => {
    const result = await repo.searchRemovedUsersSTA({ text, filters, pageInfo });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }
    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchRemovedUsers,
    };
  };

  const getSearchedRemovedUsers = async ({ text, limit, continuationId }: SearchRequestInput) => {
    const result = await repo.searchRemovedUsers({ text, limit, continuationId });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }
    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchRemovedUsers,
    };
  };

  const getSearchedPendingInvitesSTA = async ({ text, filters, pageInfo }: SearchRequestInputSTA) => {
    const result = await repo.searchPendingInvitesSTA({ text, filters, pageInfo });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchPendingInvites,
    };
  };

  const getSearchedPendingInvites = async ({
    text,
    limit,
    continuationId,
    inviteStatus,
  }: FetchSearchPaginatedInviteRequestInput) => {
    const result = await repo.searchPendingInvites({ text, limit, continuationId, inviteStatus });
    if (result?.errors) {
      const error = result?.errors[0].message;
      return { error };
    }

    return {
      ...result?.data?.adminQuery.organizationalUnit.searchQuery.searchPendingInvites,
    };
  };

  const getCombinedSearchData = async ({ text, skip, continuationId, orgType }) => {
    const searchPaginatedResultPayload = {
      limit: 30,
      continuationId,
      text,
      organizationType: orgType,
    };

    const searchUsersPromise = getSearchedUsers(searchPaginatedResultPayload);
    let searchRemovedUserPromise;
    let searchPendingInvitesPromise;
    let searchPendingAdminApprovalPromise;

    if (!skip) {
      searchRemovedUserPromise = getSearchedRemovedUsers(searchPaginatedResultPayload);
      searchPendingInvitesPromise = getSearchedPendingInvites({
        ...searchPaginatedResultPayload,
        inviteStatus: PENDING_USER,
      });
      searchPendingAdminApprovalPromise = getSearchedPendingInvites({
        ...searchPaginatedResultPayload,
        inviteStatus: PENDING_ADMIN,
      });
    }

    let [
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    ] = await Promise.all([
      searchUsersPromise,
      searchRemovedUserPromise,
      searchPendingInvitesPromise,
      searchPendingAdminApprovalPromise,
    ]);

    return {
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    };
  };

  const getCombinedSearchDataSTA = async ({
    text,
    filters,
    pageInfo,
    organizationType,
    skip,
  }: SearchRequestInputSTA) => {
    const searchPaginatedResultPayload: SearchRequestInputSTA = {
      text,
      filters,
      pageInfo,
      organizationType,
      skip,
    };

    const searchUsersPromise = getSearchedUsersSTA(searchPaginatedResultPayload);
    let searchRemovedUserPromise;
    let searchPendingInvitesPromise;
    let searchPendingAdminApprovalPromise;

    if (!skip) {
      searchRemovedUserPromise = getSearchedRemovedUsersSTA(searchPaginatedResultPayload);
      searchPendingInvitesPromise = getSearchedPendingInvitesSTA({
        ...searchPaginatedResultPayload,
        filters: {
          ...searchPaginatedResultPayload.filters,
          inviteStatus: PENDING_USER,
        },
      });
      searchPendingAdminApprovalPromise = getSearchedPendingInvitesSTA({
        ...searchPaginatedResultPayload,
        filters: {
          ...searchPaginatedResultPayload.filters,
          inviteStatus: PENDING_ADMIN,
        },
      });
    }

    let [
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    ] = await Promise.all([
      searchUsersPromise,
      searchRemovedUserPromise,
      searchPendingInvitesPromise,
      searchPendingAdminApprovalPromise,
    ]);

    return {
      searchUsersResult,
      searchRemovedUserResult,
      searchPendingInvitesResult,
      searchPendingAdminApprovalInvitesResult,
    };
  };

  const getOrganizationScopeList = async () => {
    const organizationList = await repo.fetchOrganizationScopeList();

    return organizationList.data;
  };

  const createShellAccountMutation = async (accounts) => {
    const org = checkOrganizationalUnit();
    if (org.type === 'error') {
      return {
        error: 'missing org',
      };
    }
    const result = await repo.createShellAccounts(accounts);

    return {
      data: result,
      error: result.error,
    };
  };

  const inviteUsers = async (users) => {
    const org = checkOrganizationalUnit();
    if (org.type === 'error') {
      return {
        error: 'missing org',
      };
    }
    const result = await repo.inviteUsers(users);

    return {
      data: result,
      error: result.error,
    };
  };

  return {
    useFetchPaginatedUsers,
    useFetchPaginatedUsersSTA,
    useFetchPaginatedRemovedUsers,
    useFetchPaginatedRemovedUsersSTA,
    useFetchPaginatedInvites,
    useFetchPaginatedInvitesSTA,
    getOrganizationScopeList,
    getCombinedSearchData,
    getCombinedSearchDataSTA,
    getSearchedUsers,
    getSearchedUsersSTA,
    getSearchedPendingInvites,
    getSearchedPendingInvitesSTA,
    getSearchedRemovedUsers,
    getSearchedRemovedUsersSTA,
    createShellAccountMutation,
    inviteUsers,
  };
};
