import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import {
  Button,
  DialogActions,
  InputLabel,
  Slide,
  withStyles,
  Typography,
  Tooltip,
  Breadcrumbs,
  DialogTitle,
  TextField,
  Divider,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Checkbox from '@material-ui/core/Checkbox';

const GeneralButton = styled(Button)`
  text-transform: none !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;

  span {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 15px;
    line-height: 24px !important;
  }

  &:disabled {
    color: white !important;
  }
`;

export interface IHypercareButtons {
  color?: string;
  background?: string;
}

interface HCCustomComponentsProps {
  color: string;
  padding: string;
  margin: string;
  fontWeight: number | string;
  fontFamily: string;
  fontSize: string;
  isLink: boolean;
  afterContent?: number | string;
  afterColor?: string;
  lineHeight?: string;
}

export const HCBodySpan = styled.span.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  color: ${(props) => (props.isLink ? props.theme.mainTealColor : props.color || '#222222')};
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};
  text-decoration: ${(props) => (props.isLink ? 'underline' : 'none')};
`;

export const PrimaryButton = styled(GeneralButton).attrs((props: IHypercareButtons) => ({
  variant: 'outlined',
  ...props,
}))`
  background-color: ${(props) => (props.background ? props.background : props.theme.mainButtonColor)} !important;
  color: white !important;
  font-weight: 600 !important;
  border: ${(props) => (props.border ? props.border : '1px solid rgba(0, 0, 0, 0.23)')} !important;

  &:disabled {
    background-color: ${(props) => props.theme.warmGrey} !important;
  }

  &:hover {
    background-color: ${(props) => props.theme.warmRed} !important;
  }
`;

export const SecondaryButton = styled(GeneralButton).attrs((props: IHypercareButtons) => ({
  variant: 'outlined',
  ...props,
}))`
  color: ${(props) => props.color || props.theme.mainTealColor} !important;
  font-weight: 600 !important;

  &:disabled {
    background-color: ${(props) => props.theme.warmGrey} !important;
  }
`;

export const TextButton = styled(GeneralButton).attrs((props: IHypercareButtons) => ({
  variant: 'text',
  ...props,
}))`
  color: ${(props) => (props.color ? props.color : props.theme.mainTealColor)} !important;
  font-weight: 600 !important;
  font-size: 16px;
`;

export const LinkButton = styled(GeneralButton).attrs(() => ({
  variant: 'text',
  disableRipple: true,
}))`
  color: ${(props) => props.theme.mainTealColor} !important;
  font-weight: 600 !important;
  font-size: 16px;
  background: transparent !important;
  padding: 0 !important;

  &:hover {
    .MuiButton-label {
      text-decoration: underline;
    }
  }
`;

export const FieldInputLabel = styled(InputLabel)<{ fontColor?: string }>`
  color: #222222 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  display: flex !important;
  align-items: flex-end !important;
  margin-bottom: 4px !important;
`;

export const DescriptionText = styled(Typography)`
  color: #767676 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-style: normal !important;
  font-family: 'Open Sans' !important;
`;

export const StyledDialogActions = withStyles({
  root: {
    padding: '12px 24px 24px 24px',
  },
})(DialogActions);

export const ThemedCheckbox = withStyles({
  root: {
    color: theme.mainFontColor,
    '&$checked': {
      color: theme.mainTealColor,
    },
  },
  checked: {},
})(Checkbox);

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogTitleTypography = styled(Typography)`
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 38px !important;
  font-family: 'Nunito Sans', sans-serif !important;
  color: ${(props) => props.theme.mainButtonColor} !important;
  word-break: break-word;
`;

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  & .tooltip {
    max-width: 30vw;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
    background-color: ${(props) => props.theme.mainFontColor} !important;
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.mainFontColor} !important;
  }
`;

export const Breadcrumb = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin-left: 4px !important;
    margin-right: 4px !important;
    margin-bottom: -5px !important;
  }
`;

export const DialogBoxTitle = styled(DialogTitle)`
  h2 {
    display: flex !important;
    font-family: 'Nunito Sans' !important;
    font-weight: 700 !important;
    font-size: 28px;
    line-height: 36px !important;
    color: ${(props) => props.theme.errorRed} !important;
  }
`;

export const MaterialDialogHeaderTitle = styled.h2.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  display: flex !important;
  font-family: 'Nunito Sans' !important;
  font-weight: 700 !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '28px')};
  line-height: 36px !important;
  color: ${(props) => (props.color ? props.color : props.theme.errorRed)};
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
`;

export const CustomCheckbox = withStyles({
  root: {
    color: theme.mainFontColor,
    '&$checked': {
      color: '#00859A',
    },
  },
  checked: {},
})(Checkbox);

export const DateTextField = styled(TextField)`
  .MuiOutlinedInput-inputMarginDense {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #222222;
    border-radius: 2px;
    padding: 12px 14px;
  }

  .MuiOutlinedInput-inputMarginDense.Mui-disabled {
    background-color: hsl(0, 0%, 95%) !important;
    border-color: hsl(0, 0%, 80%) !important;
    opacity: 1 !important;
  }

  .Mui-focused fieldset {
    border-color: #00859a !important;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: 0 0 0 1px #00859a !important;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 24px !important;
  margin-bottom: 16px !important;
`;

export const StyledDialogTitle = styled(Typography)`
  font-size: 21px !important;
  font-weight: 700 !important;
  line-height: 29px !important;
  font-family: 'Nunito Sans', sans-serif !important;
  color: ${(props) => props.theme.errorRed} !important;
`;

export const ToasterCloseContainer = styled.span`
  cursor: pointer !important;
  margin-top: 4px !important;
  margin-left: 16px !important;
`;

export const HCHeadingOne = styled.h1.attrs((props) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 40px;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCHeadingTwo = styled.h2.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 28px;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
`;

export const HCHeadingThree = styled.h3.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 21px;
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '21px')};
`;

export const HCHeadingFour = styled.h4.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)} !important;
  font-size: 17px;
  line-height: 24px;
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCBodyOne = styled.div.attrs((props) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
`;

export const HCBodyTwo = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '21px')};

  &:after {
    content: '${(props) => props.afterContent || ''}';
    color: ${(props) => (props.afterColor ? props.afterColor : 'red')};
  }
`;

export const HCLabelOne = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: 24px;
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;

  &:after {
    content: '${(props) => props.afterContent || ''}';
    color: ${(props) => (props.afterColor ? props.afterColor : 'red')};
  }
`;

export const HCLabelTwo = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};

  &:after {
    content: '${(props) => props.afterContent || ''}';
    color: ${(props) => (props.afterColor ? props.afterColor : 'red')};
  }
`;

export const HCLabelThree = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : '#222222')};

  &:after {
    content: '${(props) => props.afterContent || ''}';
    color: ${(props) => (props.afterColor ? props.afterColor : 'red')};
  }
`;

export const HCLabelFour = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCTextContext = styled.div.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};

  &:after {
    content: '${(props) => props.afterContent || ''}';
    color: ${(props) => (props.afterColor ? props.afterColor : 'red')};
  }
`;

export const HCTextContextTwo = styled.div.attrs((props) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
`;

export const HCLargeBoxDescription = styled.h2.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : props.theme.mainFontColor)};
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
`;

export const HCCustomText = styled.h2.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Open Sans')} !important;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)} !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  color: ${(props) => (props.color ? props.color : props.theme.mainFontColor)};
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
`;

export const HCCustomSpan = styled.span.attrs((props: HCCustomComponentsProps) => ({ ...props }))`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  line-height: 24px;
  color: ${(props) => (props.color ? props.color : '')};
  margin: ${(props) => (props.margin ? props.margin : 0)} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
`;

export const RedTag = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 20px;

  margin-left: 6px;
  padding: 2px 8px;

  border-radius: 20px;
  background: ${(props) => props.theme.watermelon};
`;

export const HCLabelSix = styled.div.attrs((props) => ({ ...props }))`
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : '#4A4A4A')};
`;
