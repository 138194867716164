import _ from 'lodash';
import React, { useState } from 'react';
import { StyledModal, CloseBtn, Header, Footer } from 'src/components/modals/ModalStyleComponents';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import {
  HCHeadingThree,
  HCLabelOne,
  HCBodySpan,
  SecondaryButton,
  PrimaryButton,
} from 'src/components/shared/HypercareComponents';
import InfoIcon from 'src/assets/svgs/Info';
import {
  CANCEL,
  CONFIRM_PURCHASE_TEXT_PART_1,
  CONFIRM_PURCHASE_TEXT_PART_2,
  CONFIRM_PURCHASE_TEXT_PART_3,
  CONFIRM_PURCHASES,
  DONE,
  PAGER_PURCHASE_PROCESSING_COMPLETE,
  PROCESSING,
  PURCHASE,
  PURCHASE_FAIL,
  PURCHASE_SUCCESS,
  PURCHASING,
} from 'src/constants/virtualPagerStrings';

import NumberCell from './components/NumberCell';
import { TwilioNumbers, PagerPurchasedState } from 'src/types';
import GetNumberViewModal from './GetNumberViewModel';
import store from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
const ModalHeader = styled(Header)``;
const ModalFooter = styled(Footer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset !important;
  padding: 16px 24px;
  gap: 8px;
`;

const InfoBox = styled.div`
  background: ${theme.lighterTeal};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const InfoIconContainer = styled.div`
  padding: 0px;
  margin: 0px;
`;

const StylizedModal = styled(StyledModal)`
  .ReactModal__Content {
    width: 560px;
    padding: 0;
  }
`;

const ConfirmPurchaseModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 24px;
`;

const ScrollableContent = styled.div`
  max-height: 40vh;
  overflow-y: auto;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  incomingTwilioNumberList: TwilioNumbers[];
  closeMainModal: () => void;
  onPurchase?: () => Promise<void>;
}

type PurchaseState = 'prePurchase' | 'isPurchasing' | 'postPurchase';

const ModalNumberConfirmView = ({
  isOpen,
  closeModal,
  incomingTwilioNumberList,
  closeMainModal,
  onPurchase,
}: Props) => {
  let hasPurchaseFailed = null;
  const orgId = store.getState()?.organizationReducer?.organization_id;
  const { formatPhoneNumber, purchaseTwilioNumbers } = GetNumberViewModal();
  const formattedList: string[] = incomingTwilioNumberList.map((item) => formatPhoneNumber(item.phoneNumber));

  const [successList, setSuccessList] = useState({});
  const [purchaseState, setPurchaseState] = useState<PurchaseState>('prePurchase');

  const handlePurchaseClick = async () => {
    const selected_virtual_pager_numbers = incomingTwilioNumberList.map((number) =>
      formatPhoneNumber(number.phoneNumber),
    );
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersConfirmPurchasePurchaseButtonPressed,
      params: {
        selected_virtual_pager_numbers: selected_virtual_pager_numbers,
      },
    });
    setPurchaseState('isPurchasing');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersProcessingShow,
    });
    let results: { [key: string]: string } = {};
    for (const number of incomingTwilioNumberList) {
      const response = await purchaseTwilioNumbers(number.phoneNumber, orgId, number.locality);
      if (response === 'fail') hasPurchaseFailed = true;
      results[formatPhoneNumber(number.phoneNumber)] = response;
    }
    setSuccessList(results);
    await onPurchase();
    setPurchaseState('postPurchase');
    const successful_purchases = Object.keys(results).filter((number) => results[number] !== 'fail');
    const failed_purchases = Object.keys(results).filter((number) => results[number] === 'fail');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersProcessingCompletedShow,
      params: {
        successful_purchase_virtual_pager_numbers: successful_purchases,
        failed_purchase_virtual_pager_numbers: failed_purchases,
      },
    });
  };

  const completePurchase = () => {
    const successful_purchases = Object.keys(successList).filter((number) => successList[number] !== 'fail');
    const failed_purchases = Object.keys(successList).filter((number) => successList[number] === 'fail');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersDonePressed,
      params: {
        successful_purchase_virtual_pager_numbers: successful_purchases,
        failed_purchase_virtual_pager_numbers: failed_purchases,
      },
    });
    setPurchaseState('prePurchase');
    closeModal();
    closeMainModal();
  };

  const handleCancel = (source) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaGetNumbersConfirmPurchaseCancelButtonPressed,
      params: {
        source: source,
      },
    });
    closeModal();
  };

  return (
    <StylizedModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      {purchaseState === 'prePurchase' ? (
        <div>
          <ConfirmPurchaseModalContainer>
            <ModalHeader>
              <HCHeadingThree>{CONFIRM_PURCHASES}</HCHeadingThree>
              <CloseBtn onClick={() => handleCancel('x')} />
            </ModalHeader>

            <InfoBox>
              <InfoIconContainer>
                <InfoIcon
                  width={24}
                  height={24}
                  circlefill={theme.mainTealColor}
                  strokefill={theme.white}
                  stroke={theme.white}
                />
              </InfoIconContainer>
              <div>
                <HCBodySpan fontSize="16px">{CONFIRM_PURCHASE_TEXT_PART_1}</HCBodySpan>
                <HCBodySpan fontSize="16px" fontWeight={600}>
                  {CONFIRM_PURCHASE_TEXT_PART_2}
                </HCBodySpan>
                <HCBodySpan fontSize="16px">{CONFIRM_PURCHASE_TEXT_PART_3}</HCBodySpan>
              </div>
            </InfoBox>
            <ScrollableContent style={{ marginTop: '10px' }}>
              <NumberCell formattedList={formattedList} addSecondCell={false} />
            </ScrollableContent>
          </ConfirmPurchaseModalContainer>
          <ModalFooter>
            <SecondaryButton onClick={() => handleCancel('Cancel')}>{CANCEL}</SecondaryButton>
            <PrimaryButton onClick={handlePurchaseClick}>{PURCHASE}</PrimaryButton>
          </ModalFooter>
        </div>
      ) : purchaseState === 'isPurchasing' ? (
        <div>
          <ConfirmPurchaseModalContainer>
            <ModalHeader>
              <HCHeadingThree>{PROCESSING}</HCHeadingThree>
            </ModalHeader>
            <ScrollableContent>
              <NumberCell formattedList={formattedList} addSecondCell={true} />
            </ScrollableContent>
          </ConfirmPurchaseModalContainer>
          <ModalFooter>
            <PrimaryButton disabled={true} onClick={handlePurchaseClick}>
              {PURCHASING}
            </PrimaryButton>
          </ModalFooter>
        </div>
      ) : (
        <div>
          <ConfirmPurchaseModalContainer>
            <ModalHeader>
              <HCHeadingThree>{PAGER_PURCHASE_PROCESSING_COMPLETE}</HCHeadingThree>
            </ModalHeader>
            {!hasPurchaseFailed ? (
              <div>
                <HCLabelOne>{PURCHASE_SUCCESS}</HCLabelOne>
                <ScrollableContent>
                  <NumberCell formattedList={formattedList} addSecondCell={true} successList={successList} />
                </ScrollableContent>
              </div>
            ) : (
              <div>
                <HCLabelOne>{PURCHASE_SUCCESS}</HCLabelOne>
                <ScrollableContent>
                  <NumberCell
                    formattedList={formattedList}
                    addSecondCell={true}
                    successList={_.pickBy(successList, function (value: PagerPurchasedState) {
                      return value === 'success';
                    })}
                  />
                </ScrollableContent>
                <HCLabelOne>{PURCHASE_FAIL}</HCLabelOne>
                <ScrollableContent>
                  <NumberCell
                    formattedList={formattedList}
                    addSecondCell={true}
                    successList={_.pickBy(successList, function (value: PagerPurchasedState) {
                      return value === 'fail';
                    })}
                  />
                </ScrollableContent>
              </div>
            )}
          </ConfirmPurchaseModalContainer>
          <ModalFooter>
            <PrimaryButton disabled={false} onClick={completePurchase}>
              {DONE}
            </PrimaryButton>
          </ModalFooter>
        </div>
      )}
    </StylizedModal>
  );
};

export default ModalNumberConfirmView;
