import React, { useEffect, useRef, useState } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import { defaultTime, IDLE_TIMEOUT } from 'src/constants/sessionTimeoutTypes';
import { getCurrentOrganizationId } from 'src/utils/getLocalAuth';
import FirebaseRemoteConfigModule from 'src/components/SessionTimeout/FirebaseRemoteConfigModule';
import { IdleTimerManager } from 'src/components/SessionTimeout/IdleTimerManager';
import { isFirebaseRemoteConfigSupported } from 'src/firebase/firebaseSetup';

interface Props {
  isLoggedIn?: boolean;
  logout: () => void;
}

const IdleTimerHandler = ({ logout }: Props) => {
  const firebaseRemoteConfigModule = FirebaseRemoteConfigModule();
  const currentOrganizationId = getCurrentOrganizationId();

  const [dataHasBeenFetched, setDataHasBeenFetched] = useState(false);
  const [showIdleModal, setShowIdleModal] = useState(false);

  let idleTimeout = useRef(defaultTime.IDLE_TIMEOUT);

  const handleModalChange = (value: boolean) => {
    setShowIdleModal(value);
  };

  useEffect(() => {
    const getIdleTimeoutData = async () => {
      const result = await isFirebaseRemoteConfigSupported();

      if (result) {
        const idleTimeoutData = (await firebaseRemoteConfigModule.getOrganizationConfig(currentOrganizationId))
          ?.idle_timeout;

        if (!idleTimeoutData) {
          sessionStorage.setItem(IDLE_TIMEOUT, idleTimeout.current.toString());
        } else {
          idleTimeout.current = idleTimeoutData;
          sessionStorage.setItem(IDLE_TIMEOUT, idleTimeoutData);
        }
      }
      setDataHasBeenFetched(true);
    };

    getIdleTimeoutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dataHasBeenFetched ? (
    <IdleTimerManager
      showModal={showIdleModal}
      value={idleTimeout.current}
      logout={logout}
      handleModalChange={handleModalChange}
    />
  ) : null;
};

export default () => (
  <AuthContext.Consumer>
    {({ isLoggedIn, logout }) => (isLoggedIn ? <IdleTimerHandler logout={logout} /> : null)}
  </AuthContext.Consumer>
);
