import gql from 'graphql-tag';

export const REMOVE_ADDRESSES_MUTATION = gql`
  mutation RemoveAddressSTA($organizationalUnit: OrganizationalUnitInput!, $id: ID!, $addressIds: [String!]!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $id) {
              ... on UserNotFoundError {
                __typename
                message
              }
              ... on FullOrganizationMemberMutation {
                removeAddresses(addressIds: $addressIds) {
                  __typename
                  addresses {
                    __typename
                    ... on DeletedAddress {
                      address
                    }
                    ... on AddressNotOwnedByUser {
                      __typename
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
