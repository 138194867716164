import { Operation, NextLink, fromPromise } from 'apollo-link';
import AuthHelper from 'src/auth/authHelper';
import { INVALID_ORG_SCOPE, NO_RECORD } from 'src/constants/networkError';
import { USER_AUTH_EXPIRATION_NAMES } from 'src/constants/user';
import { ServerError, ServerParseError } from 'apollo-link-http-common';
import ApiHelper from 'src/api';
import store from 'src/redux/store';
import { resetAction } from 'src/redux/actions/authorizeAction';
import { GraphQLError } from 'graphql';
import { print } from 'graphql/language/printer';
import { IS_EXPIRED } from 'src/constants/storageKeys';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

// TODO: request abortion with uuid
// https://github.com/apollographql/apollo-feature-requests/issues/40

// retry docs:
// https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8

// original discussion:
// https://github.com/apollographql/apollo-link/issues/646

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  console.log(`resolving pending requests, ${pendingRequests.length} in total...`);
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

// TODO: align with authProvider logout, currently reload to make sure reset
const handleUserExpired = () => {
  AuthHelper.logout();
  // redux
  window.localStorage.clear();
  store.dispatch(resetAction());
};

// TODO: create error model that match backend
// https://github.com/Hypercare/hypercare-api/blob/fd469dbc4be2ec36c5fbbe3e8a456eb27fbbf43f/App/src/utils/errors.js
export const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError | any,
  operation: Operation,
  forward: NextLink,
) => {
  // TODO: track error event for analytics
  const castedNetworkError = networkError as any;
  const context = operation.getContext();
  const { response } = context;

  if (response) {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.networkRequest,
      params: {
        status_code: response.status ? response.status : '',
        query: print(operation.query),
        error_code:
          castedNetworkError.result && castedNetworkError.result.errors[0] && castedNetworkError.result.errors[0].code
            ? castedNetworkError.result.errors[0].code
            : '',
        url_path: response.url,
      },
    });
  }

  // this should only happen when user manually editing the localStorage scope
  if (
    networkError.result &&
    networkError.result.errors &&
    networkError.result.errors[0] &&
    networkError.result.errors[0].name === INVALID_ORG_SCOPE
  ) {
    console.error('Organization scope was invalid', networkError);
    handleUserExpired();
  }

  // e.g. when force logout from admin
  if (networkError.result && networkError.result.inner && networkError.result.inner.name === NO_RECORD) {
    console.error('User record not found', networkError);
    handleUserExpired();
  }
};

export const handleGraphQLErrors = ({
  graphQLErrors,
  operation,
  forward,
}: {
  graphQLErrors: readonly GraphQLError[];
  operation: Operation;
  forward: NextLink;
}) => {
  for (const err of graphQLErrors) {
    console.error(`ran into graphql errors: ${err.name}`);
    console.error(err);
    if (USER_AUTH_EXPIRATION_NAMES.includes(err.name)) {
      if (!isRefreshing) {
        setIsRefreshing(true);

        return fromPromise(
          ApiHelper.refreshAccessToken().catch(() => {
            resolvePendingRequests();
            setIsRefreshing(false);

            // if refresh accessToken failed, logout without retry for now
            handleUserExpired();

            return forward(operation);
          }),
        ).flatMap(() => {
          resolvePendingRequests();
          setIsRefreshing(false);

          return forward(operation);
        });
      } else {
        return fromPromise(
          new Promise<void>((resolve) => {
            addPendingRequest(() => resolve());
          }),
        ).flatMap(() => {
          return forward(operation);
        });
      }
    }
  }
};
