import React, { useContext, useEffect, useState } from 'react';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { LogOutModalWrapper } from 'src/components/modals/LogoutPageTimeoutModal';
import { IDLE_TIMEOUT, LAST_ACTIVE_TIME, SESSION_TIMEOUT_LOGINPAGE_MODAL } from 'src/constants/sessionTimeoutTypes';
import { AUTH_INFO, ORGANIZATION_ACCOUNTS_DATA } from '../../constants/storageKeys';
import { IntroductionPage } from '../NewLoginPage/components/IntroductionPage';
import { localStorageService } from '../../services/localStorageService';
import { OrganizationAccountsCacheData } from '../../types/sta';
import { base64GzipDecode } from '../../utils/sta/util';
import AdminAccessModalContext from '../../contexts/AdminAccessModalContext';
import { CustomModal } from '../../microfrontend/modals/CustomModal';
import { ACCOUNT_QUERY_PARAM } from '../../constants/queryParams';
import { AuthContext } from '../../auth';
import { Redirect } from 'react-router-dom';
import { StaLogin } from '../../types';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import { PageContainer } from '../NewLoginPage/styled/login.styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getParsedAuthInfo } from '../../utils/localStorageHelper';

interface LoginPageProps {
  staLogin: StaLogin;
  isLoggedIn: boolean;
  isAuthorized: boolean;
}

const LoginPage = ({ staLogin, isLoggedIn, isAuthorized }: LoginPageProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const account = urlParams.get(ACCOUNT_QUERY_PARAM) || '';

  const [isOpen, setIsOpen] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const idleTimeoutValue = window.sessionStorage.getItem(IDLE_TIMEOUT);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const authBoi = getParsedAuthInfo();

  console.log({ authBoi });

  const { adminAccessModalContextOpenStatus, setAdminAccessModalContextOpenStatus } =
    useContext(AdminAccessModalContext);
  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.loginView);
    const modalCloseValues = JSON.parse(window.sessionStorage.getItem(SESSION_TIMEOUT_LOGINPAGE_MODAL));
    const initialModalValue = modalCloseValues ? true : false;
    setIsOpen(initialModalValue);
  }, [isOpen]);

  useEffect(() => {
    const loginToAccount = async () => {
      if (account) {
        window.localStorage.removeItem(AUTH_INFO);
        window.localStorage.removeItem(LAST_ACTIVE_TIME);
        const base64GzipDecoded = base64GzipDecode(account);

        console.log(base64GzipDecoded);

        if (base64GzipDecoded) {
          const res = await staLogin(JSON.parse(base64GzipDecoded));

          if (res?.success) {
            window.routerHistory.push('/');
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    loginToAccount();
  }, []);

  useEffect(() => {
    if (
      currentCacheData &&
      currentCacheData?.savedOrganizations?.length >= 1 &&
      currentCacheData?.selectedAccountUserId &&
      !account
    ) {
      const findAccount = currentCacheData.savedOrganizations.find(
        (acc) => acc.user.id === currentCacheData.selectedAccountUserId,
      );
      if (findAccount && !account) staLogin(findAccount);
    }
  }, []);

  console.log(isLoading, 'isLoading');

  if (isLoading) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!account && isAuthorized && isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { params: '/home' },
        }}
      />
    );
  }

  const showIntroPage =
    Object.keys(currentCacheData ? currentCacheData : {}).length === 0 ||
    (currentCacheData.selectedAccountUserId.length === 0 && !isLoading);

  console.log(isLoading, 'isLoading');

  return (
    <>
      {isLoading && <CircularProgress />}
      {showIntroPage && <IntroductionPage />}
      {isOpen ? <LogOutModalWrapper isOpen={isOpen} setIsOpen={setIsOpen} timeoutValue={idleTimeoutValue} /> : null}
      {adminAccessModalContextOpenStatus && (
        <CustomModal
          width="sm"
          showCloseButton={false}
          titleFontSize={'21px'}
          title={'You need to be an admin to access the Hypercare Admin Portal'}
          subtitle={
            'Your account does not have admin access or is linked to a removed account. Contact your admin if you need help.'
          }
          isAlertModalVisible={adminAccessModalContextOpenStatus}
          alertModalButtons={[
            {
              type: 'primary',
              buttonLabel: 'OK',
              onClickHandler: () => setAdminAccessModalContextOpenStatus(false),
              id: 'cancel-btn',
            },
          ]}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuthorized: state.flagReducer.isAuthorized,
  };
};

const LoginLayout = React.memo(connect(mapStateToProps)(LoginPage));

const LoginPagePresenter = () => {
  return (
    <AuthContext.Consumer>
      {({ auth0props, staLogin, isLoggedIn }) => <LoginLayout staLogin={staLogin} isLoggedIn={isLoggedIn} />}
    </AuthContext.Consumer>
  );
};
export default LoginPagePresenter;
