// custom
export const ALREADY_EXIST = 'User already exists';
export const USER_NOT_ADMIN = 'NOT_ADMIN';
export const USERNAME_ALREADY_EXIST = 'Username already exists';
export const EMAIL_ALREADY_IN_USE = 'Email is already in use';
export const PHONE_ALREADY_IN_USE = 'Phone number is already in use';

// backend
export const USER_NOT_FOUND = 'UserNotFound';
export const ADDRESS_ALREADY_INUSE = 'AddressInUse';
export const BACKEND_ALREADY_EXIST = 'UserAlreadyExists';
export const INVALID_PHONE_NUMBER = 'InvalidPhoneNumber';
