import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { HCHeadingFour, HCLabelOne, SecondaryButton } from 'src/components/shared/HypercareComponents';
import ClearIcon from 'src/assets/svgs/ClearIcon';
import theme from 'src/assets/styles/theme';
import styled from 'styled-components';
import ChevronDown from 'src/assets/svgs/ChevronDown';
import { SearchFieldType } from 'src/types';
import { NUMBER, ASSIGNEE, DISPLAY_NAME, SEARCH_TYPE_DROPDOWN_HEADER } from 'src/constants/virtualPagerStrings';
import { setSearchType } from 'src/redux/actions/virtualPagersActions';
import { useDispatch } from 'react-redux';
import { typedUseSelector } from 'src/redux/store';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
const SearchByHeader = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid ${theme.borderBottomLight};
`;

type searchFieldTypeToTextMapping = { [key in Exclude<SearchFieldType, 'all'>]: string };

const options: searchFieldTypeToTextMapping = {
  number: NUMBER,
  userIds: ASSIGNEE,
  friendlyName: DISPLAY_NAME,
};

const SearchFieldSelectDropDown = () => {
  const dispatch = useDispatch();
  const searchType = typedUseSelector((state) => state.virtualPagerReducer.searchType);

  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);

  const [searchTypeVal, setSearchTypeVal] = React.useState<SearchFieldType>(searchType);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleOptionSelect = (searchType: SearchFieldType) => {
    handleClose();
    dispatch(setSearchType(searchType));
    setSearchTypeVal(searchType);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaListSearchTypeSelected,
      params: {
        search_type: options[searchType],
      },
    });
  };

  return (
    <>
      <SecondaryButton
        onClick={handleClick}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderColor: isOpen ? theme.mainTealColor : theme.greyBackground,
          background: 'white',
        }}
      >
        <HCLabelOne>{options[searchTypeVal]}</HCLabelOne>
        <ChevronDown
          style={{
            color: 'transparent',
            fill: '#4A4A4A',
            rotate: isOpen ? '180deg' : '0deg',
            transition: 'all 0.2s ease-in-out',
          }}
        />
      </SecondaryButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          borderRadius: '10px',
        }}
      >
        <SearchByHeader>
          <HCHeadingFour color={theme.darkenFontColor} fontWeight={700}>
            {SEARCH_TYPE_DROPDOWN_HEADER}
          </HCHeadingFour>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <ClearIcon width={20} height={20} />
          </div>
        </SearchByHeader>
        <div
          style={{
            padding: '8px',
          }}
        >
          {Object.keys(options).map((optionSearchType: SearchFieldType) => (
            <MenuItem
              style={{
                padding: '8px 10px',
                borderRadius: '4px',
              }}
              key={options[optionSearchType]}
              selected={searchTypeVal === optionSearchType}
              onClick={() => handleOptionSelect(optionSearchType)}
            >
              <HCLabelOne>{options[optionSearchType]}</HCLabelOne>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
};

export default SearchFieldSelectDropDown;
