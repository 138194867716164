import gql from 'graphql-tag';
import { FullOrganizationMemberFragment } from 'src/gql/fragment/FullOrganizationMemberFragment';
import { PageInfo, PaginatedUsersType } from 'src/types/PaginatedTypes';

export type PaginatedLicensedMembersSTA = {
  pageInfo: PageInfo;
  users: PaginatedUsersType[];
};

export type FetchPaginatedUsersQueryResponseSTA = {
  adminQuery: {
    organizationalUnit: {
      paginatedMembers: PaginatedLicensedMembersSTA;
    };
  };
};
export const FETCH_PAGINATED_USERS_QUERY_STA = gql`
  query FetchPaginatedUsers(
    $filters: AdminPaginatedMembersFilter
    $pageInfo: CursorPageInput
    $organizationalUnit: OrganizationalUnitInput!
    $isDirSyncFeatureFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedMembers(filters: $filters, pageInfo: $pageInfo) {
              ...PaginatedUsersAdminQueryFragment
            }
          }

          ... on AdminSiteQuery {
            paginatedMembers(filters: $filters, pageInfo: $pageInfo) {
              ...PaginatedUsersAdminQueryFragment
            }
          }

          ... on AdminDepartmentQuery {
            paginatedMembers(filters: $filters, pageInfo: $pageInfo) {
              ...PaginatedUsersAdminQueryFragment
            }
          }
        }
      }
    }
  }

  fragment PaginatedUsersAdminQueryFragment on AdminPaginatedMembersResponse {
    pageInfo {
      hasNextPage
      nextCursor
      totalResults
      resultsCount
      pageSize
    }
    users {
      ...FullOrganizationMemberFragment
    }
  }

  ${FullOrganizationMemberFragment}
`;
