import React from 'react';
import styled from 'styled-components';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import AppTheme from 'src/assets/styles/theme';

export const WrappedToastContainer = ({ className, ...rest }: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

// ref:
// https://github.com/fkhadra/react-toastify/issues/342
// https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components

export default styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    width: 650px;
    max-width: 100vw !important;
  }
  .Toastify__toast {
    padding: 8px 14px;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__toast--info {
    background-color: ${AppTheme.mainTealColor};
    color: white;
  }
  /* .Toastify__toast--success {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__progress-bar {
  } */
  .Toastify__close-button {
    font-size: 1em;
    margin-left: 0.5em;
    align-self: center;
  }
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
    margin-left: unset;
  }
`;
