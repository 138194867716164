import React from 'react';
import theme from 'src/assets/styles/theme';
import styled from 'styled-components';
import { BannerMessageType } from 'src/types';
import InfoIcon from 'src/assets/svgs/Info';
import WarningIcon from 'src/assets/svgs/WarningIcon';
import { HCBodySpan, HCLargeBoxDescription } from './HypercareComponents';
import ConflictIcon from 'src/assets/svgs/ConflictIcon';
import ErrorIcon from 'src/assets/svgs/ErrorIcon';
import CheckMarkRound from 'src/assets/svgs/CheckMarkRound';

const BannerMessageContainer = styled.div<{ type: BannerMessageType }>`
  background: ${(props) =>
    props.type === 'info' || props.type === 'success'
      ? theme.lighterTeal
      : props.type === 'warning'
      ? theme.yellowBackground
      : props.type === 'conflict'
      ? theme.orangeBackground
      : theme.backdropRed};
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  border: ${(props) =>
    props.type === 'error'
      ? `1px solid  ${theme.redBorder}`
      : props.type === 'warning'
      ? `1px solid ${theme.orangedYellow}`
      : props.type === 'conflict'
      ? `1px solid ${theme.orangeBorder}`
      : 'none'};
  border-radius: 5px;
`;

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const InfoIconContainer = styled.div`
  padding: 0px;
  margin: 0px;
`;

type MessageIconProps = {
  type: BannerMessageType;
};

const MessageIcon = ({ type }: MessageIconProps) => {
  switch (type) {
    case 'info':
      return (
        <InfoIcon
          width={24}
          height={24}
          circlefill={theme.mainTealColor}
          strokefill={theme.white}
          stroke={theme.white}
        />
      );
    case 'warning':
      return <WarningIcon width={24} height={24} />;
    case 'conflict':
      return <ConflictIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'success':
      return <CheckMarkRound />;
    default:
      return null;
  }
};

interface BannerMessageProps {
  title?: string;
  description: string | React.ReactNode;
  type: BannerMessageType;
}

export const BannerMessage: React.FC<BannerMessageProps> = ({ title, description, type }) => {
  return (
    <BannerMessageContainer type={type}>
      <InfoIconContainer>
        <MessageIcon type={type} />
      </InfoIconContainer>
      <BannerTextContainer>
        {title && (
          <HCLargeBoxDescription fontWeight={700} color={theme.black}>
            {title}
          </HCLargeBoxDescription>
        )}
        {typeof description === 'string' ? <HCBodySpan>{description}</HCBodySpan> : <>{description}</>}
      </BannerTextContainer>
    </BannerMessageContainer>
  );
};
