import gql from 'graphql-tag';

export type RemoveSessionByIdSelfMutationResult = {
  selfMutation: {
    session: {
      remove: {
        id: string;
      };
      __typename: string;
      message: string;
    };
  };
};

export const REMOVE_SESSION_BY_ID_SELF_MUTATION = gql`
  mutation RemoveSessionById($sessionId: ID!) {
    selfMutation {
      session(id: $sessionId) {
        ... on SessionNotFound {
          __typename
          message
        }
        ... on SessionMutation {
          remove {
            ... on FullOrganizationMember {
              id
            }
          }
        }
      }
    }
  }
`;
