import _ from 'lodash';
import * as React from 'react';
import BaseAccordion from './BaseAccordion';
import AudioAccordionDetails from './AccordionDetails/AudioAccordionDetails';
import { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import { setCurrentPager } from 'src/redux/actions/virtualPagersActions';
import VirtualPagerTutorialButton from '../VirtualPagerTutorialButton';
import {
  ACCORDION_VOICEMAIL_DEFAULT_DESCRIPTION,
  ACCORDION_VOICEMAIL_HEADER,
  ACCORDION_VOICEMAIL_SPECIFIC_DESCRIPTION,
} from 'src/constants/virtualPagerStrings';
import { VirtualPagerStatusTypes, DefaultAudioMap, audioObject } from 'src/types';
import ConfigureNumberViewModel from '../../ConfigureNumberViewModel';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const AudioAccordion = () => {
  const dispatch = useDispatch();
  const { configureVoicemail, configureCallback } = ConfigureNumberViewModel();
  const { editedPager, currentPager } = typedUseSelector((state) => state.virtualPagerReducer);
  const [defaultAudioMap, setDefaultAudioMap] = React.useState<DefaultAudioMap>({
    callback: null,
    voicemail: null,
  });
  const [selectedCallbackAudio, setSelectedCallbackAudio] = React.useState<audioObject | null>(null);
  const [selectedVoicemailAudio, setSelectedVoicemailAudio] = React.useState<audioObject | null>(null);
  const [opened, setOpened] = React.useState(false);

  const isSaveButtonDisabled =
    _.isEqual(editedPager.voicemail, currentPager.voicemail) && _.isEqual(editedPager.callback, currentPager.callback);

  const isUnconfigured = currentPager.callback === null && currentPager.voicemail === null;

  const trackClick = (expanded) => {
    setOpened(true);
    const containerStatus = isUnconfigured
      ? 'Pending Configuration'
      : isSaveButtonDisabled
      ? 'Configured'
      : 'Unsaved Changes';
    if (!expanded) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaAudioSetupClosePressed,
        params: {
          virtual_pager_number: currentPager.pagerNumber,
          selected_callback_audio: selectedCallbackAudio ? selectedCallbackAudio.name : '',
          selected_voicemail_audio: selectedVoicemailAudio ? selectedVoicemailAudio.name : '',
          container_status: containerStatus,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.pvpaConfigurationSetAudioPressed,
        params: {
          container_status: containerStatus,
        },
      });
    }
  };

  function TutorialButton() {
    return VirtualPagerTutorialButton('audio');
  }

  return (
    <>
      {editedPager && currentPager && (
        <BaseAccordion
          title={ACCORDION_VOICEMAIL_HEADER}
          description={{
            Generic: ACCORDION_VOICEMAIL_DEFAULT_DESCRIPTION,
            PagerSpecific: currentPager.callback && ACCORDION_VOICEMAIL_SPECIFIC_DESCRIPTION,
          }}
          trackClick={trackClick}
          handleSave={async () => {
            if (!_.isEqual(editedPager.voicemail, currentPager.voicemail)) {
              dispatch(setCurrentPager(await configureVoicemail(currentPager._id, editedPager.voicemail)));
            }
            if (!_.isEqual(editedPager.callback, currentPager.callback)) {
              dispatch(setCurrentPager(await configureCallback(currentPager._id, editedPager.callback)));
            }
            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.pvpaAudioSetupSavePressed,
              params: {
                virtual_pager_number: currentPager.pagerNumber,
                selected_callback_audio: selectedCallbackAudio ? selectedCallbackAudio.name : '',
                selected_voicemail_audio: selectedVoicemailAudio ? selectedVoicemailAudio.name : '',
              },
            });
          }}
          ExtraDescription={<TutorialButton />}
          isUnsaved={opened ? !isSaveButtonDisabled : false}
          isSaveButtonDisabled={isSaveButtonDisabled}
          isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
          isFieldValid={!_.isNil(currentPager.callback)}
          AccordionDetail={
            <AudioAccordionDetails
              isPagerDisabled={currentPager.status === VirtualPagerStatusTypes.DISABLED}
              defaultAudioMap={defaultAudioMap}
              setDefaultAudioMap={setDefaultAudioMap}
              selectedCallbackAudio={selectedCallbackAudio}
              setSelectedCallbackAudio={setSelectedCallbackAudio}
              selectedVoicemailAudio={selectedVoicemailAudio}
              setSelectedVoicemailAudio={setSelectedVoicemailAudio}
            />
          }
        />
      )}
    </>
  );
};

export default AudioAccordion;
