import gql from 'graphql-tag';

export const BasicOrganizationMemberFragment = gql`
  fragment FetchMembersForIDsResponseFragment on FetchMembersForIDsResponse {
    members {
      ... on UserNotFoundError {
        message
      }
      ... on FullOrganizationMember {
        id
        role
        firstName
        lastName
        memberStatus
      }
    }
  }
`;

export const FullOrganizationMemberFragment = gql`
  fragment FetchMembersForIDsResponseFragment on FetchMembersForIDsResponse {
    members {
      ... on UserNotFoundError {
        message
      }
      ... on FullOrganizationMember {
        id
        role
        firstName
        lastName
        memberStatus
        joinDate
        licenseStartTime
        licenseExpiryTime
        isDirectorySynced
        isSSOEnabled
        addresses {
          address
          type
          label
          access
          syncStatus
        }
        scopes {
          ...OrganizationScopeFragment
        }
      }
    }
  }

  fragment OrganizationScopeFragment on OrganizationScope {
    organizationId
    siteId
    departmentId
    status
  }
`;
