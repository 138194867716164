import React, { createContext } from 'react';

interface IAdminModalContext {
  adminAccessModalContextOpenStatus: boolean;
  setAdminAccessModalContextOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminAccessModalContext = createContext<IAdminModalContext>({
  adminAccessModalContextOpenStatus: false,
  setAdminAccessModalContextOpenStatus: () => {},
});

export default AdminAccessModalContext;
