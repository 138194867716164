import React from 'react';
import styled from 'styled-components';
import Info from 'src/assets/svgs/Info';
import { Tooltip } from '@material-ui/core';
import theme from 'src/assets/styles/theme';
import {
  PENDING_ADMIN,
  PENDING_ADMIN_APPROVAL,
  PENDING_ADMIN_RESPONSE,
  PresentedUserLicensingStatus,
} from 'src/constants/inviteUserTypes';
import { ApolloQueryResult } from 'apollo-client';
import { PaginatedInvites, PaginatedPendingInvitesSTA } from '../../../../types/PaginatedTypes';
import { OrganizationalUnitInputType } from '../../../../gql/v2/types/input';
import { FetchPaginatedInvitesQueryResponseSTA } from '../../../../gql/v2/query/sta/FetchPaginatedInvitesQuerySTA';
import {
  PENDING_ADMIN_APPROVAL_TOOLTIP_TEXT,
  PENDING_INVITEE_RESPONSE_TOOLTIP_TEXT,
} from '../../../../constants/strings';
import { PendingAdminUserListSTA } from './PendingAdminUsersListSTA';
import { PendingUsersListSTA } from './PendingUsersListSTA';

const StyledCategoryContainer = styled.div`
  user-select: none;
  display: flex;
  padding: 12px 12px 12px 0px;
`;

const StyledCategoryHeader = styled.div`
  width: 100%;
  min-width: 510px;
  padding-top: 12px;
  padding-bottom: 4px;
  display: flex;
  background: #fafafa;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
`;

const StyledTooltip = styled(Tooltip)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const StyledInfo = styled(Info)`
  height: 16px !important;
  width: 16px !important;
  margin: 4px 0px 0px 9px !important;
`;

interface Props {
  users: PaginatedInvites[];
  pendingByAdmin: PaginatedInvites[];
  setPendingByAdmin: React.Dispatch<React.SetStateAction<PaginatedInvites[]>>;
  pendingRecords: (
    variables?: OrganizationalUnitInputType & PaginatedPendingInvitesSTA,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponseSTA>>;
  paginatedPendingAdminInvitesQuery: FetchPaginatedInvitesQueryResponseSTA;
  fetchMorePaginatedAdminInviteQuery: (
    variables?: Record<string, any>,
  ) => Promise<ApolloQueryResult<FetchPaginatedInvitesQueryResponseSTA>>;
  searchPendingAdminApprovalInviteData: PaginatedPendingInvitesSTA;
  setSearchPendingAdminApprovalInviteData: React.Dispatch<React.SetStateAction<PaginatedPendingInvitesSTA>>;
  localSearchText: string;
  isDoneRows: boolean;
  setIsDoneRows: (isDoneRows: boolean) => void;
}

export const PendingUserListContainerSTA: React.FC<Props> = ({
  users,
  pendingByAdmin,
  setPendingByAdmin,
  pendingRecords,
  paginatedPendingAdminInvitesQuery,
  fetchMorePaginatedAdminInviteQuery,
  searchPendingAdminApprovalInviteData,
  setSearchPendingAdminApprovalInviteData,
  localSearchText,
  isDoneRows,
  setIsDoneRows,
}) => {
  return (
    <div>
      {users && users.length > 0 && (
        <>
          <div className="usersList__wrapper">
            <PendingAdminUserListSTA
              users={users}
              pendingByAdmin={pendingByAdmin}
              setPendingByAdmin={setPendingByAdmin}
              pendingRecords={pendingRecords}
              paginatedPendingAdminInvitesQuery={paginatedPendingAdminInvitesQuery}
              fetchMorePaginatedAdminInviteQuery={fetchMorePaginatedAdminInviteQuery}
              searchPendingAdminApprovalInviteData={searchPendingAdminApprovalInviteData}
              setSearchPendingAdminApprovalInviteData={setSearchPendingAdminApprovalInviteData}
              localSearchText={localSearchText}
              isDoneRows={isDoneRows}
              setIsDoneRows={setIsDoneRows}
            />
          </div>
        </>
      )}
    </div>
  );
};
