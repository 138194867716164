import { ApolloQueryResult } from 'apollo-client';
import { FormikActions, FormikErrors, FormikHandlers } from 'formik';
import clonedeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import 'src/assets/styles/ProfileStyles.scss';
import { AuthContext } from 'src/auth';
import { SuccessToast } from 'src/components/CustomToasts';
import ProfileForm from 'src/components/forms/ProfilePageForm';
import { StyledLink } from 'src/components/shared/SharedStyles';
import WarningBanner from 'src/components/shared/WarningBanner';
import {
  DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_DESCRIPTION,
  DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_TITLE,
} from 'src/constants/dirSyncConstants';
import {
  CANNOT_EDIT_SYNCED_USERS_PROFILE_FIELD_SUPPORT_LINK,
  INCORRECT_LICENSE_END_DATE,
  PROFILE_UPDATED,
} from 'src/constants/strings';
import { LICENSED, UNLICENSED } from 'src/constants/user';
import { LEARN_MORE } from 'src/constants/virtualPagerStrings';
import { UPDATE_PROFILE_FIELDS } from 'src/gql/v2/mutation/AdminUpdateProfileFieldsMutation';
import ProfileBoxOPC from 'src/pages/UserProfilePage/profile-layout/ProfileBoxOPC';
import ProfileDangerZone from 'src/pages/UserProfilePage/profile-layout/ProfileDangerZone';
import ProfileFooter from 'src/pages/UserProfilePage/profile-layout/ProfileFooter';
import ProfileHiddenNotes from 'src/pages/UserProfilePage/profile-layout/ProfileHiddenNotes';
import UserDeviceList from 'src/pages/UserProfilePage/profile-layout/UserDeviceList';
import ProfileViewModel from 'src/pages/UserProfilePage/profile-layout/viewModels/ProfileViewModel';
import { typedUseSelector } from 'src/redux/store';
import { FetchUserProfileResultFinal, User, UserAddress, UserLicensingStatus, UserProfileValues } from 'src/types';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { ProfileConfigurableFieldsForm } from './components/ProfileConfigurableFieldsForm';
import { UserDeviceListSTA } from '../sta/UserDeviceListSTA';

interface Props {
  user: User;
  isAdmin: boolean;
  selfId: string;
  userLicenseStatus: UserLicensingStatus;
  updateAuthUserInfo: (user: User) => void;
  profileNotesFeatureFlag: boolean;
  hiddenNotes: boolean;
  reFetchData: (variables?: Record<string, any>) => Promise<ApolloQueryResult<FetchUserProfileResultFinal>>;
}

const UserProfileOPC = ({
  user,
  isAdmin,
  selfId,
  userLicenseStatus,
  updateAuthUserInfo,
  hiddenNotes,
  reFetchData,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCPFEditing, setIsCPFEditing] = useState(false);
  const [isCPFSubmitting, setIsCPFSubmitting] = useState(false);
  const { handleUpdateProfile } = ProfileViewModel();
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const isConfigurableProfileFieldsFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.configurableProfileFields);
  const isUserDeviceListFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.userDeviceList);
  const staFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const [currentAddresses, setCurrentAddresses] = useState<UserAddress[]>(user?.addresses);

  const onModeChange = (mode: 'edit' | 'view') => {
    switch (mode) {
      case 'edit':
        setIsEditing(true);
        break;
      case 'view':
        setIsEditing(false);
        break;
    }
  };

  const isDirectorySynced = typedUseSelector((state) => state.userDataReducer?.isDirectorySynced);

  const formikRenderProps = {
    submitFormik: null,
    errors: null,
    resetForm: null,
  } as {
    submitFormik: FormikHandlers['handleSubmit'];
    errors: FormikErrors<UserProfileValues>;
    resetForm: FormikActions<any>['resetForm'];
  };

  const bindFormik = (
    submitForm: FormikHandlers['handleSubmit'],
    errors: FormikErrors<UserProfileValues>,
    resetForm: FormikActions<any>['resetForm'],
  ) => {
    formikRenderProps.submitFormik = submitForm;
    formikRenderProps.errors = errors;
    formikRenderProps.resetForm = resetForm;
  };

  // TODO: note there is currently no updateAddress, have to delete then add (also have to diff beforehand)
  // wait on backend to implement updating, or else adding failed all address will be lost...

  const handleOnSubmitForm = async (values: UserProfileValues) => {
    try {
      setIsSubmitting(true);
      setIsEditing(true);
      if (formikRenderProps.errors && Object.keys(formikRenderProps.errors).length > 0)
        throw new Error('form validation error');

      const { addresses: preEditAddresses } = user;
      const result = await handleUpdateProfile(user.id, selfId, values, preEditAddresses);

      if (result.success) {
        handleAfterSubmitForm(!!result.success, null);
      }

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.updateProfile,
        params: {
          user_id: user.id,
        },
      });
      if (result.error) {
        handleAfterSubmitForm(false, result.error);
      }
      reFetchData();
    } catch (e) {
      let errMsg: string;
      console.error(e);
      if (formikRenderProps.errors.licenseEndDate) {
        errMsg = INCORRECT_LICENSE_END_DATE;
      }
      toast.error(e);
      handleAfterSubmitForm(false, errMsg);
      return Promise.reject('failed');
    }
  };

  const handleCancelForm = () => {
    setIsEditing(false);
    formikRenderProps.resetForm();
  };

  const handleAfterSubmitForm = (isSuccess: boolean, customErrorMsg?: string | boolean) => {
    reFetchData();
    // TODO: hold editing state for optional error such as license date
    setIsEditing(false);
    if (isSuccess) {
      toast.success(<SuccessToast title={PROFILE_UPDATED} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
    } else {
      const addressArray = user.addresses.map((address) => {
        return address;
      });
      setCurrentAddresses(addressArray);
      toast.error(customErrorMsg ? customErrorMsg : 'Error when editing profile', {
        className: 'Toast-Container',
      });
      formikRenderProps.resetForm();
    }
    setIsSubmitting(false);
  };

  const userProfile = user;
  const isLicensed = userLicenseStatus === LICENSED;

  if (!userProfile) return null;

  const formattedUserProfile = {
    ...userProfile,
    addresses: clonedeep(userProfile.addresses).map((address) => {
      delete address['__typename'];
      return address;
    }),
  };

  const createWarningBannerDescription = () => (
    <>
      {DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_DESCRIPTION}
      <StyledLink href={CANNOT_EDIT_SYNCED_USERS_PROFILE_FIELD_SUPPORT_LINK}>
        <span>{LEARN_MORE}</span>
      </StyledLink>
    </>
  );

  return (
    <Mutation mutation={UPDATE_PROFILE_FIELDS}>
      {() => {
        return (
          <>
            <section className="userProfile">
              <div className="userProfile__wrapper">
                {isDirectorySynced && (
                  <div className="profileHeaderWarningBanner">
                    <WarningBanner
                      title={DIR_SYNC_PROFILE_PAGE_WARNING_BANNER_TITLE}
                      description={createWarningBannerDescription()}
                    />
                  </div>
                )}
                <ProfileBoxOPC
                  userLicenseStatus={userLicenseStatus}
                  user={formattedUserProfile}
                  onSubmitForm={() => formikRenderProps.submitFormik()}
                  onCancelForm={() => handleCancelForm()}
                  isSubmitting={isSubmitting}
                  isInputDisabled={!isEditing || isSubmitting}
                  enableEditing={() => setIsEditing(true)}
                  setCurrentAddresses={setCurrentAddresses}
                />
                <ProfileForm
                  isInputDisabled={!isEditing || !isLicensed || isSubmitting}
                  user={formattedUserProfile}
                  isLicensed={isLicensed}
                  handleOnSubmitForm={handleOnSubmitForm}
                  onModeChange={onModeChange}
                  bindFormik={bindFormik}
                  setCurrentAddresses={setCurrentAddresses}
                  currentAddresses={currentAddresses}
                />
                {isLicensed && <ProfileFooter isAdmin={isAdmin} />}
              </div>
            </section>
            {isConfigurableProfileFieldsFlagEnabled && (
              <ProfileConfigurableFieldsForm
                user={user}
                mode={isCPFEditing ? 'edit' : 'view'}
                onSubmitCompleted={() => setIsCPFEditing(false)}
                isSelf={selfId === user.id}
                onModeChange={(mode) => setIsCPFEditing(mode === 'edit')}
              />
            )}
            {hiddenNotes && userLicenseStatus !== UNLICENSED && <ProfileHiddenNotes user={formattedUserProfile} />}
            {isUserDeviceListFlagEnabled && !staFlag ? (
              <UserDeviceListSTA user={formattedUserProfile} />
            ) : (
              <UserDeviceList user={formattedUserProfile} />
            )}
            {ldapDirectorySyncFlag && userLicenseStatus !== UNLICENSED && (
              <ProfileDangerZone user={formattedUserProfile} />
            )}
          </>
        );
      }}
    </Mutation>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({ authInfo, updateAuthUserInfo }) => (
      <UserProfileOPC {...props} selfId={authInfo.user.id} updateAuthUserInfo={updateAuthUserInfo} />
    )}
  </AuthContext.Consumer>
);
