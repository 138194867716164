import React from 'react';

export const ChartIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1948_3269)">
      <path
        d="M30 30H4C3.46957 30 2.96086 29.7893 2.58579 29.4142C2.21071 29.0391 2 28.5304 2 28V2H4V28H30V30Z"
        fill="#4A4A4A"
      />
      <path d="M12 16H10V26H12V16Z" fill="#4A4A4A" />
      <path d="M9 22H7V26H9V22Z" fill="#4A4A4A" />
      <path d="M28 8H26V26H28V8Z" fill="#4A4A4A" />
      <path d="M25 14H23V26H25V14Z" fill="#4A4A4A" />
      <path d="M15 26H17V12H15L15 26Z" fill="#4A4A4A" />
      <path d="M18 26H20V18H18V26Z" fill="#4A4A4A" />
    </g>
    <defs>
      <clipPath id="clip0_1948_3269">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
