import React from 'react';
import { connect } from 'react-redux';
import { AuthContext } from 'src/auth';
import { RootState } from 'src/redux/store';
import { IdentifyUser } from 'src/utils/FeatureFlagManager';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  isLoggedIn: boolean;
  isAuthorized: boolean;
  props: RouteProps;
}

const ConnectedProtectedRoute = ({ isAuthorized, isLoggedIn, props }: Props) => {
  const { dynamicMaintenancePage } = useFlags();

  let CA,
    US,
    EU: boolean = false;

  if (isAuthorized) {
    CA = dynamicMaintenancePage?.currentMaintenanceRegions?.includes('CA');
    US = dynamicMaintenancePage?.currentMaintenanceRegions?.includes('US');
    EU = dynamicMaintenancePage?.currentMaintenanceRegions?.includes('EU');
  }

  if (isLoggedIn) {
    IdentifyUser();
  }

  if (CA || US || EU) {
    window.location.href = dynamicMaintenancePage.maintenancePageUrl;
  }

  console.log({ isLoggedIn, isAuthorized }, 'Connett route');

  return isAuthorized ? <Route {...props} /> : <Redirect to="/" />;
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuthorized: state.flagReducer.isAuthorized,
  };
};

const ConnectedProtectedRoutes = connect(mapStateToProps, null)(ConnectedProtectedRoute);

export default (props: RouteProps) => (
  <AuthContext.Consumer>
    {({ isLoggedIn }) => <ConnectedProtectedRoutes isLoggedIn={isLoggedIn} props={props} />}
  </AuthContext.Consumer>
);
