import gql from 'graphql-tag';

const GeneralUserFragment = gql`
  fragment GeneralUserFragment on GeneralUser {
    username
    firstname: firstName
    lastname: lastName
  }
`;

const OrganizationScopeFragment = gql`
  fragment OrganizationScopeFragment on OrganizationScope {
    organizationId
    siteId
    departmentId
    status
  }
`;

const AddressFragment = gql`
  fragment AddressFragment on Address {
    address
    type
    id
    access
    label @include(if: $isProfileAddressLabelFlagEnabled)
  }
`;

const AddressFragmentDirSync = gql`
  fragment AddressFragmentDirSync on Address {
    address
    type
    label @include(if: $isProfileAddressLabelFlagEnabled)
    access
    syncStatus
    id
  }
`;

const FullOrganizationMemberFragment = gql`
  fragment FullOrganizationMemberFragment on FullOrganizationMember {
    id
    username
    firstname: firstName
    lastname: lastName
    joinDate
    licenseStartTime
    memberStatus
    licenseExpiryTime
    profilePic: avatar {
      url
    }
    role
    addresses {
      ...AddressFragment
    }
    scopes {
      ...OrganizationScopeFragment
    }
  }
  ${AddressFragment}
  ${OrganizationScopeFragment}
`;

export const FullOrganizationUserResultFragment = gql`
  fragment FullOrganizationUserResultFragment on FullOrganizationUserResult {
    ... on PublicUser {
      ...GeneralUserFragment
    }
    ... on Node {
      id
    }
    ... on FullOrganizationMember {
      ...FullOrganizationMemberFragment
    }
  }
  ${GeneralUserFragment}
  ${FullOrganizationMemberFragment}
`;

export const FullOrganizationMemberDirSyncFragment = gql`
  fragment FullOrganizationMemberDirSyncFragment on FullOrganizationMember {
    id
    username
    firstname: firstName
    lastname: lastName
    joinDate
    licenseStartTime
    memberStatus
    licenseExpiryTime
    isDirectorySynced
    isSSOEnabled
    profilePic: avatar {
      url
    }
    role
    addresses {
      ...AddressFragmentDirSync
    }
    scopes {
      ...OrganizationScopeFragment
    }
  }
  ${AddressFragmentDirSync}
  ${OrganizationScopeFragment}
`;

export const FullOrganizationUserDirSyncResultFragment = gql`
  fragment FullOrganizationUserDirSyncResultFragment on FullOrganizationUserResult {
    ... on PublicUser {
      ...GeneralUserFragment
    }
    ... on Node {
      id
    }
    ... on FullOrganizationMember {
      ...FullOrganizationMemberDirSyncFragment
    }
  }
  ${GeneralUserFragment}
  ${FullOrganizationMemberDirSyncFragment}
`;
