import { localStorageService } from '../../../services/localStorageService';
import { getParsedAuthInfo } from '../../../utils/localStorageHelper';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../../types/sta';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';

export const LogoutViewModel = (logout?: any) => {
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const getAuthInfo = getParsedAuthInfo();

  const logoutOfAccount = (currentSelectedAcc: SavedOrganization | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        newCachedData.savedOrganizations = currentCacheData?.savedOrganizations?.map((org) =>
          org.user.id === currentSelectedAcc?.user.id
            ? {
                ...org,
                accessToken: null,
                refreshToken: null,
              }
            : org,
        );

        if (currentSelectedAcc.user.id === getAuthInfo?.user.id) {
          newCachedData.selectedAccountUserId = '';
          logout && logout();
        }

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        return {
          success: true,
          data: newCachedData,
        };
      }
    } catch (err) {
      console.log(err, 'err');
      return {
        success: false,
      };
    }
  };

  const removeAccount = (currentSelectedAcc: SavedOrganization | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        newCachedData.savedOrganizations = currentCacheData?.savedOrganizations?.filter(
          (org) => org.user.id !== currentSelectedAcc?.user.id,
        );

        newCachedData.selectedAccountUserId = '';

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        if (newCachedData.savedOrganizations.length === 0 || !window.location.href.includes('/login')) {
          logout && logout();
        }

        return {
          success: true,
          data: newCachedData,
        };
      }

      return {
        success: false,
      };
    } catch (err) {
      console.log(err, 'err');
      return {
        success: false,
      };
    }
  };

  return { logoutOfAccount, removeAccount };
};
