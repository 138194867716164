import gql from 'graphql-tag';
import { PaginatedInvites } from 'src/types/PaginatedTypes';

export type PaginatedPendingInvites = {
  continuationId: string;
  totalResultsCount: number;
  invites: PaginatedInvites[];
};
export type FetchPaginatedInvitesQueryResponse = {
  adminQuery: {
    organizationalUnit: {
      paginatedPendingInvites: PaginatedPendingInvites;
    };
  };
};
export const FETCH_PAGINATED_INVITES_QUERY = gql`
  query FetchPaginatedInvites(
    $organizationalUnit: OrganizationalUnitInput!
    $direction: PaginationDirection
    $continuationId: ID
    $inviteStatus: [UserInviteStatus!]
    $limit: Int
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            paginatedPendingInvites(
              continuationId: $continuationId
              direction: $direction
              inviteStatus: $inviteStatus
              limit: $limit
            ) {
              ...PaginatedPendingInvitesFragment
            }
          }
        }
      }
    }
  }

  fragment PaginatedPendingInvitesFragment on PaginatedPendingInvites {
    continuationId
    totalResultsCount
    pageSize
    invites {
      ...UserInviteFragment
    }
  }

  fragment UserInviteFragment on UserInvite {
    id
    user {
      ...PublicUserFragment
    }
    address {
      address
    }
    organization {
      id
    }
    invitedBy {
      ...PublicUserFragment
    }
    resolvedBy {
      ...PublicUserFragment
    }
    inviteStatus
    createdAt
    updatedAt
  }

  fragment PublicUserFragment on PublicUser {
    id
    firstname: firstName
    lastname: lastName
    username
  }
`;
