import React from 'react';

export const CheckMarkWithoutBackground = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2089_9901)">
      <g clipPath="url(#clip1_2089_9901)">
        <path
          d="M10.4571 17.793C10.0666 18.1836 9.43342 18.1836 9.04289 17.793L3.53027 12.2804C3.23741 11.9875 3.23741 11.5127 3.53027 11.2199C3.82311 10.927 4.2979 10.927 4.59077 11.2198L9.75 16.3784L19.4092 6.71986C19.7021 6.42702 20.1769 6.42703 20.4697 6.71988C20.7626 7.01273 20.7626 7.48754 20.4697 7.7804L10.4571 17.793Z"
          fill="#FF3E55"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2089_9901">
        <rect width="24" height="24" fill="white" transform="translate(0 0.500122)" />
      </clipPath>
      <clipPath id="clip1_2089_9901">
        <rect width="24" height="24" fill="white" transform="translate(0 0.500122)" />
      </clipPath>
    </defs>
  </svg>
);
