import React from 'react';
import { UserRepository } from '../../../../data/repository/UserRepository';
import { checkOrganizationalUnit } from '../../../../utils/getOrganizationalUnitObject';

export const DeviceListViewModel = () => {
  const repo = UserRepository();

  const getUserDevices = ({ userId }: { userId: string }) => {
    const result = repo.useFetchUserDevices({ userId });

    return {
      data: result?.data?.adminQuery?.organizationalUnit?.member?.sessions,
      error: result.error,
      loading: result.loading,
      refetch: result.refetch,
    };
  };

  const logoutUserDevice = () => {
    const result = repo.useLogoutUserDevice();
    return {
      logoutUserDevice: (memberId: string, sessionId: string) => {
        const org = checkOrganizationalUnit();
        if (org.type === 'error') {
          return;
        }
        return result.logoutUserDevice({
          variables: {
            organizationalUnit: org,
            sessionId,
            memberId,
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };

  const logoutSelfDevice = () => {
    const result = repo.useLogoutSelfDevice();
    return {
      logoutSelfDevice: (sessionId: string) => {
        return result.logoutSelfDevice({
          variables: {
            sessionId,
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };

  const logoutAllDevice = () => {
    const result = repo.useLogoutAllDevice();
    return {
      logoutAllDevice: (organizationId: number, userId: string) => {
        return result.logoutAllDevice({
          variables: {
            organizationId,
            userId,
          },
        });
      },
      loading: result.loading,
      error: result.error,
    };
  };

  return { getUserDevices, logoutUserDevice, logoutSelfDevice, logoutAllDevice };
};
