import gql from 'graphql-tag';

export default gql`
  mutation CreateShellAccount($organizationalUnit: OrganizationalUnitInput!, $accounts: [AdminCreateUserDetails!]!) {
    adminMutation {
      __typename
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          __typename
          ... on AdminOrganizationMutation {
            createShellAccounts(accounts: $accounts) {
              ... on AdminCreateAccountsResponse {
                ## length of accounts will always be equal to the number of account details passed in
                accounts {
                  __typename
                  ... on FullOrganizationMember {
                    id
                    firstName
                    lastName
                    role
                    addresses {
                      address
                      access
                      label
                    }
                  }
                  ... on Error {
                    message
                  }
                  ... on AddressInUse {
                    address
                  }
                  ... on AccountBlocked {
                    address
                  }
                  ... on InvitePendingAdminApproval {
                    address
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
